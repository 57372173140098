import { useEffect, useState } from "react";
import {
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
} from "tw-elements-react";
import { ToastContainer, toast } from "react-toastify";
import { Client, Session } from "@heroiclabs/nakama-js";
import {
  useAccount,
  useDisconnect,
  useWriteContract,
  useWaitForTransactionReceipt
} from "wagmi";
import parse from "html-react-parser";
import {
  useConnectModal,
} from "@rainbow-me/rainbowkit";
import { reAuthenticate } from "../api/auth";
import { useNavigate } from "react-router-dom";
import { parseEventLogs } from 'viem'
import { abi } from "../contract/contractABI";

import { startTransaction as startTransactionApi } from "../api/transaction"; // Renaming to avoid confusion
import { burnTransaction as burnTransaction } from "../api/transaction"; // Renaming to avoid confusion

import { ethers } from 'ethers';
import { clientData } from '../hooks/client'
import { fetchMyAPI } from "../api/fetch";
import { ToastMessage } from "../api/message";
import { mintEvent, burnEvent } from "../api/transaction";

export default function ModalPage(props: any) {
  const { address } = useAccount();
  const [client, setClient] = useState<any | null>(null);
  const { openConnectModal } = useConnectModal();
  const navigate = useNavigate();
  const [isWalletLink, setIsWalletLink] = useState<boolean>(false);
  const [isWrongNetwork, setIsWrongNetwork] = useState<boolean>(false);
  const [isToast, setIstoast] = useState(false);
  const [walletAddress, setWalletAddress] = useState<string | null>(null);
  const isLoggedIn = localStorage.getItem("session");
  const { data: hash, error, isPending, writeContract } = useWriteContract();
  const { isLoading: isConfirming, isSuccess: isConfirmed } = useWaitForTransactionReceipt({ hash });
  const [triggerConnectModal, setTriggerConnectModal] = useState(false);
  const [tokenID, setTokenID] = useState(0);
  const [isMint, setsMint] = useState(false);
  const { disconnect } = useDisconnect();


  const [eventLogs, setEventLogs] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [hashID, setHashID] = useState("");

  useEffect(() => {
    const fetchDataAPI = async () => {
      try {
        const result = await fetchMyAPI(Client, setClient, setIsWalletLink, address, setIsWrongNetwork, setWalletAddress);
        setClient(result.client); // Assuming client is returned by fetchMyAPI
        setIsWalletLink(result.isWalletLink);
        setIsWrongNetwork(result.isWrongNetwork);
        setWalletAddress(result.walletAddress);
        setTokenID(props?.currentItems?.token_id);
      } catch (error) {
        console.error('Error fetching API:', error);
      }
    };

    fetchDataAPI();

  }, [isWalletLink, isWrongNetwork, address, eventLogs, hash, tokenID]);

  useEffect(() => {

    const fetchDataAPI = async () => {


      if (isConfirming && hash && isMint === false) {

        setIstoast(true);
        toast(
          <p style={{ fontSize: 14 }} className="font-bold text-[#593F3F]">
            Your transaction is being processed ⏳⏳⏳
          </p>,
          {
            position: 'bottom-right',
            type: 'default',
            autoClose: false,
            onClose: () => setIstoast(false),
          }
        );
      }
      else if (hash && isMint === false && isConfirmed) {


        setFetching(true);
        console.log("token id", tokenID)

        if (isConfirmed) {

          try {
            const provider = new ethers.providers.JsonRpcProvider(clientData.transport.url);

            const receipt = await provider.getTransactionReceipt(hash);

            toast(
              <p style={{ fontSize: 14 }} className="font-bold text-[#593F3F]">
                Burn successful! Thank you 🎉🎉🎉<br /><br />
                View in <a href={'https://testnet.bscscan.com/tx/' + hash} target="_blank" rel="noopener noreferrer">explorer</a>
              </p>,
              ToastMessage(setIstoast, 'success')
            );



            if (receipt) {
              if (receipt.logs) {
                const logs = parseEventLogs({
                  abi: abi,
                  eventName: 'Transfer',
                  //@ts-ignore              
                  logs: receipt.logs,
                });
                //@ts-ignore
                setEventLogs(logs);

                toast(
                  <p style={{ fontSize: 14 }} className="font-bold text-[#593F3F]">
                    NFT Successfully Burn
                  </p>,
                  {
                    position: 'bottom-right',
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    rtl: false,
                    type: "success",
                    pauseOnFocusLoss: false,
                    draggable: true,
                    pauseOnHover: false,
                    onClose: () => setIstoast(false),
                  }
                );

                // add burn function
                const useSSL = window.location.protocol === "https:";
                const client = new Client(
                  useSSL ? process.env.REACT_APP_CLIENT_KEY! : process.env.REACT_APP_DEV_CLIENT_KEY!,
                  useSSL ? process.env.REACT_APP_API_HOST! : process.env.REACT_APP_DEV_API_HOST!,
                  useSSL ? process.env.REACT_APP_API_PORT! : process.env.REACT_APP_DEV_API_PORT!,
                  useSSL
                );
                setClient(client);

                const sessionData = localStorage.getItem('session');
                if (sessionData) {
                  const session = JSON.parse(sessionData);
                  if (session) {
                    let sessionCheck = Session.restore(session.token, session.refresh_token);
                    //@ts-ignore
                    const expiresAtDate = new Date(sessionCheck?.expires_at * 1000);
                    const currentTime = new Date();

                    if (currentTime >= expiresAtDate) {
                      reAuthenticate(navigate);
                    } else {
                      const payload = {
                        token_id: tokenID
                      };

                      const response = await burnTransaction(sessionCheck, client, payload);

                      // Check if response.payload is a string or an object
                      const resData = typeof response.payload === "string" ? JSON.parse(response.payload) : response.payload;

                      if (resData.status === "success") {
                        // Immediately show success toast
                        toast(
                          <p style={{ fontSize: 14 }} className="font-bold text-[#593F3F]">
                            Successfully save item in inventory! Thank you 🎉🎉🎉
                          </p>,
                          ToastMessage(setIstoast, 'success')
                        );

                      } else {
                        toast(
                          <p style={{ fontSize: 14 }} className="font-bold text-[#593F3F]">
                            {resData.error}
                          </p>,
                          ToastMessage(setIstoast, 'error')
                        );
                      }
                    }
                  }
                } else {
                  reAuthenticate(navigate);
                }

                //end

                setIstoast(true);
              } else {
                console.error('No logs found in the transaction receipt.');
                alert('No logs found in the transaction receipt.');
              }
            } else {
              console.error('No transaction receipt found.');
              alert('No transaction receipt found.');
            }
          } catch (err) {


            console.error('Error fetching transaction receipt:', err);
            toast(
              <p style={{ fontSize: 14 }} className="font-bold text-[#593F3F]">
                Error fetching transaction receipt: {(err as any).message}
              </p>,
              ToastMessage(setIstoast, 'error')
            );
          }
        }
        setFetching(false); // Ensure fetching is set to false once we're done
      }

    };

    fetchDataAPI();

  }, [clientData, hash, isConfirmed, isConfirming]);


  useEffect(() => {
    if (triggerConnectModal && typeof openConnectModal === 'function') {
      openConnectModal();
      setTriggerConnectModal(false);
    }
  }, [triggerConnectModal, openConnectModal, clientData, hashID, abi, isToast]); // Include istoast in dependencies


  const notify = (message: any, types: any) => {
    if (!isToast) {
      setIstoast(true);
      toast(
        <p style={{ fontSize: 14 }} className="font-bold text-[#593F3F]">
          {parse(message)}
        </p>,
        ToastMessage(setIstoast, types)

      );
    }
  };

  const connectWallet = () => {
    if (openConnectModal) {
      openConnectModal();
    } else {
      console.error('openConnectModal is undefined');
    }
  };

  const handleWrongAddressClick = () => {
    disconnect();
    setTriggerConnectModal(true);
  };

  const submit = async (e: any) => {
    e.preventDefault();

    if (isWalletLink === false) {
      notify(`Please bind your wallet on <a href="/profile" class='underline !text-[#0376C9]'>Profile </a>😉😉😉`, '');
    }
  };

  const mintItem = async () => {
    setsMint(true)
    mintEvent(writeContract, abi)
  }


  const burnItem = async (tokenId: any) => {
    setsMint(false);
    try {
      const result = await burnEvent(writeContract, abi, tokenId);



    } catch (error) {
      console.error('Burn failed:', error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (isMint === true) {
        try {
          if (isPending) {
            setIstoast(true);
            toast(
              <p style={{ fontSize: 14 }} className="font-bold text-[#593F3F]">
                Your transaction is being processed ⏳⏳⏳
              </p>,
              {
                position: 'bottom-right',
                type: 'default',
                autoClose: false,
                onClose: () => setIstoast(false),
              }
            );
          } else if (isConfirmed) {
            setIstoast(true);

            const useSSL = window.location.protocol === "https:";
            const client = new Client(
              useSSL ? process.env.REACT_APP_CLIENT_KEY! : process.env.REACT_APP_DEV_CLIENT_KEY!,
              useSSL ? process.env.REACT_APP_API_HOST! : process.env.REACT_APP_DEV_API_HOST!,
              useSSL ? process.env.REACT_APP_API_PORT! : process.env.REACT_APP_DEV_API_PORT!,
              useSSL
            );
            setClient(client);

            const sessionData = localStorage.getItem('session');
            if (sessionData) {
              const session = JSON.parse(sessionData);
              if (session) {
                let sessionCheck = Session.restore(session.token, session.refresh_token);
                //@ts-ignore
                const expiresAtDate = new Date(sessionCheck?.expires_at * 1000);
                const currentTime = new Date();

                if (currentTime >= expiresAtDate) {
                  reAuthenticate(navigate);
                } else {
                  const payload = {
                    itemID: props?.currentItems?.ID_no_item,
                    uniqueID: props?.currentItems?.uniqueID,
                    userID: sessionCheck?.user_id,
                    address: address,
                  };

                  const response = await startTransactionApi(sessionCheck, client, payload);

                  // Check if response.payload is a string or an object
                  const resData = typeof response.payload === "string" ? JSON.parse(response.payload) : response.payload;

                  if (resData.status === "success") {
                    // Immediately show success toast
                    toast(
                      <p style={{ fontSize: 14 }} className="font-bold text-[#593F3F]">
                        Transaction successful! Thank you 🎉🎉🎉<br /><br />
                        View in <a href={'https://testnet.bscscan.com/tx/' + resData.hash} target="_blank" rel="noopener noreferrer">explorer</a>
                      </p>,
                      ToastMessage(setIstoast, 'success')
                    );

                    setFetching(true); // Set fetching to true when we start fetching

                    try {
                      const provider = new ethers.providers.JsonRpcProvider(clientData.transport.url);

                      const receipt = await provider.getTransactionReceipt(resData.hash);

                      if (receipt) {
                        if (receipt.logs) {
                          const logs = parseEventLogs({
                            abi: abi,
                            eventName: 'Transfer',
                            //@ts-ignore              
                            logs: receipt.logs,
                          });
                          //@ts-ignore
                          setEventLogs(logs);

                          toast(
                            <p style={{ fontSize: 14 }} className="font-bold text-[#593F3F]">
                              NFT Successfully transferred
                            </p>,
                            {
                              position: 'bottom-right',
                              autoClose: 8000,
                              hideProgressBar: false,
                              closeOnClick: false,
                              rtl: false,
                              type: "success",
                              pauseOnFocusLoss: false,
                              draggable: true,
                              pauseOnHover: false,
                              onClose: () => setIstoast(false),
                            }
                          );

                          setIstoast(true);
                        } else {
                          console.error('No logs found in the transaction receipt.');
                          alert('No logs found in the transaction receipt.');
                        }
                      } else {
                        console.error('No transaction receipt found.');
                        alert('No transaction receipt found.');
                      }
                    } catch (err) {

                      console.error('Error fetching transaction receipt:', err);
                      toast(
                        <p style={{ fontSize: 14 }} className="font-bold text-[#593F3F]">
                          Error fetching transaction receipt: {(err as any).message}
                        </p>,
                        ToastMessage(setIstoast, 'error')
                      );
                    }

                    setFetching(false); // Ensure fetching is set to false once we're done
                  } else {
                    toast(
                      <p style={{ fontSize: 14 }} className="font-bold text-[#593F3F]">
                        {resData.error}
                      </p>,
                      ToastMessage(setIstoast, 'error')
                    );
                  }
                }
              }
            } else {
              reAuthenticate(navigate);
            }
          } else if (error) {
            setIstoast(true);
            toast(
              <p style={{ fontSize: 14 }} className="font-bold text-[#593F3F]">
                {error.message}
              </p>,
              {
                position: 'bottom-right',
                type: 'error',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                rtl: false,
                pauseOnFocusLoss: false,
                draggable: true,
                pauseOnHover: false,
                onClose: () => setIstoast(false),
              }
            );
          }
        }

        catch (err) {
          setIstoast(true);

          toast(
            <p style={{ fontSize: 14 }} className="font-bold text-[#593F3F]">
              Connection Timeout. Please Check if the item already in <a href="/mynfts" className='underline !text-[#0376C9]'>My Nft</a>.<br /><br />
              If the item is not listed, Please Retry to mint again. Thank you...
            </p>,
            ToastMessage(setIstoast, 'error')
          );
        }
      }
    };

    fetchData();
  }, [isPending, isConfirmed]);

  const handleCopy = () => {
    const textToCopy = props?.isNft ? props?.currentItems?.token_id : props?.currentItems?.ID_no_item;
    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          toast(
            <p style={{ fontSize: 14 }} className="font-bold text-[#593F3F]">
              Copied to clipboard!
            </p>,
            {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              rtl: false,
              type: "success",
              pauseOnFocusLoss: false,
              draggable: true,
              pauseOnHover: false,
              onClose: () => setIstoast(false),
            }
          );
        })
        .catch((err) => {
          console.error('Failed to copy text: ', err);
        });
    }
  };
  const itemsObject = props.currentItems || {};
  let itemsArray = Array.isArray(itemsObject)
    ? itemsObject
    : Object.values(itemsObject);

  itemsArray = itemsArray.filter(
    (item) => typeof item === "object" && item !== null
  );
  return (
    <div>
      {isToast === true && (
        <div
          className=" fixed top-0 left-0 w-full h-full z-[9999]"
          style={{ background: "rgba(0,0,0,0.8)" }}
        />
      )}

      <TEModal
        show={props.showModalTopRight}
        setShow={props.setShowModalTopRight}
        scrollable
      >
        <TEModalDialog
          size="xl"
          centered
          style={{ width: "max-width: 900px !important;" }}
          className="modalDilog"
          theme={{
            show: "translate-x-0 opacity-100",
            hidden: "translate-x-[100%] opacity-0",
          }}
        >
          <TEModalContent className="contentModsl">
            <TEModalHeader>
              {/* <!--Modal title--> */}
              <h3 className="text-2xl font-bold tracking-tight text-gray-900">
                {/* Catalog Detail */}
                {props?.title}
              </h3>
              {/* <!--Close button--> */}
              <button
                type="button"
                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                onClick={() => props.setShowModalTopRight(false)}
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </TEModalHeader>
            {/* <!--Modal body--> */}
            <TEModalBody>
              <div className="py-5">
                <div className="mx-auto max-w-7xl sm:px-2 lg:px-8">
                  <div
                    className="mx-auto max-w-6xl 
                  Ultrawide:max-w-7xl
                  mq4k:max-w-7xl
                  rounded-3xl  sm:mt-1 lg:mx-0 md:block lg:flex Ultrawide:flex mq4k:flex lg:max-w-none"
                  >
                    <div
                      className="-mt-2 p-2 lg:mt-0 lg:w-full sm:max-w-full lg:max-w-xs 
                    Ultrawide:w-[45%]
                    mq4k:w-[45%]
                    lg:flex-shrink-0"
                    >
                      <img
                        className="w-full rounded-[12px]"
                        alt="tools"
                        src={
                          props?.currentItems?.Link
                            ? props?.currentItems?.Link
                            : "/coming-soon.png"
                        }
                      />
                    </div>
                    <div
                      className="sm:p-0 lg:p-10  lg:!pt-0
                    Ultrawide:p-10 
                    Ultrawide:!pb-0 
                    Ultrawide:!pt-4
                    mq4k:p-10 
                    d-flex align-start flex-column
                    w-full
                    m-auto"
                    >
                      <div>
                        <h3 className="text-2xl font-bold tracking-tight text-gray-900">
                          {props?.currentItems?.Key?.replace(/\.[^.]+$/, "")}
                        </h3>
                        <h4 className="flex-none text-2xl md:text-4xl font-semibold leading-6 text-[#593F3F]">

                          {/* {JSON.stringify(props?.currentItems)} */}
                          {
                            props?.isNft ?
                              <>
                                {props?.currentItems?.name}
                              </>
                              :
                              <>
                                {props?.currentItems?.Name}
                              </>
                          }
                        </h4>
                        <p className="flex-none text-base mt-3 font-[400]  text-[#593F3F]">
                          {props?.currentItems?.Description}
                        </p>

                        <div className="mb-1 tracking-wide py-4">
                          <div className="pb-1">
                            <div className="flex items-center mt-1">
                              <div
                                className=" sm:w-1/2 lg:w-1/5
                              Ultrawide:w-1/2
                              mq4k:w-1/2
                              text-dimgray-200 tracking-tighter"
                              >
                                <span>ID</span>
                              </div>
                              <div
                                className="w-3/5 text-dimgray-200 flex
                               Ultrawide:w-full
                               mq4k:w-full"
                              >
                                <span className="sm:hidden lg:block">
                                  {
                                    props?.isNft ?
                                      <>
                                        {props?.currentItems?.token_id}
                                      </>
                                      :
                                      <>
                                        {props?.currentItems?.ID_no_item}
                                      </>
                                  }
                                  {/* {JSON.stringify(props?.currentItems)} */}
                                </span>
                                <div className="sm:block hidden">
                                  0xac...5c91
                                </div>
                                <span className="pl-2 text-sm cursor-pointer"
                                  onClick={handleCopy}>
                                  <img
                                    src="/copy.svg"
                                    className="h-5 w-5 mr-1"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="pb-1">
                            <div className="flex items-center mt-1">
                              <div
                                className=" sm:w-1/2 lg:w-1/5
                                                            Ultrawide:w-1/2
                                                            mq4k:w-1/2
                              text-dimgray-200 tracking-tighter"
                              >
                                <span>Tier</span>
                              </div>
                              <div
                                className="w-3/5 text-default
                              Ultrawide:w-full
                              mq4k:w-full"
                              >
                                <button
                                  className="bg-[#D1E5FF] hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded inline-flex items-center
                              m-auto"
                                >
                                  <img
                                    src="/star-solid.svg"
                                    className="h-5 w-5 mr-1"
                                  />
                                  <span className="mt-1">
                                    Tier 1
                                    {/* {props?.currentItems?.Tier}{" "}
                                    {props?.currentItems?.Costume_Tier} */}
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>

                          {props?.currentItems?.Tools === "YES" ||
                            (props?.currentItems?.Player === "YES" && (
                              <div className="pb-1">
                                <div className="flex items-center mt-1">
                                  <div
                                    className=" sm:w-1/2 lg:w-1/5
                                                            Ultrawide:w-1/2
                                                            mq4k:w-1/2
                              text-dimgray-200 tracking-tighter"
                                  >
                                    <span>Date Create</span>
                                  </div>
                                  <div
                                    className="w-3/5 text-dimgray-200
                              Ultrawide:w-full
                              mq4k:w-full"
                                  >
                                    11 / 12 / 2024
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>

                      <div className="">
                        <div className="mb-1 tracking-wide pb-4 w-full">
                          <form className="set  w-full" onSubmit={submit}>
                            {isLoggedIn && (
                              <div className="mt-2 w-full max-w-full flex flex-row items-start justify-start gap-[16px] text-left text-sm text-colors-white-100 font-dm-sans">
                                {!address ? (
                                  <button
                                    className="flex-1 rounded bg-royalblue h-8 flex flex-row items-center justify-center py-0 px-6 box-border lg:gap-[4px] lg:items-center lg:justify-center sm:flex-col sm:gap-[8px]"
                                    onClick={connectWallet}
                                  >
                                    <b className="relative tracking-[0.02em] leading-[100%] lg:text-lg sm:text-xs">
                                      Connect Wallet
                                    </b>
                                  </button>
                                ) : (

                                  <>
                                    {
                                      isWrongNetwork ?
                                        <button
                                          className="flex-1 rounded bg-[#ff6464d9] h-8 flex flex-row items-center justify-center py-0 px-6 box-border lg:gap-[4px] lg:items-center lg:justify-center sm:flex-col sm:gap-[8px]"
                                          onClick={handleWrongAddressClick}
                                          type="button"
                                        >
                                          <p className="font-medium relative tracking-[0.02em] leading-[100%] lg:text-lg sm:text-xs">
                                            Wrong Address
                                          </p>
                                        </button>
                                        :
                                        (
                                          <>
                                            {
                                              props?.isNft ?
                                                <button
                                                  className="flex-1 rounded bg-royalblue h-8 flex flex-row items-center justify-center py-0 px-6 box-border lg:gap-[4px] lg:items-center lg:justify-center sm:flex-col sm:gap-[8px]"
                                                  onClick={(e: any) => burnItem(props?.currentItems?.token_id)}
                                                >
                                                  <p className="font-medium relative tracking-[0.02em] leading-[100%] lg:text-lg sm:text-xs">
                                                    Burn
                                                  </p>
                                                </button>
                                                :

                                                <button
                                                  className="flex-1 rounded bg-royalblue h-8 flex flex-row items-center justify-center py-0 px-6 box-border lg:gap-[4px] lg:items-center lg:justify-center sm:flex-col sm:gap-[8px]"
                                                  disabled={isPending}
                                                  onClick={(e: any) => mintItem()}
                                                >
                                                  <p className="font-medium relative tracking-[0.02em] leading-[100%] lg:text-lg sm:text-xs">
                                                    {isPending ? "Confirming..." : "Mint"}
                                                  </p>
                                                </button>
                                            }
                                          </>
                                        )


                                    }
                                  </>

                                )}

                                <div className="flex-1 rounded bg-dimgray-200 h-8 flex flex-row items-center justify-center py-0 px-6 box-border lg:gap-[4px] lg:items-center lg:justify-center sm:flex-col sm:gap-[8px]">
                                  <p className="font-medium relative tracking-[0.02em] leading-[100%] lg:text-lg sm:text-xs">
                                    Close
                                  </p>
                                </div>
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Test */}
                  <div
                    className="mx-auto max-w-6xl 
                  Ultrawide:max-w-7xl
                  mq4k:max-w-7xl
                  rounded-3xl  sm:mt-1 lg:mx-0 md:block lg:flex Ultrawide:flex mq4k:flex lg:max-w-none"
                  >
                    <div
                      className="-mt-2 p-2 lg:mt-0 lg:w-full sm:hidden lg:max-w-xs 
                    Ultrawide:w-[34%]
                    mq4k:w-[34%]
                    lg:flex-shrink-0"
                    ></div>
                    {/* Details */}
                    <div className="mt-4">
                      <h3 className="text-2xl font-bold tracking-tight text-gray-900 pb-1">
                        Details
                      </h3>
                      <div className="mb-1 tracking-wide py-4 !pt-1">
                        <div
                          className="gridDetails grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-5 Ultrawide:grid-cols-5
                          mq4k:grid-cols-5
                          gap-4"
                        >
                          <div className=" max-w-[194px]">
                            <div
                              className={`
                            ${props?.currentItems?.Tier === "Common" &&
                                "bg-[#CBCBCB]"
                                }
                            ${props?.currentItems?.Tier === "Rare" &&
                                "bg-[#D1E5FF]"
                                }

                            ${props?.currentItems?.Tier === "Legendary" &&
                                "bg-[#FDE2CC]"
                                }

                            ${props?.currentItems?.Tier === "Epic" &&
                                "bg-[#E4CFEB]"
                                }

                            ${props?.currentItems?.Tier === "Mythical" &&
                                "bg-[#F5C5C5]"
                                }
                            
                            ${props?.currentItems?.Tier === "Uncommon" &&
                                "bg-[#C4E9BC]"
                                }
                            
                            rounded-lg border sm:w-full lg:w-full pb-1 pt-0
                            min-h-[52px] h-auto
                            `}
                            >
                              <div className="px-1 py-[0.2rem]">
                                <div
                                  className={`font-bold text-[12px] mb-1 p-2 pb-0 

                                  ${props?.currentItems?.Tier === "Common" &&
                                    "text-[#6A6A6A]"
                                    }
                                  ${props?.currentItems?.Tier === "Rare" &&
                                    "text-[#1E6DD5]"
                                    }
      
                                  ${props?.currentItems?.Tier === "Legendary" &&
                                    "text-[#8A3A17]"
                                    }
      
                                  ${props?.currentItems?.Tier === "Epic" &&
                                    "text-[#644C8E]"
                                    }
      
                                  ${props?.currentItems?.Tier === "Mythical" &&
                                    "text-[#7A2626]"
                                    }
                                  
                                  ${props?.currentItems?.Tier === "Uncommon" &&
                                    "text-[#24602A]"
                                    }
                                        inline-block leading-4 pt-[2.2px]
                                        `}
                                >
                                  Rank
                                </div>
                                <div className="text-md p-2 py-0 flex justify-between items-center">
                                  <div className="text-default mt-0">
                                    <span
                                      className={`font-[500] text-[14px] px-4 rounded-lg inline-block leading-4 pt-[2.2px]
                                    ${props?.currentItems?.Tier === "Common" &&
                                        "bg-[#EAEAEA]  text-[#6A6A6A]"
                                        }
                                    ${props?.currentItems?.Tier === "Rare" &&
                                        "bg-[#EDF5FF]  text-[#1E6DD5]"
                                        }
        
                                    ${props?.currentItems?.Tier ===
                                        "Legendary" &&
                                        "text-[#8A3A17] bg-[#FFEADE]"
                                        }
        
                                    ${props?.currentItems?.Tier === "Epic" &&
                                        "text-[#644C8E] bg-[#EADEFF]"
                                        }
        
                                    ${props?.currentItems?.Tier ===
                                        "Mythical" &&
                                        "text-[#7A2626] bg-[#FFDEDE]"
                                        }
                                    
                                    ${props?.currentItems?.Tier ===
                                        "Uncommon" &&
                                        "text-[#24602A] bg-[#DFFFDE]"
                                        }
                                    
                                    `}
                                    >
                                      {props?.currentItems?.Tier
                                        ? props?.currentItems?.Tier
                                        : "EPIC"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* {props?.currentItems?.Tools === "YES" ||
                            props?.currentItems?.Player === "YES" ||
                            props?.currentItems?.Odds_Ends === "YES" ||
                            props?.currentItems?.Pets === "YES" ||
                            (props?.currentItems?.Resources === "YES" && ( */}
                          <div className=" max-w-[194px]">
                            <div className="bg-[#D1E5FF] rounded-lg border sm:w-full lg:w-full pb-[4px] pt-[4px]">
                              <div className="px-1 min-h-[45px] h-[45px]">
                                <div
                                  className="font-bold text-[12px] mb-1 p-2 pb-0 text-default
                                        inline-block leading-4 pt-[2.2px]
                                        "
                                >
                                  Category
                                </div>
                                <div className="font-bold text-md p-2 py-0 flex justify-between items-center">
                                  <div className="text-default mt-0">
                                    <span
                                      className="text-default bg-[#EDF5FF] text-[14px] px-4 rounded-lg inline-block leading-4 pt-[2.2px]
                                    font-[500]"
                                    >
                                      {props?.currentItems?.Resources ===
                                        "YES" &&
                                        props?.currentItems?.Odds_Ends ===
                                        "YES" ? (
                                        "Resources"
                                      ) : (
                                        <>
                                          {props?.currentItems?.Tools ===
                                            "YES" && "Tools"}
                                          {props?.currentItems?.Resources ===
                                            "YES" && "Resources"}
                                          {props?.currentItems?.Player ===
                                            "YES" && "Costume"}
                                          {props?.currentItems?.Odds_Ends ===
                                            "YES" && "Odds_Ends"}

                                          {props?.currentItems?.Pets ===
                                            "YES" && "Pets"}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    {/* end details */}
                  </div>
                  {/* end test */}
                </div>
              </div>
            </TEModalBody>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>
      <ToastContainer />
    </div>
  );
}
