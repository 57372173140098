
import { Session } from "@heroiclabs/nakama-js";
import { reAuthenticate } from "./auth";


export async function fetchDataForPosts(): Promise<any> {

    try {
        let response = await fetch(
            "https://update.terraquest.xyz/patchnotes.html"
        ); // Gets a promise

        return (await response.text());
    } catch (error) {
        throw error; // Rethrow the error to handle it at a higher level
    }
}

export async function fetchBlogPosts(): Promise<any> {

    try {
        let response = await fetch(
            "https://blog.terraquest.xyz/ghost/api/content/posts/?key=1377ea1fcb8d8d3c0c6c1888d1"
        );
        let data = await response.json();
        return data; // Return the fetched data if needed
    } catch (error) {
        throw error; // Rethrow the error to handle it at a higher level
    }
}

export async function fetchS3Images(): Promise<any> {

    try {
        let response = await fetch(
            "http://gallery.terraquest.xyz/"
        )
            .then(response => response.text())
            .then(str => (new window.DOMParser()).parseFromString(str, "text/xml"))
            .then(data => console.log(data));
        // let data = await response.text();

        // return data; // Return the fetched data if needed
    } catch (error) {
        throw error; // Rethrow the error to handle it at a higher level
    }
}



export async function fetchMyAPI(Client: any, setClient: any, setIsWalletLink: any, address: any, setIsWrongNetwork: any, setWalletAddress: any): Promise<any> {
    try {
        let useSSL = window.location.protocol === "https:";
        const client = new Client(
            useSSL ? process.env.REACT_APP_CLIENT_KEY! : process.env.REACT_APP_DEV_CLIENT_KEY!,
            useSSL ? process.env.REACT_APP_API_HOST! : process.env.REACT_APP_DEV_API_HOST!,
            useSSL ? process.env.REACT_APP_API_PORT! : process.env.REACT_APP_DEV_API_PORT!,
            useSSL
        );
        setClient(client);

        const sessionData = localStorage.getItem('session');
        if (sessionData) {
            const session = JSON.parse(sessionData);
            if (session) {
                let session1 = Session.restore(session.token, session.refresh_token);

                //@ts-ignore
                const expiresAtDate = new Date(session1?.expires_at * 1000);
                const currentTime = new Date();

                if (currentTime < expiresAtDate) {
                    const getWalletData = await client.rpc(session1, 'getWallet', {});
                    if (getWalletData.payload === null) {
                        setIsWalletLink(false);
                        return { client: client, isWalletLink: false, isWrongNetwork: null, walletAddress: null };
                    } else {
                        setIsWalletLink(true);
                        if (getWalletData.payload === address) {
                            //@ts-ignore                  
                            setWalletAddress(getWalletData.payload);
                            setIsWrongNetwork(false);
                            return { client: client, isWalletLink: true, isWrongNetwork: false, walletAddress: getWalletData.payload };
                        } else {
                            setIsWrongNetwork(true);
                            return { client: client, isWalletLink: true, isWrongNetwork: true, walletAddress: null };
                        }
                    }
                }
            } else {
                console.error('Session data or Google token is null. Handle this case accordingly.');
                return { error: 'Session data or Google token is null.' };
            }
        }
        return { error: 'No session data found.' };
    } catch (error) {
        console.error('An error occurred:', error);
        throw error; // Rethrow the error to handle it at a higher level
    }
}


export async function fetchData(Client: any, setClient: any, navigate: any, props: any, address: any): Promise<any> {
    try {
        let useSSL = window.location.protocol === "https:";
        const client = new Client(
            useSSL ? process.env.REACT_APP_CLIENT_KEY! : process.env.REACT_APP_DEV_CLIENT_KEY!,
            useSSL ? process.env.REACT_APP_API_HOST! : process.env.REACT_APP_DEV_API_HOST!,
            useSSL ? process.env.REACT_APP_API_PORT! : process.env.REACT_APP_DEV_API_PORT!,
            useSSL
        );
        setClient(client);

        const sessionData = localStorage.getItem('session');
        if (sessionData) {
            const session = JSON.parse(sessionData);
            if (session) {
                let sessionCheck = Session.restore(session.token, session.refresh_token);
                //@ts-ignore
                const expiresAtDate = new Date(sessionCheck?.expires_at * 1000);
                const currentTime = new Date();

                if (currentTime >= expiresAtDate) {
                    reAuthenticate(navigate);
                } else {
                    const payload = {
                        itemID: props?.currentItems?.ID_no_item,
                        uniqueID: props?.currentItems?.uniqueID,
                        userID: sessionCheck?.user_id,
                        address: address,
                    };

                }
            }
        } else {
            reAuthenticate(navigate);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

