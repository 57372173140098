import { FunctionComponent, useState, useCallback } from "react";
import InventoryMemoryFragmentSide from "../components/InventoryMemoryFragmentSide";
import DrawerMenuSlide from "../components/DrawerMenuSlide";
import PortalDrawer from "../components/PortalDrawer";
import Header from "../components/Header";
import TermsCondition from "../components/TermsCondition";
import useNavigation from "../api/route";

const MemoryFragmentConvertSucces: FunctionComponent = () => {
  const [isDrawerMenuSlideOpen, setDrawerMenuSlideOpen] = useState(false);

  const {
    onDashboardClick,
    onProfileClick,
    onInventoryClick,
    onMyNFTClick,
    onLogoutClick,
    onInventoryPlacedBookmarkClick,
    onInventoryMemoryFragmentClick,
    onInventoryCostumesClick,
    onInventoryAllItemClick,
  } = useNavigation(); // Use the custom hook

  const openDrawerMenuSlide = useCallback(() => {
    setDrawerMenuSlideOpen(true);
  }, []);

  const closeDrawerMenuSlide = useCallback(() => {
    setDrawerMenuSlideOpen(false);
  }, []);

  return (
    <>
      <div className="w-full h-screen relative bg-whitesmoke-100 flex flex-col items-start justify-start text-left text-5xs text-royalblue font-dm-sans lg:h-screen md:w-auto md:[align-self:unset] sm:w-auto sm:[align-self:unset] sm:pl-0 sm:box-border">
        <div className="self-stretch flex-1 flex flex-row items-start justify-start gap-[16px] lg:flex-1 md:self-stretch md:w-auto md:flex-1 md:gap-[0px] md:items-start md:justify-center sm:self-stretch sm:w-auto sm:flex-1 sm:gap-[0px] sm:pl-0 sm:box-border">
          <InventoryMemoryFragmentSide
            terraquestLogoBig1="/terraquestlogobig-1@2x.png"
            inventoryMixBlendMode="normal"
            onDashboardClick={onDashboardClick}
            onProfileClick={onProfileClick}
            onInventoryClick={onInventoryClick}
            onMyNFTClick={onMyNFTClick}
            onLogoutClick={onLogoutClick}
            onInventoryPlacedBookmarkClick={onInventoryPlacedBookmarkClick}
          />
          <div className="self-stretch flex-1 flex flex-col items-center justify-between lg:h-auto md:flex-1 md:gap-[16px] sm:flex-1 sm:gap-[8px] sm:items-center sm:justify-between sm:pl-0 sm:box-border">
            <div className="w-[944px] bg-colors-white-100 shadow-[0px_3.5px_5.5px_rgba(0,_0,_0,_0.02)] hidden flex-row items-center justify-between py-2 px-4 box-border lg:hidden md:flex md:self-stretch md:w-auto md:pl-4 md:pr-4 md:box-border sm:self-stretch sm:w-auto sm:pl-4 sm:box-border">
              <img
                className="w-[92.8px] relative h-14 object-cover"
                alt=""
                src="/terraquestlogobig-2@2x.png"
              />
              <img
                className="w-12 relative h-12 overflow-hidden shrink-0 cursor-pointer"
                alt=""
                src="/iconamoonmenuburgerhorizontalbold.svg"
                onClick={openDrawerMenuSlide}
              />
            </div>
            <main className="self-stretch flex flex-col items-center justify-start py-0 px-4 gap-[8px] text-left text-sm text-dimgray-200 font-dm-sans lg:h-auto md:self-stretch md:w-auto md:h-auto md:gap-[16px] sm:w-auto sm:[align-self:unset] sm:h-auto sm:gap-[8px] sm:pl-2 sm:pr-2 sm:box-border">
              <Header />
              <div className="self-stretch rounded-2xl bg-colors-white-100 flex flex-col items-start justify-start p-4 lg:h-auto md:w-auto md:[align-self:unset] md:items-center md:justify-start md:p-4 md:box-border sm:w-auto sm:[align-self:unset] sm:items-center sm:justify-center sm:p-2 sm:box-border">
                <div className="self-stretch rounded-2xl flex flex-col items-start justify-start py-2 px-4 gap-[8px] border-[2px] border-dashed border-whitesmoke-300 md:w-auto md:[align-self:unset] md:gap-[32px] md:items-center md:justify-start sm:gap-[16px] sm:p-2 sm:box-border">
                  <div className="h-8 flex flex-row items-center justify-start md:self-stretch md:w-auto md:items-center md:justify-center sm:pl-2 sm:box-border">
                    <h1
                      className="m-0 h-[17px] relative text-inherit leading-[120%] uppercase font-bold font-inherit inline-block md:text-base sm:text-sm"
                      id="Dashboard"
                    >{`Transmute memory fragment `}</h1>
                  </div>
                  <div className="flex flex-row items-start justify-start md:flex-col md:gap-[16px] md:items-center md:justify-start sm:flex-col sm:items-center sm:justify-start">
                    <div className="flex flex-row items-center justify-start sm:gap-[8px]">
                      <div className="rounded-md flex flex-col items-start justify-start p-[30px] bg-[url('/public/memontotransmutation@3x.png')] bg-cover bg-no-repeat bg-[top] sm:w-auto sm:[align-self:unset] sm:p-6 sm:box-border">
                        <img
                          className="w-[180px] relative h-[180px] object-cover lg:w-40 lg:h-40 md:w-[120px] md:h-[120px] sm:w-[100px] sm:h-[100px]"
                          alt=""
                          src="/image-35@2x.png"
                        />
                      </div>
                    </div>
                  </div>
                  <h1
                    className="m-0 relative text-5xl leading-[120%] uppercase font-bold font-inherit md:text-5xl sm:text-lg sm:leading-[60px] sm:self-stretch sm:w-auto sm:h-10 sm:active:self-stretch sm:active:w-auto"
                    id="Dashboard"
                  >
                    Transmutation Successful
                  </h1>
                  <div className="relative tracking-[0.02em] leading-[150%] font-medium font-dm-sans md:text-sm md:text-center sm:text-xs sm:w-60">
                    <p className="m-0">
                      Congratulations! Your Memento is now available for use in
                      TerraQuest.
                    </p>
                    <p className="m-0">Please check your in-game inventory.</p>
                  </div>
                  <div className="flex flex-row items-start justify-start gap-[16px] md:self-stretch md:w-auto md:items-start md:justify-center sm:flex-col sm:gap-[4px] sm:items-center sm:justify-start">
                    <button className="cursor-pointer [border:none] py-0 px-6 bg-dimgray-200 w-60 rounded-lg h-8 flex flex-row items-center justify-center box-border hover:bg-rosybrown active:[background:linear-gradient(#fff,_#fff),_#362222] md:w-[220px] md:h-8">
                      <p className="font-medium relative text-sm tracking-[0.02em] leading-[100%] font-dm-sans text-colors-white-100 text-left sm:text-xs">
                        Check your Inventory
                      </p>
                    </button>
                    <button className="cursor-pointer py-0 px-6 bg-[transparent] w-60 rounded-lg box-border h-8 flex flex-row items-center justify-center mix-blend-normal border-[1px] border-solid border-dimgray-200 md:w-[220px] md:h-8">
                      <b className="relative text-sm tracking-[0.02em] leading-[100%] font-dm-sans text-dimgray-200 text-left sm:text-xs">
                        Reconvert another item
                      </b>
                    </button>
                  </div>
                </div>
              </div>
            </main>
            <TermsCondition
              privacyPolicyFontWeight="bold"
              termsAndServicesFontWeight="bold"
              copyright2023FontWeight="bold"
            />
            <div className="w-[944px] bg-colors-white-100 h-12 hidden flex-row items-center justify-between py-2 px-6 box-border md:flex md:self-stretch md:w-auto sm:flex sm:gap-[8px] sm:pl-6 sm:pt-2 sm:pb-4 sm:box-border">
              <div className="flex flex-col items-center justify-start">
                <img
                  className="w-8 relative rounded h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/bxsdashboard13.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Dashboard
                </b>
              </div>
              <div className="flex flex-col items-center justify-start">
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/Inventory_icon.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Inventory
                </b>
              </div>
              <div className="hidden flex-col items-center justify-start">
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/Inventory_icon.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Logout
                </b>
              </div>
              <div className="flex flex-col items-center justify-start">
                <div className="w-8 relative rounded h-7 overflow-hidden shrink-0 mix-blend-normal">
                  <img
                    className="absolute top-[calc(20%_-_8px)] left-[calc(20%_-_8px)] w-8 h-8"
                    alt=""
                    src="/Profile_icon.svg"
                  />
                </div>
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Profile
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDrawerMenuSlideOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top"
          onOutsideClick={closeDrawerMenuSlide}
        >
          <DrawerMenuSlide onClose={closeDrawerMenuSlide} />
        </PortalDrawer>
      )}
    </>
  );
};

export default MemoryFragmentConvertSucces;
