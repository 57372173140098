import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Client, Session } from "@heroiclabs/nakama-js";

declare var google: any;

export default function googleauthLinking(props: any) {
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const navigate = useNavigate();

  function decodeJwtResponse(token: string) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
  function handleCredentialResponse(response: any) {

    const responsePayload = decodeJwtResponse(response.credential);

    nakamaGoogle(response.credential, responsePayload.email);
    setisLoggedIn(true);
  }

  window.onload = function () {
    google.accounts.id.initialize({
      client_id:
        "372509649387-1qjgvtas93k6cjaok1mn42jlg52aklep.apps.googleusercontent.com",
      callback: handleCredentialResponse,
      ux_mode: "popup",
      login_uri: "http://localhost:3000",
      auto_select: false,
    });
  };

  google.accounts.id.renderButton(document.getElementById("signinDiv"), {
    type: "icon",
    size: "large",
    shape: "circle",
    width: 8,
    click_listener: onClickHandler,
  });

  function onClickHandler() {
    setisLoggedIn(true);
    console.log("Sign in with Google button clicked...");
  }

  function onSignout() {
    setisLoggedIn(false);
    console.log("Sign out with Google button clicked...");
    window.location.reload();
  }

  // -------------------------------------------------- Connect to Nakama -----------------------------------------------------
  // ... (previous code)

  // -------------------------------------------------- Connect to Nakama -----------------------------------------------------
  async function nakamaGoogle(googletoken: string, email: any) {

    const sessionData = localStorage.getItem("session");

    if (sessionData !== null) {
      const session = JSON.parse(sessionData);
      if (session && session != "") {
        let sessionCheck = Session.restore(
          session.token,
          session.refresh_token
        );
        const currentRoute = window.location.pathname;

        const useSSL = window.location.protocol === "https:";
        const client = new Client(
          useSSL ? process.env.REACT_APP_CLIENT_KEY! : process.env.REACT_APP_DEV_CLIENT_KEY!,
          useSSL ? process.env.REACT_APP_API_HOST! : process.env.REACT_APP_DEV_API_HOST!,
          useSSL ? process.env.REACT_APP_API_PORT! : process.env.REACT_APP_DEV_API_PORT!,
          useSSL
        );

        const googleTokenObject = { token: googletoken };

        try {
          const linkEmail = await client.linkGoogle(
            sessionCheck,
            googleTokenObject
          );

          const emailData = email;

          const result = await client.rpc(
            sessionCheck,
            "UpdateMetadata",
            JSON.parse(`{"metadata": {"email": "${emailData}"}}`)
          );
          // props.setFacebookLink(true);
          props.toast.success("Successfully Link Google");
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        } catch (error) {
          // props.setFacebookLink(true);
          props.toast.error("Account already link to other user.");
          setTimeout(() => {
            window.location.reload();
          }, 5000);
          // Handle the error appropriately, such as redirecting to the login page or displaying an error message
        }
      }
    }
  }

  // -------------------------------------------------- End Connect to Nakama -------------------------------------------------

  return (
    <>
      <div id="signinDiv" className="-translate-y-1 scale-90"></div>
    </>
  );
}
