import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

// Define the hook inside a functional component or a custom hook
const useNavigation = () => {
    const navigate = useNavigate();

    const onDashboardClick = useCallback(() => {
        navigate("/dashboard");
    }, [navigate]);

    const onProfileClick = useCallback(() => {
        navigate("/profile");
    }, [navigate]);

    const onInventoryClick = useCallback(() => {
        // navigate("/bookmarks");
    }, [navigate]);

    const onLogoutClick = useCallback(() => {
        navigate("/logout");
        // Please sync "Logout" to the project
    }, []);

    const onCatalogClick = useCallback(() => {
        navigate("/catalog");
        // Please sync "Logout" to the project
    }, []);

    const onInventoryPlacedBookmarkClick = useCallback(() => {
        navigate("/inventory");
        // Please sync "Logout" to the project
    }, [navigate]);

    const onInventoryMemoryFragmentClick = useCallback(() => {
        navigate("/memory-fragment");
        // Please sync "Logout" to the project
    }, [navigate]);

    const onInventoryCostumesClick = useCallback(() => {
        navigate("/attires");
        // Please sync "Logout" to the project
    }, [navigate]);
    const onInventoryAllItemClick = useCallback(() => {
        navigate("/inventory");
        // Please sync "Logout" to the project
    }, [navigate]);

    const onMyNFTClick = useCallback(() => {
        navigate("/mynfts");
        // Please sync "Logout" to the project
    }, [navigate]);



    return {
        onDashboardClick,
        onProfileClick,
        onInventoryClick,
        onMyNFTClick,
        onLogoutClick,
        onCatalogClick,
        onInventoryPlacedBookmarkClick,
        onInventoryMemoryFragmentClick,
        onInventoryCostumesClick,
        onInventoryAllItemClick
    };
};

export default useNavigation;
