import { FunctionComponent } from "react";

type ContainerButtonFormType = {
  /** Action props */
  ready?: any;
  onPrimaryButtonClick?: () => void;
  onPrimaryButtonContainerClick?: () => void;
};

const ContainerButtonForm: FunctionComponent<ContainerButtonFormType> = ({
  ready,
  onPrimaryButtonClick,
  onPrimaryButtonContainerClick,
}) => {
  return (
    <div className="self-stretch flex flex-col items-center justify-start gap-[24px] text-left text-sm text-colors-white-100 font-dm-sans sm:items-center sm:justify-start">
      <div className="self-stretch flex flex-row items-start justify-center gap-[8px] sm:gap-[4px]">
        <button
          className={`cursor-pointer [border:none] py-0 px-5 bg-dimgray-200 rounded-lg h-8 flex flex-row items-center justify-center box-border sm:w-auto sm:[align-self:unset] sm:h-8 sm:rounded-lg sm:pt-0 sm:box-border
          `}
          onClick={onPrimaryButtonClick}
        >
          <img
            className={`w-[5.3px] relative h-[10.7px] object-contain
            
            `}
            alt=""
            src="/vector2.svg"
          />
        </button>
        <div
          className={`flex-1 rounded-lg bg-royalblue h-8 flex flex-row items-center justify-center py-0 px-3 box-border cursor-pointer hover:bg-steelblue active:bg-darkslategray-200 lg:w-auto lg:[align-self:unset] lg:gap-[4px] lg:pl-4 lg:pr-4 lg:box-border md:pl-4 md:pr-4 md:box-border sm:flex-1 sm:h-8 sm:gap-[8px]
          
          `}
          onClick={onPrimaryButtonContainerClick}
        >
          <b className="relative tracking-[0.02em] leading-[100%] lg:font-medium lg:font-dm-sans lg:text-sm lg:text-left sm:text-xs mq350small:text-3xs">
            Confirm Code
          </b>
        </div>
        <button
          className={`cursor-pointer [border:none] py-0 px-4 bg-dimgray-200 h-8 flex-1 rounded-lg flex flex-row items-center justify-center box-border sm:w-[120px] sm:h-8 sm:rounded-lg sm:pt-0 sm:box-border
        
        `}
        >
          <b className="h-3.5 flex-1 relative text-sm tracking-[0.02em] leading-[100%] inline-block font-dm-sans text-colors-white-100 text-center sm:text-xs mq350small:text-3xs">
            Resend Code
          </b>
        </button>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[8px] text-dimgray-200 font-dm-sans sm:items-center sm:justify-start">
        <h1
          className={`m-0 relative text-inherit leading-[120%] uppercase font-bold font-inherit md:text-sm sm:text-xs sm:text-center mq350small:text-xs
         
          `}
          id="Dashboard"
        >
          make sure to keep this open while checking
        </h1>
        <h1
          className={`m-0 relative text-xs leading-[100%] font-medium font-dm-sans md:text-sm sm:text-xs mq350small:text-3xs
          
          `}
          id="Dashboard"
        >
          Verification code expire in 5 minutes
        </h1>
      </div>
    </div>
  );
};

export default ContainerButtonForm;
