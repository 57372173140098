import React, {
  CSSProperties,
  FunctionComponent,
  ReactNode,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { useAccount } from "wagmi";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2"; // Assuming you're using Swal for the alert

type DrawerProps = {
  overlayColor?: string;
  placement?: "Left" | "Right" | "Top" | "Bottom";
  onOutsideClick?: () => void;
  zIndex?: number;
  children: ReactNode;
};

const PortalDrawer: FunctionComponent<DrawerProps> = ({
  children,
  overlayColor,
  placement = "Left",
  onOutsideClick,
  zIndex = 100,
}) => {
  const drawerStyle = useMemo(() => {
    const style: CSSProperties = {};
    style.zIndex = zIndex;

    if (overlayColor) {
      style.backgroundColor = overlayColor;
    }

    switch (placement) {
      case "Left":
        style.alignItems = "flex-start";
        break;
      case "Right":
        style.alignItems = "flex-end";
        break;
      case "Top":
        style.alignItems = "center";
        break;
      case "Bottom":
        style.alignItems = "center";
        style.justifyContent = "flex-end";
        break;
    }

    return style;
  }, [placement, overlayColor, zIndex]);

  const onOverlayClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (
        onOutsideClick &&
        (e.target as HTMLElement).classList.contains("portalPopupOverlay")
      ) {
        onOutsideClick();
      }
      e.stopPropagation();
    },
    [onOutsideClick]
  );

  const { address, isConnected, status } = useAccount();
  const [prevStatus, setPrevStatus] = useState(status);

  useEffect(() => {
    if (prevStatus === "connecting" && status === "connected") {
      Swal.fire({
        title: "Connected",
        text: "You have successfully connected.",
        icon: "success",
      });
    }
    setPrevStatus(status);
  }, [status, prevStatus]);

  return (
    <DrawerContainer>
      <ToastContainer />
      <div
        className={`flex flex-col fixed inset-0 portalPopupOverlay
        ${status === "connecting" && "overlayModal"}
        `}
        style={drawerStyle}
        onClick={onOverlayClick}
      >
        {children}
      </div>
    </DrawerContainer>
  );
};

type DrawerContainerProps = {
  children: ReactNode;
  containerId?: string;
};

export const DrawerContainer: FunctionComponent<DrawerContainerProps> = ({
  children,
  containerId = "portals",
}) => {
  let portalsDiv = document.getElementById(containerId);
  if (!portalsDiv) {
    portalsDiv = document.createElement("div");
    portalsDiv.setAttribute("id", containerId);
    document.body.appendChild(portalsDiv);
  }

  return createPortal(children, portalsDiv);
};

export default PortalDrawer;
