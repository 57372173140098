// Update InventoryCardCatalog component
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import InfiniteScroll from "react-infinite-scroll-component";
import AWS from "aws-sdk";
import ModalPage from "./Modal";

import CustomTooltip from "../components/tooltip";
import "react-placeholder/lib/reactPlaceholder.css";
import { Client, Session } from "@heroiclabs/nakama-js";
import { reAuthenticate } from "../api/auth";

import {
  getMyInventoryItems,
  getAllItemsSet,
  calculateInitialItemCount,
  getSpecialToolID,
} from "../api/storage";

import { useNavigate } from "react-router-dom";

export default function InventoryCardAlItem(props: any) {
  const [showModalTopRight, setShowModalTopRight] = useState(false);
  const [allItems, setAllItems] = useState<any[]>([]);
  const [specialToolID, setSpecialToolID] = useState<any[]>([]);

  const [currentItems, setCurrentItems] = useState<any | null>(null);
  const [client, setClient] = useState<any | null>(null);
  const [ready, setReady] = useState<boolean>(false);

  const [items, setItems] = useState<any[]>([]);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const useSSL = window.location.protocol === "https:";
        const client = new Client(
          useSSL ? process.env.REACT_APP_CLIENT_KEY! : process.env.REACT_APP_DEV_CLIENT_KEY!,
          useSSL ? process.env.REACT_APP_API_HOST! : process.env.REACT_APP_DEV_API_HOST!,
          useSSL ? process.env.REACT_APP_API_PORT! : process.env.REACT_APP_DEV_API_PORT!,
          useSSL
        );
        setClient(client);
        const sessionData = localStorage.getItem("session");

        if (sessionData !== null) {
          const session = JSON.parse(sessionData);

          if (session && session != "") {
            let sessionCheck = Session.restore(
              session.token,
              session.refresh_token
            );
            // Create a new Date object with the Unix timestamp in milliseconds
            //@ts-ignore
            const expiresAtDate = new Date(sessionCheck?.expires_at * 1000);

            const currentTime = new Date();

            if (currentTime >= expiresAtDate) {
              // Navigate to login or take appropriate action
              reAuthenticate(navigate);
            } else {
              let acc = client.getAccount(sessionCheck);
              // Get my inventory items
              let getMyItems = await getMyInventoryItems(
                sessionCheck,
                client,
                (await acc).user?.id
              );

              let getAllItems = await getAllItemsSet(sessionCheck, client);

              let getSpecialToolID1 = await getSpecialToolID(
                sessionCheck,
                client
              );

              setSpecialToolID(getSpecialToolID1.collection);

              // Create a lookup table for uniqueIDs
              const inventoryItemsLookup = getMyItems?.items.reduce((acc: any, item: any) => {
                if (item.itemID !== null) {
                  acc[item.itemID] = item.uniqueID || null;
                }
                return acc;
              }, {});


              // Filter out matching items and add uniqueID using the lookup table
              const filteredCostumeSet = getAllItems?.collection
                .filter((costume: any) => inventoryItemsLookup?.hasOwnProperty(costume.ID_no_item))
                .map((costume: any) => {
                  return {
                    ...costume,
                    uniqueID: inventoryItemsLookup[costume.ID_no_item] || null
                  };
                });




              const specialToolItems = getSpecialToolID1.collection;

              const filteredCostumeSetList = filteredCostumeSet?.map(
                (costume: any) => {
                  const matchingToolItem = specialToolItems.find(
                    (toolItem: any) =>
                      toolItem.ID_no_item === costume.ID_no_item
                  );

                  // If there's a matching tool item, merge the properties
                  if (matchingToolItem) {
                    return { ...costume, ...matchingToolItem };
                  }
                  // If no match, return the costume as is
                  return costume;
                }
              );

              const initialItems = await calculateInitialItemCount(
                filteredCostumeSetList,
                isMobile
              );

              setItems(initialItems);

              // Listen for window resize event and recalculate
              const handleResize = async () => {
                const updatedItems = await calculateInitialItemCount(
                  filteredCostumeSetList,
                  isMobile
                );
                setItems(updatedItems);
              };
              window.addEventListener("resize", handleResize);
              setReady(true);

              // Clean up the event listener
              return () => {
                window.removeEventListener("resize", handleResize);
              };
            }
          }
        } else {
          reAuthenticate(navigate);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // reAuthenticate(navigate);

        // reAuthenticate(navigate);
      }
    };

    fetchData();
  }, []); // Only fetch once when component mounts

  const fetchMoreItems = () => {
    // Determine the range of items to fetch
    const startIndex = items.length;
    const endIndex = startIndex + 13;
    // Fetch next batch of items
    const moreItems = allItems.slice(startIndex, endIndex);
    // Append new items to the existing list
    setItems((prevItems) => [...prevItems, ...moreItems]);
  };

  const onItemClick = (item: any) => {
    setCurrentItems(item);
    setShowModalTopRight(true);
  };

  return (
    <div
      id="scrollableDiv"
      className="w-full self-stretch pl-0 rounded-xl bg-colors-white-100 p-4 text-left text-sm text-dimgray-200 font-dm-sans lg:h-auto md:w-full md:[align-self:unset] md:items-center md:justify-start md:p-4 md:box-border sm:w-full sm:[align-self:unset] sm:items-center sm:justify-center sm:p-2 sm:box-border"
      style={{
        paddingLeft: "21px",
        paddingRight: "31px",
        overflowY: "auto",
        height: "80vh", // Set the height to prevent body scroll
      }}
    >
      <InfiniteScroll
        dataLength={items.length}
        next={fetchMoreItems}
        hasMore={items.length < allItems.length}
        loader={<h4></h4>}
        scrollableTarget="scrollableDiv"
      >
        <div className="self-stretch rounded-xl py-2 px-4 border-[2px] border-dashed border-whitesmoke-300 md:w-auto md:[align-self:unset] sm:gap-[0px] sm:py-2 sm:px-1 sm:box-border">
          <div className="h-8 flex flex-row items-center justify-start gap-[8px] sm:pl-2 sm:box-border">
            <h1
              className={`m-0 h-[17px] relative text-inherit leading-[120%] uppercase font-bold font-inherit inline-block md:text-base sm:text-sm
                        `}
              id="Dashboard"
            >
              All Items
            </h1>

            <div className="group relative my-12 flex justify-center">
              <CustomTooltip content="This is a tooltip">
                <button style={{ maxWidth: "300px", minWidth: "200px" }}>
                  {" "}
                  Items that you have obtained in-game will appear here.
                </button>
              </CustomTooltip>
            </div>
          </div>

          <div className="grid gridBoxesCostume">
            {!ready ? (
              <>
                {[...Array(Math.max(15 - items.length, 0))].map((_, index) => (
                  <div
                    key={`empty-${index}`}
                    className={`
                          bg-slate-200 text-transparent animate-pulse rounded-lg
                !z-5 relative border-[#E1DEEE] border-2 border-solid flex flex-col rounded-xl max-w-[180px] h-[160px] bg-clip-border shadow-3xl shadow-shadow-500 flex flex-col w-full  undefined
                
                `}
                  ></div>
                ))}
              </>
            ) : (
              <>
                {items.map((product, index) => (
                  <>
                    <div
                      key={index}
                      className="!z-5 relative border-[#E1DEEE] border-2 border-solid flex flex-col rounded-xl max-w-[180px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 flex flex-col w-full bg-white undefined
                      hover:border-[#2F6DD5]"
                    >
                      <div className="h-full">
                        <div
                          className="relative w-full flex flex-col"
                          onClick={() => onItemClick(product)}
                        >
                          <img
                            key={index}
                            src={
                              product?.Link ? product.Link : "/coming-soon.png"
                            }
                            className="h-[150px] w-full  transition duration-500 object-cover rounded-t-xl
                            hover:scale-[1.01]"
                            alt={`Image ${index}`}
                          />

                          {/* hover:scale-[1.02] */}
                          <span className="text-xs font-bold text-navy-700 mb-0 pb-2 mt-[1rem] pt-0 px-3 text-center block lg:w-[150px] break-words">
                            {product?.Name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </>
            )}
          </div>
        </div>
      </InfiniteScroll>
      <ModalPage
        title="Item Details"
        setShowModalTopRight={setShowModalTopRight}
        showModalTopRight={showModalTopRight}
        currentItems={currentItems} // Pass currentItems here
      />
    </div>
  );
}
