import { FunctionComponent } from "react";
import SidebarComponent from "./SidebarComponent";

type DashboardSidebarComponentType = {
  terraquestLogoBig1?: string;

  /** Action props */
  onDashboardClick?: () => void;
  onProfileClick?: () => void;
  onInventoryClick?: () => void;
  onInventoryMemoryFragmentClick?: () => void;
  onInventoryCostumesClick?: () => void;
  onInventoryAllItemClick?: () => void;
  onMyNFTClick?: () => void;
  onLogoutClick?: () => void;
  onCatalogClick?: () => void;
  ready?: any;
  onInventoryPlacedBookmarkClick?: () => void;
};

const DashboardSidebarComponent: FunctionComponent<
  DashboardSidebarComponentType
> = ({
  terraquestLogoBig1,
  onDashboardClick,
  onProfileClick,
  onInventoryClick,
  onCatalogClick,
  onInventoryMemoryFragmentClick,
  onInventoryCostumesClick,
  onInventoryAllItemClick,
  onMyNFTClick,
  onLogoutClick,
  ready,
  onInventoryPlacedBookmarkClick,
}) => {
  return (
    <div className="flex flex-row items-start justify-start self-stretch">
      <SidebarComponent
        ready={ready}
        terraquestLogoBig1="/terraquestlogobig-11@2x.png"
        bxsdashboard="/Dashboard_icon.svg"
        bxsdashboard1="/bxsdashboard1.svg"
        catalogIcon="Catalog.svg"
        catalogFontWeight="500"
        catalogColor="#5a3f3f"
        bxsdashboard2="/bxsdashboard2.svg"
        bxsdashboard3="/bxsdashboard4.svg"
        placedBookmark="My Bookmarks"
        bxsdashboard4="/bxsdashboard5.svg"
        memoryFragment="Memory Fragments"
        bxsdashboard5="/bxsdashboard6.svg"
        costumes="Attires"
        bxsdashboard6="/bxsdashboard7.svg"
        allItem="Inventory"
        bxsdashboard7="/bxsdashboard3.svg"
        logouticon="/bxsdashboard11.svg"
        myNFT="My NFTs"
        createACharacterDesign="Create a Character Design"
        inventoryAllItem
        inventoryPlacedBookmark={false}
        showIconParkSoliddownOne
        inventoryMemoryFragment={false}
        inventoryCostumes={false}
        sidebarComponentAlignSelf="stretch"
        sidebarComponentHeight="100vh"
        dashboardBorderRadius="4px"
        dashboardOpacity="unset"
        dashboardColor="#2F6DD5"
        dashboardFontWeight="bold"
        profileOpacity="0.2"
        profileColor="#5a3f3f"
        inventoryOpacity="0.2"
        inventoryMixBlendMode="unset"
        inventoryColor="#5a3f3f"
        inventoryPlacedBookmarkOpacity="0.2"
        inventoryPlacedBookmarkAlignSelf="unset"
        placedBookmarkColor="#5a3f3f"
        iconParkSoliddownOneWidth="16px"
        iconParkSoliddownOnePosition="relative"
        iconParkSoliddownOneHeight="16px"
        iconParkSoliddownOneOverflow="hidden"
        iconParkSoliddownOneBorder="unset"
        iconParkSoliddownOnePadding="unset"
        iconParkSoliddownOneBackgroundColor="unset"
        iconParkSoliddownOneBorderRadius="unset"
        iconParkSoliddownOneFlexDirection="unset"
        iconParkSoliddownOneGap="unset"
        iconParkSoliddownOneOpacity="unset"
        inventoryMemoryFragmentOpacity="0.2"
        inventoryMemoryFragmentBorder="none"
        inventoryMemoryFragmentPadding="4px 16px"
        inventoryMemoryFragmentBackgroundColor="transparent"
        inventoryMemoryFragmentBorderRadius="8px"
        inventoryMemoryFragmentFlexDirection="row"
        inventoryMemoryFragmentGap="8px"
        inventoryMemoryFragmentWidth="unset"
        inventoryMemoryFragmentPosition="unset"
        inventoryMemoryFragmentHeight="unset"
        inventoryMemoryFragmentOverflow="unset"
        bxsdashboardIconWidth="24px"
        bxsdashboardIconBorderRadius="4px"
        bxsdashboardIconHeight="24px"
        bxsdashboardIconOverflow="hidden"
        bxsdashboardIconFontSize="unset"
        bxsdashboardIconLetterSpacing="unset"
        bxsdashboardIconLineHeight="unset"
        bxsdashboardIconFontWeight="unset"
        bxsdashboardIconFontFamily="unset"
        bxsdashboardIconColor="unset"
        bxsdashboardIconTextAlign="unset"
        bxsdashboardIconDisplay="unset"
        memoryFragmentColor="#5a3f3f"
        memoryFragmentWidth="104px"
        memoryFragmentFontSize="12px"
        memoryFragmentLetterSpacing="0.02em"
        memoryFragmentLineHeight="100%"
        memoryFragmentFontWeight="500"
        memoryFragmentFontFamily="'DM Sans'"
        memoryFragmentTextAlign="left"
        memoryFragmentDisplay="inline-block"
        memoryFragmentHeight="unset"
        memoryFragmentOverflow="unset"
        inventoryCostumesOpacity="0.2"
        costumesColor="#5a3f3f"
        inventoryAllItemOpacity="0.2"
        allItemColor="#5a3f3f"
        myNFTOpacity="0.2"
        myNFTAlignSelf="stretch"
        myNFTColor="#5a3f3f"
        designCatalogDisplay="inline-block"
        onDashboardClick={onDashboardClick}
        onProfileClick={onProfileClick}
        onInventoryClick={onInventoryClick}
        onMyNFTClick={onMyNFTClick}
        onInventoryMemoryFragmentClick={onInventoryMemoryFragmentClick}
        onInventoryCostumesClick={onInventoryCostumesClick}
        onInventoryAllItemClick={onInventoryAllItemClick}
        onLogoutClick={onLogoutClick}
        onInventoryPlacedBookmarkClick={onInventoryPlacedBookmarkClick}
        onCatalogClick={onCatalogClick}
      />
    </div>
  );
};

export default DashboardSidebarComponent;
