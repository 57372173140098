import { FunctionComponent, useState, useCallback, useEffect } from "react";
import { useMediaQuery } from "react-responsive"; // Import from the library
import { Client, Session } from "@heroiclabs/nakama-js";

import InventoryMemoryFragmentSide from "../components/InventoryMemoryFragmentSide";
import DrawerMenuSlide from "../components/DrawerMenuSlide";
import PortalDrawer from "../components/PortalDrawer";
import Header from "../components/Header";
import TermsCondition from "../components/TermsCondition";
import useNavigation from "../api/route"; // Import the custom hook
import DetailsBookmarkDesktop from "../components/Bookmarks/detail";
import Web3 from "web3";
import { useConnectWallet } from "@web3-onboard/react";

export default function MemoryFragment(props: any) {
  const [web3, setWeb3] = useState(null);
  const [contract, setContract] = useState<any | null>(null);
  const [account, setAccount] = useState("");

  const [isDrawerMenuSlideOpen, setDrawerMenuSlideOpen] = useState(false);
  const [ready, setReady] = useState<boolean>(false);
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();

  const [allItems, setallItems] = useState<any | null>(null);
  const isMobile = useMediaQuery({ maxWidth: 768 }); // Adjust the breakpoint as needed

  const [currentBackground, setCurrentBackground] = useState(
    "achievementflag1-2@2x.png"
  );
  const [currentItems, setCurrentItems] = useState<any | null>(null);

  const onItemClick = (item: any) => {
    setCurrentItems(item);

    if (item && item.img) {
      setCurrentBackground(item.img);
    }
  };

  useEffect(() => {
    const init = async () => {
      if (!wallet) return;

      await connect();

      const web3Instance = new Web3(wallet.provider);

      const contractABI = [
        { inputs: [], stateMutability: "nonpayable", type: "constructor" },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "approved",
              type: "address",
            },
            {
              indexed: true,
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "Approval",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "operator",
              type: "address",
            },
            {
              indexed: false,
              internalType: "bool",
              name: "approved",
              type: "bool",
            },
          ],
          name: "ApprovalForAll",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "receiver",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "NFTMinted",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "NFTUnboxed",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "previousOwner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "newOwner",
              type: "address",
            },
          ],
          name: "OwnershipTransferred",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: "address",
              name: "account",
              type: "address",
            },
          ],
          name: "Paused",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "bytes32",
              name: "role",
              type: "bytes32",
            },
            {
              indexed: true,
              internalType: "bytes32",
              name: "previousAdminRole",
              type: "bytes32",
            },
            {
              indexed: true,
              internalType: "bytes32",
              name: "newAdminRole",
              type: "bytes32",
            },
          ],
          name: "RoleAdminChanged",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "bytes32",
              name: "role",
              type: "bytes32",
            },
            {
              indexed: true,
              internalType: "address",
              name: "account",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "sender",
              type: "address",
            },
          ],
          name: "RoleGranted",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "bytes32",
              name: "role",
              type: "bytes32",
            },
            {
              indexed: true,
              internalType: "address",
              name: "account",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "sender",
              type: "address",
            },
          ],
          name: "RoleRevoked",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              indexed: true,
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "Transfer",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: "address",
              name: "account",
              type: "address",
            },
          ],
          name: "Unpaused",
          type: "event",
        },
        {
          inputs: [],
          name: "DEFAULT_ADMIN_ROLE",
          outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "MINTER_ROLE",
          outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "PAUSER_ROLE",
          outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address[]",
              name: "_addresses",
              type: "address[]",
            },
          ],
          name: "addToWhitelistedDest",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address[]",
              name: "_addresses",
              type: "address[]",
            },
          ],
          name: "addToWhitelistedSource",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256", name: "tokenId", type: "uint256" },
          ],
          name: "approve",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [{ internalType: "address", name: "owner", type: "address" }],
          name: "balanceOf",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: "tokenId", type: "uint256" },
          ],
          name: "burn",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "enableWhitelistTransfer",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: "tokenId", type: "uint256" },
          ],
          name: "getApproved",
          outputs: [{ internalType: "address", name: "", type: "address" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [{ internalType: "bytes32", name: "role", type: "bytes32" }],
          name: "getRoleAdmin",
          outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "bytes32", name: "role", type: "bytes32" },
            { internalType: "uint256", name: "index", type: "uint256" },
          ],
          name: "getRoleMember",
          outputs: [{ internalType: "address", name: "", type: "address" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [{ internalType: "bytes32", name: "role", type: "bytes32" }],
          name: "getRoleMemberCount",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "bytes32", name: "role", type: "bytes32" },
            { internalType: "address", name: "account", type: "address" },
          ],
          name: "grantRole",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "bytes32", name: "role", type: "bytes32" },
            { internalType: "address", name: "account", type: "address" },
          ],
          name: "hasRole",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "owner", type: "address" },
            { internalType: "address", name: "operator", type: "address" },
          ],
          name: "isApprovedForAll",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: "tokenId", type: "uint256" },
          ],
          name: "isNFTBound",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [{ internalType: "address", name: "to", type: "address" }],
          name: "mint",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: "tokenId", type: "uint256" },
          ],
          name: "mint",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [],
          name: "mintingFee",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "name",
          outputs: [{ internalType: "string", name: "", type: "string" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "owner",
          outputs: [{ internalType: "address", name: "", type: "address" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: "tokenId", type: "uint256" },
          ],
          name: "ownerOf",
          outputs: [{ internalType: "address", name: "", type: "address" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "pause",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "paused",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address[]",
              name: "_addresses",
              type: "address[]",
            },
          ],
          name: "removeFromWhitelistedDest",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address[]",
              name: "_addresses",
              type: "address[]",
            },
          ],
          name: "removeFromWhitelistedSource",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "renounceOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "bytes32", name: "role", type: "bytes32" },
            { internalType: "address", name: "account", type: "address" },
          ],
          name: "renounceRole",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "bytes32", name: "role", type: "bytes32" },
            { internalType: "address", name: "account", type: "address" },
          ],
          name: "revokeRole",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: "", type: "uint256" },
            { internalType: "uint256", name: "value", type: "uint256" },
          ],
          name: "royaltyInfo",
          outputs: [
            { internalType: "address", name: "receiver", type: "address" },
            { internalType: "uint256", name: "royaltyAmount", type: "uint256" },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "from", type: "address" },
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256", name: "tokenId", type: "uint256" },
          ],
          name: "safeTransferFrom",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "from", type: "address" },
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256", name: "tokenId", type: "uint256" },
            { internalType: "bytes", name: "data", type: "bytes" },
          ],
          name: "safeTransferFrom",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "operator", type: "address" },
            { internalType: "bool", name: "approved", type: "bool" },
          ],
          name: "setApprovalForAll",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [{ internalType: "string", name: "URI", type: "string" }],
          name: "setBaseURI",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "bool",
              name: "_enableWhitelistTransfer",
              type: "bool",
            },
          ],
          name: "setEnableWhitelist",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "recipient", type: "address" },
            { internalType: "uint256", name: "value", type: "uint256" },
          ],
          name: "setRoyalties",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [{ internalType: "string", name: "suffix", type: "string" }],
          name: "setTokenURISuffix",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "bytes4", name: "interfaceId", type: "bytes4" },
          ],
          name: "supportsInterface",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "symbol",
          outputs: [{ internalType: "string", name: "", type: "string" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [{ internalType: "uint256", name: "index", type: "uint256" }],
          name: "tokenByIndex",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "owner", type: "address" },
            { internalType: "uint256", name: "index", type: "uint256" },
          ],
          name: "tokenOfOwnerByIndex",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: "tokenId", type: "uint256" },
          ],
          name: "tokenURI",
          outputs: [{ internalType: "string", name: "", type: "string" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "totalSupply",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "from", type: "address" },
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256", name: "tokenId", type: "uint256" },
          ],
          name: "transferFrom",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "newOwner", type: "address" },
          ],
          name: "transferOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: "tokenId", type: "uint256" },
          ],
          name: "unbox",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "unpause",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [{ internalType: "address", name: "", type: "address" }],
          name: "whitelistedDest",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [{ internalType: "address", name: "", type: "address" }],
          name: "whitelistedSource",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "view",
          type: "function",
        },
      ];
      const contractAddress = "0xb7A9EA6b7D355d91287088d9833A93f648686065";
      const myContract = new web3Instance.eth.Contract(
        contractABI,
        contractAddress
      );
      setContract(myContract);

      const accounts = await web3Instance.eth.getAccounts();

      setAccount(accounts[0]);
    };

    init();
  }, [wallet]);

  const handleTransmuteClick = () => {
    if (1) {
      callContractFunction(5);
    } else {
      console.error("Parameter value is required.");
    }
  };

  const callContractFunction = async (tokenId: number) => {
    if (!contract) return;

    try {
      // Example: Call a write function named 'setNumber' that takes an integer parameter
      await contract.methods.burn(tokenId).send({ from: account });
    } catch (error) {
      console.error("Error calling contract function:", error);
    }
  };

  // @ts-nocheck
  async function getAllItems(this: any) {
    // const sessionData = localStorage.getItem("session");

    {
      // const session = JSON.parse(sessionData);

      {
        let sessionCheck = Session.restore(
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiI3YmEwN2M1Mi1hMjU5LTQ2OTktOWY0Ny1mY2ZjODg4MGQ0NDAiLCJ1c24iOiJyWkVRS2dqUWx6IiwiZXhwIjoxNzEzNzI1NzUxfQ.K-XHnrQxf7wUVT8KYv4FfC4Q-DxvnhqGLSRQqKWFc7U",
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiI3YmEwN2M1Mi1hMjU5LTQ2OTktOWY0Ny1mY2ZjODg4MGQ0NDAiLCJ1c24iOiJyWkVRS2dqUWx6IiwiZXhwIjoxNzEzNzIyMTUxfQ.getzmNA2qZc4nJnOWG_GTQide6lYZRfkuNNRHso4Hr8"
        );
        const currentTimeInSec = new Date().getTime() / 1000; // Corrected line

        if (!sessionCheck.isexpired(currentTimeInSec)) {
          // Session valid so restore it
          // console.log("Session Restored");

          const acc = await props.clientApi.getAccount(sessionCheck);

          const readStorage = {
            object_ids: [
              {
                collection: "Inventory",
                key: "memory-fragment",
                user_id: acc.user?.id,
              },
            ],
          };
          const result = await props.clientApi.readStorageObjects(
            sessionCheck,
            readStorage
          );

          if (result.objects.length > 0 && !undefined) {
            const items = result.objects[0].value;

            setallItems(items);
          } else {
            console.log("Not item found from storage");
          }
        } else {
          console.log("Session Expired");

          if (sessionCheck?.username) {
            let sessionRefreshExpired =
              await props.clientApi.authenticateCustom(
                sessionCheck?.username,
                true
              );

            const acc = await props.clientApi.getAccount(sessionRefreshExpired);

            const readStorage = {
              object_ids: [
                {
                  collection: "Inventory",
                  key: "memory-fragment",
                  user_id: acc.user?.id,
                },
              ],
            };
            const result = await props.clientApi.readStorageObjects(
              sessionRefreshExpired,
              readStorage
            );

            if (result.objects.length > 0 && !undefined) {
              const items = result.objects[0].value;

              setallItems(items);
            } else {
              console.log("Not item found from storage");
            }
          }
        }
      }
    }
  }
  useEffect(() => {
    getAllItems();

    setTimeout(() => {
      setReady(true);
    }, 3000);
  }, []);

  const {
    onDashboardClick,
    onProfileClick,
    onInventoryClick,
    onMyNFTClick,
    onLogoutClick,
    onInventoryPlacedBookmarkClick,
    onInventoryMemoryFragmentClick,
    onInventoryCostumesClick,
    onInventoryAllItemClick,
    onCatalogClick,
  } = useNavigation(); // Use the custom hook

  const openDrawerMenuSlide = useCallback(() => {
    setDrawerMenuSlideOpen(true);
  }, []);

  const closeDrawerMenuSlide = useCallback(() => {
    setDrawerMenuSlideOpen(false);
  }, []);

  const onPrimaryButtonClick = useCallback(() => {
    // Please sync "Prototype-Dashboard-Convert-Memory-Fragment" to the project
  }, []);

  return (
    <>
      <div className="w-full h-screen relative bg-whitesmoke-100 flex flex-col items-start justify-start text-left text-5xs text-royalblue font-dm-sans lg:h-screen md:w-auto md:[align-self:unset] sm:w-auto sm:[align-self:unset] sm:pl-0 sm:box-border">
        <div className="self-stretch flex-1 flex flex-row items-start justify-start gap-[16px] lg:flex-1 md:self-stretch md:w-auto md:flex-1 md:gap-[0px] md:items-start md:justify-center sm:self-stretch sm:w-auto sm:flex-1 sm:gap-[0px] sm:pl-0 sm:box-border">
          <InventoryMemoryFragmentSide
            ready={ready}
            terraquestLogoBig1="/terraquestlogobig-1@2x.png"
            inventoryMixBlendMode="normal"
            onDashboardClick={onDashboardClick}
            onProfileClick={onProfileClick}
            onInventoryClick={onInventoryClick}
            onMyNFTClick={onMyNFTClick}
            onLogoutClick={onLogoutClick}
            onInventoryMemoryFragmentClick={onInventoryMemoryFragmentClick}
            onInventoryPlacedBookmarkClick={onInventoryPlacedBookmarkClick}
            onInventoryCostumesClick={onInventoryCostumesClick}
            onInventoryAllItemClick={onInventoryAllItemClick}
            onCatalogClick={onCatalogClick}
          />
          <div className="self-stretch flex-1 flex flex-col items-center justify-between lg:h-auto md:flex-1 md:gap-[16px] sm:flex-1 sm:gap-[8px] sm:items-center sm:justify-between sm:pl-0 sm:box-border">
            <div className="w-[944px] bg-colors-white-100 shadow-[0px_3.5px_5.5px_rgba(0,_0,_0,_0.02)] hidden flex-row items-center justify-between py-2 px-4 box-border lg:hidden md:flex md:self-stretch md:w-auto md:pl-4 md:pr-4 md:box-border sm:self-stretch sm:w-auto sm:pl-4 sm:box-border">
              <img
                className="w-[92.8px] relative h-14 object-cover"
                alt=""
                src="/terraquestlogobig-2@2x.png"
              />
              <img
                className="w-12 relative h-12 overflow-hidden shrink-0 cursor-pointer"
                alt=""
                src="/iconamoonmenuburgerhorizontalbold.svg"
                onClick={openDrawerMenuSlide}
              />
            </div>
            <main className="self-stretch flex flex-col items-center justify-start py-0 px-4 gap-[8px] text-left text-sm text-dimgray-200 font-dm-sans lg:h-auto md:self-stretch md:w-auto md:h-auto md:gap-[16px] sm:w-auto sm:[align-self:unset] sm:h-auto sm:gap-[8px] sm:pl-2 sm:pr-2 sm:box-border">
              <Header ready={!ready} />
              <div
                className="self-stretch rounded-2xl bg-colors-white-100 flex flex-col items-start justify-start p-4 lg:h-auto md:w-auto md:[align-self:unset] md:items-center md:justify-start md:p-4 md:box-border sm:w-auto sm:[align-self:unset] sm:items-center sm:justify-center sm:p-2 sm:box-border
              maxContainer"
              >
                <div className="self-stretch rounded-2xl flex flex-col items-start justify-start py-2 px-4 border-[2px] border-dashed border-whitesmoke-300 md:w-auto md:[align-self:unset] sm:gap-[0px] sm:py-2 sm:px-1 sm:box-border">
                  <div className="h-8 flex flex-row items-center justify-start gap-[8px] sm:pl-2 sm:box-border">
                    <h1
                      className={`m-0 h-[17px] relative text-inherit leading-[120%] uppercase font-bold font-inherit inline-block md:text-base sm:text-sm
                      
                      `}
                      id="Dashboard"
                    >
                      Memory Fragment
                    </h1>
                    <div className="group relative my-12 flex justify-center">
                      <img
                        className="w-6 relative h-6 overflow-hidden shrink-0 sm:w-6 sm:h-6"
                        alt=""
                        src="/phinfobold.svg"
                      />

                      <span
                        className="z-[9999999] border-[#5A3F3F] border-solid border-2  absolute top-10 scale-0 rounded !bg-white p-2 text-xs text-[#5A3F3F] text-center group-hover:scale-100
              w-52
          "
                      >
                        Memory Fragments are NFTs that can be transmuted back
                        into the game. Please ensure that your wallet is
                        connected to display your Memory Fragments.
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-row items-start justify-center gap-[8px] text-5xs font-dm-sans sm:self-stretch sm:w-auto sm:flex-1 sm:flex-col sm:gap-[16px] sm:items-center sm:justify-start">
                    <div className="rounded-lg bg-colors-white-100 shadow-[0px_3.5px_5.5px_rgba(0,_0,_0,_0.02)] hidden flex-row items-center justify-start py-1 px-2 border-[1px] border-solid border-whitesmoke-200 sm:flex sm:w-auto sm:[align-self:unset] sm:gap-[4px]">
                      <div className="h-[140px] w-[140px] rounded-lg flex flex-col items-center justify-center bg-[url('/public/previewmobile2@3x.png')] bg-cover bg-no-repeat bg-[top] lg:hidden md:hidden sm:flex sm:w-[140px] sm:h-40">
                        <img
                          className="w-[100px] relative h-[100px] object-cover"
                          alt=""
                          src="/achievementflag1-2@2x.png"
                        />
                      </div>
                      <div className="flex flex-col items-center justify-center p-1 md:gap-[4px] md:pt-2 md:pb-4 md:box-border sm:pl-1 sm:pr-1 sm:box-border">
                        <div className="flex flex-col items-start justify-start gap-[2px]">
                          <b className="self-stretch relative text-xs tracking-[0.02em] leading-[100%]">
                            Memory Fragment#1
                          </b>
                          <div className="h-[18px] flex flex-row items-center justify-start gap-[11.65px]">
                            <b className="w-[61px] relative tracking-[0.02em] leading-[100%] inline-block shrink-0">
                              ID
                            </b>
                            <div className="flex flex-row items-center justify-start py-[4.661417484283447px] px-0 gap-[4.66px]">
                              <div className="relative leading-[19.77px] uppercase font-medium">
                                0x23 .... 23n
                              </div>
                              <img
                                className="w-3 relative h-3 overflow-hidden shrink-0"
                                alt=""
                                src="/phcopybold.svg"
                              />
                            </div>
                          </div>
                          <div className="h-[18px] flex flex-row items-center justify-start gap-[11.65px]">
                            <b className="w-[61px] relative tracking-[0.02em] leading-[100%] inline-block shrink-0">
                              Expiry
                            </b>
                            <b className="relative leading-[19.77px]">
                              50 D 12 H 2 M
                            </b>
                          </div>
                          <div className="h-[18px] flex flex-row items-center justify-start gap-[11.65px]">
                            <b className="w-[61px] relative tracking-[0.02em] leading-[100%] inline-block shrink-0">
                              Date Create
                            </b>
                            <div className="relative leading-[19.77px] uppercase font-medium">
                              11 / 12 / 2024
                            </div>
                          </div>
                          <div className="h-[18px] flex flex-row items-center justify-start gap-[11.65px]">
                            <b className="w-[61px] relative tracking-[0.02em] leading-[100%] inline-block shrink-0">
                              Owned by
                            </b>
                            <div className="relative leading-[19.77px] uppercase font-medium">
                              Andi haw
                            </div>
                          </div>
                          <div className="flex flex-row items-start justify-start gap-[11.65px]">
                            <b className="w-[61px] relative tracking-[0.02em] leading-[100%] inline-block shrink-0">
                              Content
                            </b>
                            <div className="relative leading-[140%] font-medium">
                              <ul className="m-0 font-inherit text-inherit pl-[11px]">
                                <li className="mb-0">Resources</li>
                                <li className="mb-0">NPC</li>
                                <li>Lorem ipsum</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-start gap-[16px] overflowMobilee">
                      <div className="flex flex-col items-start justify-start gap-[8px] md:gap-[4px] sm:self-stretch sm:w-auto sm:gap-[4px] sm:items-center sm:justify-center">
                        <div className="self-stretch flex flex-row items-start justify-start gap-[8px] lg:flex-row md:flex-row sm:flex sm:flex-row">
                          {/* DESKTOP UI */}
                          <div className="">
                            <div className="">
                              {[...Array(isMobile ? 2 : 4)].map(
                                (_, rowIndex) => (
                                  <div
                                    key={`row-${rowIndex}`}
                                    className="flex flex-row items-start justify-start gap-[8px] mb-4"
                                  >
                                    {[...Array(isMobile ? 3 : 4)].map(
                                      (_, colIndex) => {
                                        const itemIndex =
                                          rowIndex * (isMobile ? 3 : 4) +
                                          colIndex;
                                        const item =
                                          allItems &&
                                          allItems.items &&
                                          allItems.items[itemIndex];

                                        return (
                                          <button
                                            className={`cursor-pointer p-4 bg-[transparent] rounded-lg flex flex-row items-start justify-start border-[1px] border-solid border-black hover:box-border hover:border-[1px] hover:border-solid hover:border-royalblue active:box-border active:border-[1px] active:border-solid active:border-royalblue
                              ${
                                !ready
                                  ? "bg-slate-200 text-transparent animate-pulse rounded-lg"
                                  : ""
                              }
                              `}
                                            autoFocus={true}
                                            onClick={() => onItemClick(item)}
                                          >
                                            {item && item.img && props.ready ? (
                                              <div
                                                className={`relative w-20 h-20 lg:w-20 lg:h-20 md:w-16 md:h-16 sm:w-12 sm:h-12
                                  
                                  `}
                                                style={{
                                                  backgroundImage: `url(${item.img})`,
                                                  backgroundSize: "cover",
                                                }}
                                              />
                                            ) : (
                                              <div className="relative w-20 h-20 lg:w-20 lg:h-20 md:w-16 md:h-16 sm:w-12 sm:h-12" />
                                            )}
                                          </button>
                                        );
                                      }
                                    )}
                                  </div>
                                )
                              )}
                            </div>
                          </div>

                          {/* MOBILE UI */}
                          <div className="hidden flex-row items-start justify-start gap-[8px]">
                            <div className="flex flex-row items-start justify-start gap-[8px] md:flex-row sm:flex-row">
                              <div className="flex flex-col items-start justify-start gap-[8px]">
                                <button
                                  className="cursor-pointer p-4 bg-[transparent] rounded-lg flex flex-row items-start justify-start border-[1px] border-solid border-black hover:box-border hover:border-[1px] hover:border-solid hover:border-royalblue active:box-border active:border-[1px] active:border-solid active:border-royalblue"
                                  autoFocus={true}
                                >
                                  <div className="w-20 relative h-20 lg:w-20 lg:h-20 md:w-16 md:h-16 sm:w-12 sm:h-12" />
                                </button>
                                <button className="cursor-pointer p-4 bg-[transparent] rounded-lg flex flex-row items-start justify-start border-[1px] border-solid border-black hover:box-border hover:border-[1px] hover:border-solid hover:border-royalblue">
                                  <div className="w-20 relative h-20 lg:w-20 lg:h-20 md:w-16 md:h-16 sm:w-12 sm:h-12" />
                                </button>
                              </div>
                              <div className="flex flex-col items-start justify-start gap-[8px]">
                                <button className="cursor-pointer p-4 bg-[transparent] rounded-lg flex flex-row items-start justify-start border-[1px] border-solid border-black hover:box-border hover:border-[1px] hover:border-solid hover:border-royalblue">
                                  <div className="w-20 relative h-20 lg:w-20 lg:h-20 md:w-16 md:h-16 sm:w-12 sm:h-12" />
                                </button>
                                <button className="cursor-pointer p-4 bg-[transparent] rounded-lg flex flex-row items-start justify-start border-[1px] border-solid border-black hover:box-border hover:border-[1px] hover:border-solid hover:border-royalblue active:box-border active:border-[1px] active:border-solid active:border-royalblue">
                                  <div className="w-20 relative h-20 lg:w-20 lg:h-20 md:w-16 md:h-16 sm:w-12 sm:h-12" />
                                </button>
                              </div>
                            </div>
                            <div className="flex flex-row items-start justify-start gap-[8px] md:flex-row sm:flex-row">
                              <div className="flex flex-col items-start justify-start gap-[8px]">
                                <button
                                  className="cursor-pointer p-4 bg-[transparent] rounded-lg flex flex-row items-start justify-start border-[1px] border-solid border-black hover:box-border hover:border-[1px] hover:border-solid hover:border-royalblue active:box-border active:border-[1px] active:border-solid active:border-royalblue"
                                  autoFocus={true}
                                >
                                  <div className="w-20 relative h-20 lg:w-20 lg:h-20 md:w-16 md:h-16 sm:w-12 sm:h-12" />
                                </button>
                                <button className="cursor-pointer p-4 bg-[transparent] rounded-lg flex flex-row items-start justify-start border-[1px] border-solid border-black hover:box-border hover:border-[1px] hover:border-solid hover:border-royalblue">
                                  <div className="w-20 relative h-20 lg:w-20 lg:h-20 md:w-16 md:h-16 sm:w-12 sm:h-12" />
                                </button>
                              </div>
                              <div className="flex flex-col items-start justify-start gap-[8px]">
                                <button className="cursor-pointer p-4 bg-[transparent] rounded-lg flex flex-row items-start justify-start border-[1px] border-solid border-black hover:box-border hover:border-[1px] hover:border-solid hover:border-royalblue">
                                  <div className="w-20 relative h-20 lg:w-20 lg:h-20 md:w-16 md:h-16 sm:w-12 sm:h-12" />
                                </button>
                                <button className="cursor-pointer p-4 bg-[transparent] rounded-lg flex flex-row items-start justify-start border-[1px] border-solid border-black hover:box-border hover:border-[1px] hover:border-solid hover:border-royalblue active:box-border active:border-[1px] active:border-solid active:border-royalblue">
                                  <div className="w-20 relative h-20 lg:w-20 lg:h-20 md:w-16 md:h-16 sm:w-12 sm:h-12" />
                                </button>
                              </div>
                            </div>
                            <div className="flex flex-row items-start justify-start gap-[8px] md:flex-row sm:flex-row">
                              <div className="flex flex-col items-start justify-start gap-[8px]">
                                <button
                                  className="cursor-pointer p-4 bg-[transparent] rounded-lg flex flex-row items-start justify-start border-[1px] border-solid border-black hover:box-border hover:border-[1px] hover:border-solid hover:border-royalblue active:box-border active:border-[1px] active:border-solid active:border-royalblue"
                                  autoFocus={true}
                                >
                                  <div className="w-20 relative h-20 lg:w-20 lg:h-20 md:w-16 md:h-16 sm:w-12 sm:h-12" />
                                </button>
                                <button className="cursor-pointer p-4 bg-[transparent] rounded-lg flex flex-row items-start justify-start border-[1px] border-solid border-black hover:box-border hover:border-[1px] hover:border-solid hover:border-royalblue">
                                  <div className="w-20 relative h-20 lg:w-20 lg:h-20 md:w-16 md:h-16 sm:w-12 sm:h-12" />
                                </button>
                              </div>
                              <div className="flex flex-col items-start justify-start gap-[8px]">
                                <button className="cursor-pointer p-4 bg-[transparent] rounded-lg flex flex-row items-start justify-start border-[1px] border-solid border-black hover:box-border hover:border-[1px] hover:border-solid hover:border-royalblue">
                                  <div className="w-20 relative h-20 lg:w-20 lg:h-20 md:w-16 md:h-16 sm:w-12 sm:h-12" />
                                </button>
                                <button className="cursor-pointer p-4 bg-[transparent] rounded-lg flex flex-row items-start justify-start border-[1px] border-solid border-black hover:box-border hover:border-[1px] hover:border-solid hover:border-royalblue active:box-border active:border-[1px] active:border-solid active:border-royalblue">
                                  <div className="w-20 relative h-20 lg:w-20 lg:h-20 md:w-16 md:h-16 sm:w-12 sm:h-12" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="self-stretch flex flex-row items-start justify-start gap-[8px] lg:gap-[4px] lg:items-center lg:justify-center sm:flex-col sm:gap-[8px]">
                        <button
                          className={`cursor-pointer [border:none] py-0 px-3 bg-royalblue rounded-lg h-8 flex flex-row items-center justify-center box-border gap-[8px] hover:bg-steelblue active:bg-darkslategray-200 lg:w-auto lg:[align-self:unset] lg:gap-[4px] lg:pl-4 lg:pr-4 lg:box-border md:pl-4 md:pr-4 md:box-border sm:self-stretch sm:w-auto sm:h-8 sm:gap-[8px]

                          `}
                          onClick={onPrimaryButtonClick}
                        >
                          <>
                            <img
                              className="w-5 relative h-5 overflow-hidden shrink-0 lg:w-5 lg:h-5"
                              alt=""
                              src="/rirefreshline.svg"
                            />
                          </>

                          <b
                            onClick={handleTransmuteClick}
                            className="relative text-sm tracking-[0.02em] leading-[100%] font-dm-sans text-colors-white-100 text-left lg:font-medium lg:font-dm-sans lg:text-sm lg:text-left sm:text-xs"
                          >
                            Transmute
                          </b>
                        </button>
                        <div className="flex-1 flex flex-row items-start justify-start gap-[8px] sm:gap-[4px] sm:items-start sm:justify-center sm:flex-[unset] sm:self-stretch">
                          <button
                            className={`cursor-pointer py-0 px-6
                            
                            !text-white flex-1 rounded-lg box-border h-8 flex flex-row items-center justify-center  md:w-40 md:h-8 sm:flex-1
                            border-[1px] border-solid border-dimgray-200  !bg-dimgray-200 
                `}
                          >
                            <img
                              className="w-5 relative h-5 overflow-hidden shrink-0 lg:w-5 lg:h-5 mr-2 "
                              alt=""
                              src="/simpleiconsopensea.svg"
                            />
                            <b className="!text-white relative text-sm tracking-[0.02em] leading-[100%] font-dm-sans text-dimgray-200 text-left lg:text-xs sm:text-[11px]">
                              Sell
                              <span className="mobileHidden"> on OpenSea</span>
                            </b>
                          </button>
                          <button
                            className={`cursor-pointer py-0 px-3 bg-[transparent] rounded-lg box-border h-8 flex flex-row items-center justify-center gap-[8px] mix-blend-normal border-[1px] border-solid border-dimgray-200 lg:items-center lg:justify-center lg:pl-4 lg:pr-4 lg:box-border md:gap-[4px] md:pl-2 md:pr-2 md:box-border sm:flex-1 sm:h-8 sm:gap-[8px]
                            border-[1px] border-solid border-dimgray-200  !bg-dimgray-200  
                          `}
                          >
                            <img
                              className="w-5 relative h-5 overflow-hidden shrink-0 lg:w-5 lg:h-5 mr-2 "
                              alt=""
                              src="/simpleiconsopensea.svg"
                            />
                            <b className="!text-white relative text-sm tracking-[0.02em] leading-[100%] font-dm-sans text-dimgray-200 text-left lg:text-center sm:text-[11px]">
                              Buy
                              <span className="mobileHidden"> on OpenSea</span>
                            </b>
                          </button>
                        </div>
                      </div>
                    </div>
                    <DetailsBookmarkDesktop
                      image={currentBackground}
                      data={currentItems}
                      ready={ready}
                    />
                  </div>
                </div>
              </div>
            </main>
            <TermsCondition
              privacyPolicyFontWeight="bold"
              termsAndServicesFontWeight="bold"
              copyright2023FontWeight="bold"
            />
            <div
              className="w-[944px] bg-colors-white-100 h-20 hidden flex-row items-center justify-between py-2 px-6 box-border md:flex md:self-stretch md:w-auto sm:flex sm:gap-[8px] sm:pl-6 sm:pt-2 sm:pb-4 sm:box-border
             z-[9999999] fixed bottom-0 sm:w-full"
            >
              <div
                className="flex flex-col items-center justify-start !text-[#2F6DD5]"
                onClick={onDashboardClick}
              >
                <img
                  className="w-8 relative rounded h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/bxsdashboard13.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Dashboard
                </b>
              </div>
              <div
                className="flex flex-col items-center justify-start"
                onClick={onInventoryPlacedBookmarkClick}
              >
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/Inventory_icon.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Inventory
                </b>
              </div>
              <div className="hidden flex-col items-center justify-start">
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/Inventory_icon.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Logout
                </b>
              </div>
              <div
                className="flex flex-col items-center justify-start"
                onClick={onProfileClick}
              >
                <div className="w-8 relative rounded h-7 overflow-hidden shrink-0 mix-blend-normal">
                  <img
                    className="absolute top-[calc(20%_-_8px)] left-[calc(20%_-_8px)] w-8 h-8"
                    alt=""
                    src="/Profile_icon.svg"
                  />
                </div>
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Profile
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDrawerMenuSlideOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top"
          onOutsideClick={closeDrawerMenuSlide}
        >
          <DrawerMenuSlide onClose={closeDrawerMenuSlide} />
        </PortalDrawer>
      )}
    </>
  );
}
