import {
  FunctionComponent,
  useMemo,
  useCallback,
  type CSSProperties,
  useState,
  useEffect,
} from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

type SidebarComponentType = {
  ready?: any;
  terraquestLogoBig1?: string;
  bxsdashboard?: string;
  bxsdashboard1?: string;
  bxsdashboard2?: string;
  bxsdashboard3?: string;
  placedBookmark?: string;
  bxsdashboard4?: string;
  memoryFragment?: string;
  bxsdashboard5?: string;
  costumes?: string;
  bxsdashboard6?: string;
  allItem?: string;
  bxsdashboard7?: string;
  logouticon?: string;
  catalogIcon?: string;
  myNFT?: string;
  createACharacterDesign?: string;
  inventoryAllItem?: boolean;
  inventoryPlacedBookmark?: boolean;
  showIconParkSoliddownOne?: boolean;
  inventoryMemoryFragment?: boolean;
  inventoryCostumes?: boolean;

  /** Style props */
  sidebarComponentAlignSelf?: CSSProperties["alignSelf"];
  sidebarComponentHeight?: CSSProperties["height"];
  dashboardBorderRadius?: CSSProperties["borderRadius"];
  dashboardOpacity?: CSSProperties["opacity"];
  dashboardFontWeight?: CSSProperties["fontWeight"];
  logoutFontWeight?: CSSProperties["fontWeight"];
  catalogFontWeight?: CSSProperties["fontWeight"];
  gitbookFontWeight?: CSSProperties["fontWeight"];
  placedBookmarkWeight?: CSSProperties["fontWeight"];

  profileFontWeight?: CSSProperties["fontWeight"];
  inventoryFontWeight?: CSSProperties["fontWeight"];
  costumeFontWeight?: CSSProperties["fontWeight"];
  allItemFontWeight?: CSSProperties["fontWeight"];
  myNFTFontWeight?: CSSProperties["fontWeight"];

  dashboardColor?: CSSProperties["color"];
  logoutColor?: CSSProperties["color"];
  catalogColor?: CSSProperties["color"];
  gitbookColor?: CSSProperties["color"];
  profileOpacity?: CSSProperties["opacity"];
  profileColor?: CSSProperties["color"];
  inventoryOpacity?: CSSProperties["opacity"];
  inventoryMixBlendMode?: CSSProperties["mixBlendMode"];
  inventoryColor?: CSSProperties["color"];
  inventoryPlacedBookmarkOpacity?: CSSProperties["opacity"];
  inventoryPlacedBookmarkAlignSelf?: CSSProperties["alignSelf"];
  placedBookmarkColor?: CSSProperties["color"];
  iconParkSoliddownOneWidth?: CSSProperties["width"];
  iconParkSoliddownOnePosition?: CSSProperties["position"];
  iconParkSoliddownOneHeight?: CSSProperties["height"];
  iconParkSoliddownOneOverflow?: CSSProperties["overflow"];
  iconParkSoliddownOneBorder?: CSSProperties["border"];
  iconParkSoliddownOnePadding?: CSSProperties["padding"];
  iconParkSoliddownOneBackgroundColor?: CSSProperties["backgroundColor"];
  iconParkSoliddownOneBorderRadius?: CSSProperties["borderRadius"];
  iconParkSoliddownOneFlexDirection?: CSSProperties["flexDirection"];
  iconParkSoliddownOneGap?: CSSProperties["gap"];
  iconParkSoliddownOneOpacity?: CSSProperties["opacity"];
  inventoryMemoryFragmentOpacity?: CSSProperties["opacity"];
  inventoryMemoryFragmentBorder?: CSSProperties["border"];
  inventoryMemoryFragmentPadding?: CSSProperties["padding"];
  inventoryMemoryFragmentBackgroundColor?: CSSProperties["backgroundColor"];
  inventoryMemoryFragmentBorderRadius?: CSSProperties["borderRadius"];
  inventoryMemoryFragmentFlexDirection?: CSSProperties["flexDirection"];
  inventoryMemoryFragmentGap?: CSSProperties["gap"];
  inventoryMemoryFragmentWidth?: CSSProperties["width"];
  inventoryMemoryFragmentPosition?: CSSProperties["position"];
  inventoryMemoryFragmentHeight?: CSSProperties["height"];
  inventoryMemoryFragmentOverflow?: CSSProperties["overflow"];
  bxsdashboardIconWidth?: CSSProperties["width"];
  bxsdashboardIconBorderRadius?: CSSProperties["borderRadius"];
  bxsdashboardIconHeight?: CSSProperties["height"];
  bxsdashboardIconOverflow?: CSSProperties["overflow"];
  bxsdashboardIconFontSize?: CSSProperties["fontSize"];
  bxsdashboardIconLetterSpacing?: CSSProperties["letterSpacing"];
  bxsdashboardIconLineHeight?: CSSProperties["lineHeight"];
  bxsdashboardIconFontWeight?: CSSProperties["fontWeight"];
  bxsdashboardIconFontFamily?: CSSProperties["fontFamily"];
  bxsdashboardIconColor?: CSSProperties["color"];
  bxsdashboardIconTextAlign?: CSSProperties["textAlign"];
  bxsdashboardIconDisplay?: CSSProperties["display"];
  memoryFragmentColor?: CSSProperties["color"];
  memoryFragmentWidth?: CSSProperties["width"];
  memoryFragmentFontSize?: CSSProperties["fontSize"];
  memoryFragmentLetterSpacing?: CSSProperties["letterSpacing"];
  memoryFragmentLineHeight?: CSSProperties["lineHeight"];
  memoryFragmentFontWeight?: CSSProperties["fontWeight"];
  memoryFragmentFontFamily?: CSSProperties["fontFamily"];
  memoryFragmentTextAlign?: CSSProperties["textAlign"];
  memoryFragmentDisplay?: CSSProperties["display"];
  memoryFragmentHeight?: CSSProperties["height"];
  memoryFragmentOverflow?: CSSProperties["overflow"];
  inventoryCostumesOpacity?: CSSProperties["opacity"];
  costumesColor?: CSSProperties["color"];
  inventoryAllItemOpacity?: CSSProperties["opacity"];
  allItemColor?: CSSProperties["color"];
  myNFTOpacity?: CSSProperties["opacity"];
  myNFTAlignSelf?: CSSProperties["alignSelf"];
  myNFTColor?: CSSProperties["color"];
  designCatalogDisplay?: CSSProperties["display"];

  /** Action props */
  onDashboardClick?: () => void;
  onProfileClick?: () => void;
  onInventoryClick?: () => void;
  onMyNFTClick?: () => void;
  onInventoryMemoryFragmentClick?: () => void;
  onInventoryCostumesClick?: () => void;
  onInventoryAllItemClick?: () => void;
  onLogoutClick?: () => void;
  onCatalogClick?: () => void;
  onGitBookClick?: () => void;
  onInventoryPlacedBookmarkClick?: () => void;
};

const SidebarComponent: FunctionComponent<SidebarComponentType> = ({
  ready,
  terraquestLogoBig1,
  bxsdashboard,
  bxsdashboard1,
  bxsdashboard2,
  bxsdashboard3,
  placedBookmark,
  bxsdashboard4,
  memoryFragment,
  bxsdashboard5,
  costumes,
  bxsdashboard6,
  allItem,
  bxsdashboard7,
  logouticon,
  myNFT,
  createACharacterDesign,
  inventoryAllItem,
  inventoryPlacedBookmark,
  showIconParkSoliddownOne,
  inventoryMemoryFragment,
  inventoryCostumes,
  sidebarComponentAlignSelf,
  sidebarComponentHeight,
  dashboardBorderRadius,
  dashboardOpacity,
  dashboardColor,
  logoutColor,
  profileOpacity,
  profileColor,
  inventoryOpacity,
  inventoryMixBlendMode,
  inventoryColor,
  inventoryPlacedBookmarkOpacity,
  inventoryPlacedBookmarkAlignSelf,
  placedBookmarkColor,
  iconParkSoliddownOneWidth,
  iconParkSoliddownOnePosition,
  iconParkSoliddownOneHeight,
  iconParkSoliddownOneOverflow,
  iconParkSoliddownOneBorder,
  iconParkSoliddownOnePadding,
  iconParkSoliddownOneBackgroundColor,
  iconParkSoliddownOneBorderRadius,
  iconParkSoliddownOneFlexDirection,
  iconParkSoliddownOneGap,
  iconParkSoliddownOneOpacity,
  inventoryMemoryFragmentOpacity,
  inventoryMemoryFragmentBorder,
  inventoryMemoryFragmentPadding,
  inventoryMemoryFragmentBackgroundColor,
  inventoryMemoryFragmentBorderRadius,
  inventoryMemoryFragmentFlexDirection,
  inventoryMemoryFragmentGap,
  inventoryMemoryFragmentWidth,
  inventoryMemoryFragmentPosition,
  inventoryMemoryFragmentHeight,
  inventoryMemoryFragmentOverflow,
  bxsdashboardIconWidth,
  bxsdashboardIconBorderRadius,
  bxsdashboardIconHeight,
  bxsdashboardIconOverflow,
  bxsdashboardIconFontSize,
  bxsdashboardIconLetterSpacing,
  bxsdashboardIconLineHeight,
  bxsdashboardIconFontWeight,
  bxsdashboardIconFontFamily,
  bxsdashboardIconColor,
  bxsdashboardIconTextAlign,
  bxsdashboardIconDisplay,
  memoryFragmentColor,
  memoryFragmentWidth,
  memoryFragmentFontSize,
  memoryFragmentLetterSpacing,
  memoryFragmentLineHeight,
  memoryFragmentFontWeight,
  memoryFragmentFontFamily,
  memoryFragmentTextAlign,
  memoryFragmentDisplay,
  memoryFragmentHeight,
  memoryFragmentOverflow,
  inventoryCostumesOpacity,
  costumesColor,
  inventoryAllItemOpacity,
  allItemColor,
  myNFTOpacity,
  myNFTAlignSelf,
  myNFTColor,
  designCatalogDisplay,
  onDashboardClick,
  onProfileClick,
  onInventoryClick,
  onCatalogClick,
  onMyNFTClick,
  onInventoryMemoryFragmentClick,
  onInventoryCostumesClick,
  onGitBookClick,
  onInventoryAllItemClick,
  onLogoutClick,
  onInventoryPlacedBookmarkClick,
  dashboardFontWeight,
  logoutFontWeight,
  catalogFontWeight,
  catalogColor,
  catalogIcon,
  gitbookFontWeight,
  gitbookColor,
  profileFontWeight,
  inventoryFontWeight,
  costumeFontWeight,
  allItemFontWeight,
  myNFTFontWeight,
  placedBookmarkWeight,
}) => {
  const sidebarComponentStyle: CSSProperties = useMemo(() => {
    return {
      alignSelf: sidebarComponentAlignSelf,
      height: sidebarComponentHeight,
    };
  }, [sidebarComponentAlignSelf, sidebarComponentHeight]);

  const navigate = useNavigate();

  const dashboardStyle: CSSProperties = useMemo(() => {
    return {
      borderRadius: dashboardBorderRadius,
      opacity: dashboardOpacity,
    };
  }, [dashboardBorderRadius, dashboardOpacity]);

  const catalogStyle: CSSProperties = useMemo(() => {
    return {
      color: catalogColor,
      fontWeight: catalogFontWeight,
    };
  }, [catalogColor, catalogFontWeight]);
  const gitbookStyle: CSSProperties = useMemo(() => {
    return {
      color: gitbookColor,
      fontWeight: gitbookFontWeight,
    };
  }, [gitbookColor, gitbookFontWeight]);
  const logoutStyle: CSSProperties = useMemo(() => {
    return {
      color: logoutColor,
      fontWeight: logoutFontWeight,
    };
  }, [logoutColor, logoutFontWeight]);

  const dashboard1Style: CSSProperties = useMemo(() => {
    return {
      color: dashboardColor,
      fontWeight: dashboardFontWeight,
    };
  }, [dashboardColor, dashboardFontWeight]);

  const profileStyle: CSSProperties = useMemo(() => {
    return {
      opacity: profileOpacity,
    };
  }, [profileOpacity]);

  const profile1Style: CSSProperties = useMemo(() => {
    return {
      color: profileColor,
      fontWeight: profileFontWeight,
    };
  }, [profileColor, profileFontWeight]);

  const inventoryStyle: CSSProperties = useMemo(() => {
    return {
      opacity: inventoryOpacity,
      mixBlendMode: inventoryMixBlendMode,
    };
  }, [inventoryOpacity, inventoryMixBlendMode]);

  const inventory1Style: CSSProperties = useMemo(() => {
    return {
      color: inventoryColor,
      fontWeight: inventoryFontWeight,
    };
  }, [inventoryColor, inventoryFontWeight]);

  const onLogout = useCallback(() => {
    navigate("/logout");
  }, []);

  // Function to handle the click event of the initial button
  const handleToggleClick = () => {
    // Update the isToggle state to its opposite value
    setIsToggle(!isToggle);
  };

  const inventoryPlacedBookmarkStyle: CSSProperties = useMemo(() => {
    return {
      opacity: inventoryPlacedBookmarkOpacity,
      alignSelf: inventoryPlacedBookmarkAlignSelf,
    };
  }, [inventoryPlacedBookmarkOpacity, inventoryPlacedBookmarkAlignSelf]);

  const placedBookmarkStyle: CSSProperties = useMemo(() => {
    return {
      color: placedBookmarkColor,
      fontWeight: placedBookmarkWeight,
    };
  }, [placedBookmarkColor, placedBookmarkWeight]);

  const iconParkSoliddownOneStyle: CSSProperties = useMemo(() => {
    return {
      width: iconParkSoliddownOneWidth,
      position: iconParkSoliddownOnePosition,
      height: iconParkSoliddownOneHeight,
      overflow: iconParkSoliddownOneOverflow,
      border: iconParkSoliddownOneBorder,
      padding: iconParkSoliddownOnePadding,
      backgroundColor: iconParkSoliddownOneBackgroundColor,
      borderRadius: iconParkSoliddownOneBorderRadius,
      flexDirection: iconParkSoliddownOneFlexDirection,
      gap: iconParkSoliddownOneGap,
      opacity: iconParkSoliddownOneOpacity,
    };
  }, [
    iconParkSoliddownOneWidth,
    iconParkSoliddownOnePosition,
    iconParkSoliddownOneHeight,
    iconParkSoliddownOneOverflow,
    iconParkSoliddownOneBorder,
    iconParkSoliddownOnePadding,
    iconParkSoliddownOneBackgroundColor,
    iconParkSoliddownOneBorderRadius,
    iconParkSoliddownOneFlexDirection,
    iconParkSoliddownOneGap,
    iconParkSoliddownOneOpacity,
  ]);

  const inventoryMemoryFragmentStyle: CSSProperties = useMemo(() => {
    return {
      opacity: inventoryMemoryFragmentOpacity,
      border: inventoryMemoryFragmentBorder,
      padding: inventoryMemoryFragmentPadding,
      backgroundColor: inventoryMemoryFragmentBackgroundColor,
      borderRadius: inventoryMemoryFragmentBorderRadius,
      flexDirection: inventoryMemoryFragmentFlexDirection,
      gap: inventoryMemoryFragmentGap,
      width: inventoryMemoryFragmentWidth,
      position: inventoryMemoryFragmentPosition,
      height: inventoryMemoryFragmentHeight,
      overflow: inventoryMemoryFragmentOverflow,
    };
  }, [
    inventoryMemoryFragmentOpacity,
    inventoryMemoryFragmentBorder,
    inventoryMemoryFragmentPadding,
    inventoryMemoryFragmentBackgroundColor,
    inventoryMemoryFragmentBorderRadius,
    inventoryMemoryFragmentFlexDirection,
    inventoryMemoryFragmentGap,
    inventoryMemoryFragmentWidth,
    inventoryMemoryFragmentPosition,
    inventoryMemoryFragmentHeight,
    inventoryMemoryFragmentOverflow,
  ]);

  const bxsdashboardIconStyle: CSSProperties = useMemo(() => {
    return {
      width: bxsdashboardIconWidth,
      borderRadius: bxsdashboardIconBorderRadius,
      height: bxsdashboardIconHeight,
      overflow: bxsdashboardIconOverflow,
      fontSize: bxsdashboardIconFontSize,
      letterSpacing: bxsdashboardIconLetterSpacing,
      lineHeight: bxsdashboardIconLineHeight,
      fontWeight: bxsdashboardIconFontWeight,
      fontFamily: bxsdashboardIconFontFamily,
      color: bxsdashboardIconColor,
      textAlign: bxsdashboardIconTextAlign,
      display: bxsdashboardIconDisplay,
    };
  }, [
    bxsdashboardIconWidth,
    bxsdashboardIconBorderRadius,
    bxsdashboardIconHeight,
    bxsdashboardIconOverflow,
    bxsdashboardIconFontSize,
    bxsdashboardIconLetterSpacing,
    bxsdashboardIconLineHeight,
    bxsdashboardIconFontWeight,
    bxsdashboardIconFontFamily,
    bxsdashboardIconColor,
    bxsdashboardIconTextAlign,
    bxsdashboardIconDisplay,
  ]);

  const memoryFragmentStyle: CSSProperties = useMemo(() => {
    return {
      color: memoryFragmentColor,
      width: memoryFragmentWidth,
      fontSize: memoryFragmentFontSize,
      letterSpacing: memoryFragmentLetterSpacing,
      lineHeight: memoryFragmentLineHeight,
      fontWeight: memoryFragmentFontWeight,
      fontFamily: memoryFragmentFontFamily,
      textAlign: memoryFragmentTextAlign,
      display: memoryFragmentDisplay,
      height: memoryFragmentHeight,
      overflow: memoryFragmentOverflow,
    };
  }, [
    memoryFragmentColor,
    memoryFragmentWidth,
    memoryFragmentFontSize,
    memoryFragmentLetterSpacing,
    memoryFragmentLineHeight,
    memoryFragmentFontWeight,
    memoryFragmentFontFamily,
    memoryFragmentTextAlign,
    memoryFragmentDisplay,
    memoryFragmentHeight,
    memoryFragmentOverflow,
  ]);

  const inventoryCostumesStyle: CSSProperties = useMemo(() => {
    return {
      opacity: inventoryCostumesOpacity,
    };
  }, [inventoryCostumesOpacity]);

  const costumesStyle: CSSProperties = useMemo(() => {
    return {
      color: costumesColor,
      fontWeight: costumeFontWeight,
    };
  }, [costumesColor, costumeFontWeight]);

  const inventoryAllItemStyle: CSSProperties = useMemo(() => {
    return {
      opacity: inventoryAllItemOpacity,
    };
  }, [inventoryAllItemOpacity]);

  const allItemStyle: CSSProperties = useMemo(() => {
    return {
      color: allItemColor,
      fontWeight: allItemFontWeight,
    };
  }, [allItemColor, allItemFontWeight]);

  const myNFTStyle: CSSProperties = useMemo(() => {
    return {
      opacity: myNFTOpacity,
      alignSelf: myNFTAlignSelf,
    };
  }, [myNFTOpacity, myNFTAlignSelf]);

  const myNFT1Style: CSSProperties = useMemo(() => {
    return {
      color: myNFTColor,
      fontWeight: myNFTFontWeight,
    };
  }, [myNFTColor, myNFTFontWeight]);

  const designCatalogStyle: CSSProperties = useMemo(() => {
    return {
      display: designCatalogDisplay,
    };
  }, [designCatalogDisplay]);

  const isSessionAvailable = () => {
    const session = localStorage.getItem("session");
    return !!session;
  };

  const [isToggle, setIsToggle] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    // Check if the current location matches "/login"
    if (
      location.pathname === "/bookmarks" ||
      location.pathname === "/memory-fragment" ||
      location.pathname === "/attires" ||
      location.pathname === "/inventory"
    ) {
      setIsToggle(true); // Set isToggle to true if route matches "/login"
    } else {
      setIsToggle(false); // Reset isToggle to false if route doesn't match "/login"
    }
  }, [location.pathname]); // Run this effect whenever the pathname changes

  return (
    <nav
      className=" sticky top-0 sidebar flex m-0 w-60 h-screen rounded-tl-none rounded-tr-2xl rounded-br-2xl rounded-bl-none bg-colors-white-100  flex-col items-start justify-between p-4 box-border"
      style={sidebarComponentStyle}
    >
      <div className="flex flex-col items-start justify-start gap-[8px]">
        <img
          className={`w-52 relative h-24 object-cover`}
          alt=""
          src="/logo208x96-1@2x.png"
        />

        <div className="w-52 h-24 hidden flex-col items-center justify-center">
          <img
            className="w-[133.1px] relative h-20 object-cover lg:w-[199px]"
            alt=""
            src={terraquestLogoBig1}
          />
        </div>
        <img
          className="self-stretch relative max-w-full overflow-hidden max-h-full "
          alt=""
          src="/sparator.svg"
        />
        <div className="flex flex-col items-start justify-start p-2 gap-[16px] lg:gap-[8px] !w-[96%]">
          <button
            className={`
            cursor-pointer [border:none] py-1 px-4 bg-[transparent] self-stretch rounded-lg flex flex-row items-center justify-start gap-[8px] 
             group`}
            style={dashboardStyle}
            onClick={onDashboardClick}
          >
            <div
              className={`
              ${
                location.pathname !== "/dashboard" &&
                "group-hover:bg-[#593F3F] group-hover:rounded-[4px]"
              }`}
            >
              <img
                className={`w-6 relative rounded h-6 overflow-hidden shrink-0
                ${
                  location.pathname !== "/dashboard" &&
                  "group-hover:brightness-[1100]"
                }
                `}
                // hover:filter invert
                alt=""
                src={bxsdashboard}
              />
            </div>

            <div
              className={`w-[104px] relative text-xs tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-darkslategray-100 text-left inline-block shrink-0 lg:text-xs
              ${
                location.pathname !== "/dashboard" &&
                "group-hover:font-extrabold"
              }`}
              style={dashboard1Style}
            >
              Dashboard
            </div>
          </button>
          <button
            className={`cursor-pointer [border:none] py-1 px-4 bg-[transparent] self-stretch rounded-lg flex flex-row items-center justify-start gap-[8px]  lg:pt-0.5 lg:pb-0.5 lg:box-border
             group`}
            onClick={onProfileClick}
          >
            <div
              className={`
              ${
                location.pathname !== "/profile" &&
                "group-hover:bg-[#593F3F] group-hover:rounded-[4px]"
              }`}
            >
              <img
                className={`w-6 relative rounded h-6 overflow-hidden shrink-0
              ${
                location.pathname !== "/profile" &&
                "group-hover:brightness-[1100]"
              }`}
                alt=""
                src={bxsdashboard1}
              />
            </div>
            <div
              className={`w-[104px] relative text-xs tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-darkslategray-100 text-left inline-block shrink-0 lg:text-xs
              ${
                location.pathname !== "/profile" && "group-hover:font-extrabold"
              }`}
              style={profile1Style}
            >
              Profile
            </div>
          </button>
          <button
            className={`cursor-pointer [border:none] py-1 px-4 bg-[transparent] rounded-lg flex flex-row items-center justify-start gap-[8px]  lg:pt-0.5 lg:pb-0.5 lg:box-border
            `}
            onClick={handleToggleClick}
          >
            <img
              className="w-6 relative rounded h-6 overflow-hidden shrink-0"
              alt=""
              src={bxsdashboard2}
            />
            <div
              className="w-[104px] relative text-xs tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-darkslategray-100 text-left inline-block shrink-0 lg:text-xs"
              style={inventory1Style}
            >
              Inventory
            </div>
            <img
              className="w-4 relative h-4 overflow-hidden shrink-0 object-cover"
              alt=""
              src="/iconparksoliddownone@2x.png"
            />
          </button>
          {isToggle && (
            <>
              {/* <button
                className={`cursor-pointer [border:none] py-1 px-4 bg-[transparent] flex rounded-lg  flex-row items-center justify-start gap-[8px] 
                group
                `}
                onClick={onInventoryPlacedBookmarkClick}
              >
                <div
                  className={`
              ${
                location.pathname !== "/bookmarks" &&
                "group-hover:bg-[#593F3F] group-hover:rounded-[4px]"
              }`}
                >
                  <img
                    className={`w-6 relative rounded h-6 overflow-hidden shrink-0
                  ${
                    location.pathname !== "/bookmarks" &&
                    "group-hover:brightness-[1100]"
                  }`}
                    alt=""
                    src={bxsdashboard3}
                  />
                </div>
                <div
                  className={`w-[104px] relative text-xs tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-darkslategray-100 text-left inline-block shrink-0 lg:text-xs
                  ${
                    location.pathname !== "/bookmarks" &&
                    "group-hover:font-extrabold"
                  }`}
                  style={placedBookmarkStyle}
                >
                  {placedBookmark}
                </div>
                {showIconParkSoliddownOne && (
                  <div
                    className="w-4 relative h-4 overflow-hidden shrink-0"
                    style={iconParkSoliddownOneStyle}
                  />
                )}
              </button>

              <button
                className={`group cursor-pointer [border:none] py-1 px-4 bg-[transparent] flex rounded-lg  flex-row items-center justify-start gap-[8px] 
`}
                onClick={onInventoryMemoryFragmentClick}
              >
                <div
                  className={`
              ${
                location.pathname !== "/memory-fragment" &&
                "group-hover:bg-[#593F3F] group-hover:rounded-[4px]"
              }`}
                >
                  <img
                    className={`w-6 relative rounded h-6 overflow-hidden shrink-0
                    ${
                      location.pathname !== "/memory-fragment" &&
                      "group-hover:brightness-[1100]"
                    }`}
                    alt=""
                    src={bxsdashboard4}
                    style={bxsdashboardIconStyle}
                  />
                </div>
                <div
                  className={`w-[104px] relative text-xs tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-darkslategray-100 text-left inline-block shrink-0 lg:text-xs
                  ${
                    location.pathname !== "/memory-fragment" &&
                    "group-hover:!font-extrabold"
                  }`}
                  style={memoryFragmentStyle}
                >
                  {memoryFragment}
                </div>
              </button> */}
              <button
                className={`group cursor-pointer [border:none] py-1 px-4 bg-[transparent] flex rounded-lg  flex-row items-center justify-start gap-[8px] 
`}
                onClick={onInventoryCostumesClick}
              >
                <div
                  className={`
              ${
                location.pathname !== "/attires" &&
                "group-hover:bg-[#593F3F] group-hover:rounded-[4px]"
              }`}
                >
                  <img
                    className={`w-6 relative rounded h-6 overflow-hidden shrink-0
                    ${
                      location.pathname !== "/attires" &&
                      "group-hover:brightness-[1100]"
                    }`}
                    alt=""
                    src={bxsdashboard5}
                  />
                </div>
                <div
                  className={`w-[104px] relative text-xs tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-darkslategray-100 text-left inline-block shrink-0 lg:text-xs
                  ${
                    location.pathname !== "/attires" &&
                    "group-hover:font-extrabold"
                  }`}
                  style={costumesStyle}
                >
                  {costumes}
                </div>
              </button>
              <button
                className={`group cursor-pointer [border:none] py-1 px-4 bg-[transparent] flex rounded-lg  flex-row items-center justify-start gap-[8px] 
`}
                onClick={onInventoryAllItemClick}
              >
                <div
                  className={`
              ${
                location.pathname !== "/inventory" &&
                "group-hover:bg-[#593F3F] group-hover:rounded-[4px]"
              }`}
                >
                  <img
                    className={`w-6 relative rounded h-6 overflow-hidden shrink-0
                  ${
                    location.pathname !== "/inventory" &&
                    "group-hover:brightness-[1100]"
                  }`}
                    alt=""
                    src={bxsdashboard6}
                  />
                </div>
                <div
                  className={`w-[104px] relative text-xs tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-darkslategray-100 text-left inline-block shrink-0 lg:text-xs
                  ${
                    location.pathname !== "/inventory" &&
                    "group-hover:font-extrabold"
                  }`}
                  style={allItemStyle}
                >
                  {allItem}
                </div>
              </button>
            </>
          )}

          <button
            className={`group cursor-pointer [border:none] py-1 px-4 bg-[transparent] self-stretch rounded-lg flex flex-row items-center justify-start gap-[8px]  lg:pt-0.5 lg:pb-0.5 lg:box-border
            `}
            onClick={onMyNFTClick}
          >
            <div
              className={`
              ${
                location.pathname !== "/mynfts" &&
                "group-hover:bg-[#593F3F] group-hover:rounded-[4px]"
              }`}
            >
              <img
                className={`w-6 relative rounded h-6 overflow-hidden shrink-0
                ${
                  location.pathname !== "/mynfts" &&
                  "group-hover:brightness-[1100]"
                }`}
                alt=""
                src={bxsdashboard7}
              />
            </div>
            <div
              className={`w-[104px] relative text-xs tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-darkslategray-100 text-left inline-block shrink-0 lg:text-xs
              ${
                location.pathname !== "/mynfts" && "group-hover:font-extrabold"
              }`}
              style={myNFT1Style}
            >
              {myNFT}
            </div>
          </button>
        </div>
      </div>

      <div className="self-stretch flex flex-col items-start justify-start p-2 gap-[8px] lg:gap-[8px]">
        <button
          className={`group cursor-pointer [border:none] py-1 px-4 bg-[transparent] self-stretch rounded-lg flex flex-row items-center justify-start gap-[8px]  lg:pt-0.5 lg:pb-0.5 lg:box-border
`}
          onClick={onCatalogClick}
        >
          <div
            className={`
              ${
                location.pathname !== "/catalog" &&
                "group-hover:bg-[#593F3F] group-hover:rounded-[4px]"
              }`}
          >
            <img
              className={`w-6 relative rounded h-6 overflow-hidden shrink-0
                ${
                  location.pathname !== "/catalog" &&
                  "group-hover:brightness-[1100]"
                }`}
              alt=""
              src={catalogIcon}
            />
          </div>

          <div
            className={`w-[104px] relative text-xs tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-dimgray-200 text-left inline-block shrink-0 lg:text-xs
              ${
                location.pathname !== "/catalog" && "group-hover:font-extrabold"
              }`}
            style={catalogStyle}
          >
            Catalog
          </div>
        </button>

        {/* GitBook */}
        <Link
          className={`group cursor-pointer [border:none] py-1 px-4 bg-[transparent] self-stretch rounded-lg flex flex-row items-center justify-start gap-[8px]  lg:pt-0.5 lg:pb-0.5 lg:box-border
          
`}
          to="https://docs.terraquest.xyz/terraquest-litepaper/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            className={`
              ${
                location.pathname !== "/gitbook" &&
                "group-hover:bg-[#593F3F] group-hover:rounded-[4px]"
              }`}
          >
            <img
              className={`w-6 relative rounded h-6 overflow-hidden shrink-0
                ${
                  location.pathname !== "/gitbook" &&
                  "group-hover:brightness-[1100]"
                }`}
              alt=""
              src="/gitbook.svg"
            />
          </div>

          <div
            className={`
            !text-[#593F3F]
            w-[104px] relative text-xs tracking-[0.02em] leading-[100%] font-medium font-dm-sans  text-left inline-block shrink-0 lg:text-xs
              ${
                location.pathname !== "/catalog" && "group-hover:font-extrabold"
              }`}
            style={gitbookStyle}
          >
            Gitbook
          </div>
        </Link>
        {/* end Gitbook */}
        {/* GitBook */}
        <Link
          className={`group cursor-pointer [border:none] py-1 px-4 bg-[transparent] self-stretch rounded-lg flex flex-row items-center justify-start gap-[8px]  lg:pt-0.5 lg:pb-0.5 lg:box-border
`}
          to="https://blog.terraquest.xyz/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            className={`
              ${
                location.pathname !== "/gitbook" &&
                "group-hover:bg-[#593F3F] group-hover:rounded-[4px]"
              }`}
          >
            <img
              className={`w-6 relative rounded h-6 overflow-hidden shrink-0
                ${
                  location.pathname !== "/gitbook" &&
                  "group-hover:brightness-[1100]"
                }`}
              alt=""
              src="/blog.svg"
            />
          </div>

          <div
            className={`
            !text-[#593F3F]
            w-[104px] relative text-xs tracking-[0.02em] leading-[100%] font-medium font-dm-sans  text-left inline-block shrink-0 lg:text-xs
              ${
                location.pathname !== "/catalog" && "group-hover:font-extrabold"
              }`}
            style={gitbookStyle}
          >
            Blog
          </div>
        </Link>
        {/* end Gitbook */}

        {isSessionAvailable() && (
          <button
            className={`group cursor-pointer [border:none] py-1 px-4 bg-[transparent] self-stretch rounded-lg flex flex-row items-center justify-start gap-[8px]  lg:pt-0.5 lg:pb-0.5 lg:box-border
`}
            onClick={onLogout}
          >
            <div
              className={`
              ${
                location.pathname !== "/logout" &&
                "group-hover:bg-[#593F3F] group-hover:rounded-[4px]"
              }`}
            >
              <img
                className={`w-6 relative rounded h-6 overflow-hidden shrink-0
                ${
                  location.pathname !== "/logout" &&
                  "group-hover:brightness-[1100]"
                }`}
                alt=""
                src={logouticon}
              />
            </div>

            <div
              className={`
              !text-[#593F3F]
              w-[104px] relative text-xs tracking-[0.02em] leading-[100%] font-medium font-dm-sans  text-left inline-block shrink-0 lg:text-xs
              ${
                location.pathname !== "/logout" && "group-hover:font-extrabold"
              }`}
              style={logoutStyle}
            >
              Logout
            </div>
          </button>
        )}
      </div>
    </nav>
  );
};

export default SidebarComponent;
