// import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Client } from "@heroiclabs/nakama-js";

type ApiAccountCustom = {
  id: string;
  // Add other properties if there are any
};
export default function FacebookAuth(props: any) {
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [isEmail, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingData, setLoadingData] = useState(false);
  const navigate = useNavigate();

  // Function to initialize Facebook
  const initializeFacebook = () => {
    FB.init({
      appId: "877488580546968",
      xfbml: true,
      version: "v18.0",
    });

    FB.login(
      function (response) {
        if (response.authResponse) {
          FB.api("/me?fields=name,email", function (response: any) {
            setEmail(response.email);
          });

          FB.getLoginStatus(function (response) {
            if (response.status === "connected") {
              const facebooktoken = response.authResponse.accessToken;

              if (facebooktoken == undefined) {
                FB.login();
              } else {
                nakamaFacebook(facebooktoken, isEmail);
              }
            }
          });
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      { scope: "email" }
    );
  };

  function onSignout() {
    setisLoggedIn(false);
  }

  const notify = (message: any, types: any) =>
    toast(<p style={{ fontSize: 16 }}>{message}</p>, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      rtl: false,
      pauseOnFocusLoss: true,
      draggable: true,
      pauseOnHover: true,
      type: types,
    });
  function nakamaFacebook(token: string, emailData: any) {
    const currentRoute = window.location.pathname;

    const useSSL = window.location.protocol === "https:";
    const client = new Client(
      useSSL ? process.env.REACT_APP_CLIENT_KEY! : process.env.REACT_APP_DEV_CLIENT_KEY!,
      useSSL ? process.env.REACT_APP_API_HOST! : process.env.REACT_APP_DEV_API_HOST!,
      useSSL ? process.env.REACT_APP_API_PORT! : process.env.REACT_APP_DEV_API_PORT!,
      useSSL
    );

    client
      .authenticateFacebook(token)
      .then(async (session) => {

        // if test in staging, comment this line below
        const useSSL = true;
        let socket = client.createSocket(useSSL);
        const appearOnline = true;

        await socket
          .connect(session, appearOnline)
          .then(async (res) => {
            const acc = client.getAccount(session);

            const emailCredentials = {
              email: emailData,
              password: "password", // set to default
            };

            const linkEmail = await client.linkEmail(session, emailCredentials);

            const result = await client.rpc(
              session,
              "UpdateMetadata",
              JSON.parse(`{"metadata": {"facebookToken": "${token}"}}`)
            );

            setLoadingData(true);
            toast(<p style={{ fontSize: 16 }}>Logging you in. 🚀🚀🚀.</p>, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              rtl: false,
              pauseOnFocusLoss: true,
              draggable: true,
              pauseOnHover: true,
              type: "info",
            });

            // Conditionally navigate based on the current route
            if (currentRoute === "/register") {
              alert("test login facebook");
              setTimeout(() => {
                setLoadingData(false);
                const errorMessage = "Logged In! Please Wait...";
                setErrorMessage(errorMessage);
                notify(errorMessage, "success");
                setTimeout(() => {
                  setErrorMessage("");
                  localStorage.setItem("session", JSON.stringify(session));
                  navigate("/profile");
                }, 2000);
              }, 5650);
            } else {
              alert("test login facebook");
              setTimeout(() => {
                setLoadingData(false);
                const errorMessage = "Logged In! Please Wait...";
                setErrorMessage(errorMessage);
                notify(errorMessage, "success");
                setTimeout(() => {
                  setErrorMessage("");
                  localStorage.setItem("session", JSON.stringify(session));
                  window.location.reload();
                  navigate("/");
                }, 5300);
              }, 5650);
            }
          })
          .catch((error) => {
            return error;
          });

        localStorage.setItem("session", JSON.stringify(session));

        if (session?.username) {
          let test: ApiAccountCustom = {
            id: session?.username,
          };
          await client.linkCustom(session, test);
        }

        navigate("/");
        location.reload();
      })
      .catch((error) => {
        console.error("Authentication error:", error.message);
      });
  }

  return (
    <>
      {isLoggedIn ? (
        <button onClick={onSignout} className="cursor-pointer">
          Sign Out
        </button>
      ) : (
        <button
          onClick={initializeFacebook}
          className="bg-transparent facebooks"
        >
          <img
            className="relative w-8 h-8 overflow-hidden shrink-0 p-1"
            alt=""
            src="/facebook--original.svg"
          />
        </button>
      )}
      <ToastContainer />
    </>
  );
}
