import { useState } from "react";
import ReactHtmlParser from "react-html-parser";
const SideBar = (props: any) => {
  const sidebarClass = props?.toggleTutorial ? "tutorial open" : "tutorial";
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index: any) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  function toggleInfo() {
    props?.setToggleTutorial(!props.toggleTutorial);
  }
  return (
    <>
      {!props.toggleTutorial && (
        <button
          className={`hidden sm:block fixed align-middle select-none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-10 max-w-[40px] h-10 max-h-[40px] rounded-tl-full rounded-tr-full text-xs bg-gray-900  shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none
        bg-[#1E6DD5] 
        p-7
       z-[9]
        
        ${props.toggleTutorial ? "" : "bottom-20  "}


        `}
          onClick={toggleInfo}
          type="button"
        >
          <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            {!props.toggleTutorial ? (
              <>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.666 10.8432C10.666 10.5468 10.8065 10.2625 11.0565 10.0529C11.3066 9.84328 11.6457 9.72552 11.9993 9.72552C12.353 9.72552 12.6921 9.84328 12.9422 10.0529C13.1922 10.2625 13.3327 10.5468 13.3327 10.8432V17.5496C13.3327 17.846 13.1922 18.1303 12.9422 18.3399C12.6921 18.5496 12.353 18.6673 11.9993 18.6673C11.6457 18.6673 11.3066 18.5496 11.0565 18.3399C10.8065 18.1303 10.666 17.846 10.666 17.5496V10.8432ZM11.9993 5.33398C11.6457 5.33398 11.3066 5.45174 11.0565 5.66136C10.8065 5.87097 10.666 6.15527 10.666 6.45171C10.666 6.74815 10.8065 7.03245 11.0565 7.24206C11.3066 7.45167 11.6457 7.56943 11.9993 7.56943C12.353 7.56943 12.6921 7.45167 12.9422 7.24206C13.1922 7.03245 13.3327 6.74815 13.3327 6.45171C13.3327 6.15527 13.1922 5.87097 12.9422 5.66136C12.6921 5.45174 12.353 5.33398 11.9993 5.33398Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 0C5.3724 0 0 5.3724 0 12C0 18.6276 5.3724 24 12 24C18.6276 24 24 18.6276 24 12C24 5.3724 18.6276 0 12 0ZM2.4 12C2.4 14.5461 3.41143 16.9879 5.21178 18.7882C7.01212 20.5886 9.45392 21.6 12 21.6C14.5461 21.6 16.9879 20.5886 18.7882 18.7882C20.5886 16.9879 21.6 14.5461 21.6 12C21.6 9.45392 20.5886 7.01212 18.7882 5.21178C16.9879 3.41143 14.5461 2.4 12 2.4C9.45392 2.4 7.01212 3.41143 5.21178 5.21178C3.41143 7.01212 2.4 9.45392 2.4 12Z"
                    fill="white"
                  />
                </svg>
              </>
            ) : (
              <>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.0612 23.938C26.343 24.2198 26.5013 24.602 26.5013 25.0005C26.5013 25.399 26.343 25.7812 26.0612 26.063C25.7794 26.3448 25.3972 26.5031 24.9987 26.5031C24.6002 26.5031 24.218 26.3448 23.9362 26.063L15.9999 18.1242L8.0612 26.0605C7.7794 26.3423 7.39721 26.5006 6.9987 26.5006C6.60018 26.5006 6.21799 26.3423 5.9362 26.0605C5.6544 25.7787 5.49609 25.3965 5.49609 24.998C5.49609 24.5995 5.6544 24.2173 5.9362 23.9355L13.8749 15.9992L5.9387 8.06049C5.6569 7.7787 5.49859 7.39651 5.49859 6.99799C5.49859 6.59948 5.6569 6.21729 5.9387 5.93549C6.22049 5.6537 6.60268 5.49539 7.0012 5.49539C7.39971 5.49539 7.7819 5.6537 8.0637 5.93549L15.9999 13.8742L23.9387 5.93424C24.2205 5.65245 24.6027 5.49414 25.0012 5.49414C25.3997 5.49414 25.7819 5.65245 26.0637 5.93424C26.3455 6.21603 26.5038 6.59823 26.5038 6.99674C26.5038 7.39526 26.3455 7.77745 26.0637 8.05924L18.1249 15.9992L26.0612 23.938Z"
                    fill="white"
                  />
                </svg>
              </>
            )}
          </span>
        </button>
      )}

      <button
        className={` sm:hidden block  fixed align-middle select-none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-10 max-w-[40px] h-10 max-h-[40px] rounded-full text-xs bg-gray-900  shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none
        bg-[#1E6DD5] bottom-10 !z-10  p-7
        ${props.toggleTutorial ? "right-64" : "right-10"}


        `}
        onClick={toggleInfo}
        type="button"
      >
        <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
          {!props.toggleTutorial ? (
            <>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.666 10.8432C10.666 10.5468 10.8065 10.2625 11.0565 10.0529C11.3066 9.84328 11.6457 9.72552 11.9993 9.72552C12.353 9.72552 12.6921 9.84328 12.9422 10.0529C13.1922 10.2625 13.3327 10.5468 13.3327 10.8432V17.5496C13.3327 17.846 13.1922 18.1303 12.9422 18.3399C12.6921 18.5496 12.353 18.6673 11.9993 18.6673C11.6457 18.6673 11.3066 18.5496 11.0565 18.3399C10.8065 18.1303 10.666 17.846 10.666 17.5496V10.8432ZM11.9993 5.33398C11.6457 5.33398 11.3066 5.45174 11.0565 5.66136C10.8065 5.87097 10.666 6.15527 10.666 6.45171C10.666 6.74815 10.8065 7.03245 11.0565 7.24206C11.3066 7.45167 11.6457 7.56943 11.9993 7.56943C12.353 7.56943 12.6921 7.45167 12.9422 7.24206C13.1922 7.03245 13.3327 6.74815 13.3327 6.45171C13.3327 6.15527 13.1922 5.87097 12.9422 5.66136C12.6921 5.45174 12.353 5.33398 11.9993 5.33398Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 0C5.3724 0 0 5.3724 0 12C0 18.6276 5.3724 24 12 24C18.6276 24 24 18.6276 24 12C24 5.3724 18.6276 0 12 0ZM2.4 12C2.4 14.5461 3.41143 16.9879 5.21178 18.7882C7.01212 20.5886 9.45392 21.6 12 21.6C14.5461 21.6 16.9879 20.5886 18.7882 18.7882C20.5886 16.9879 21.6 14.5461 21.6 12C21.6 9.45392 20.5886 7.01212 18.7882 5.21178C16.9879 3.41143 14.5461 2.4 12 2.4C9.45392 2.4 7.01212 3.41143 5.21178 5.21178C3.41143 7.01212 2.4 9.45392 2.4 12Z"
                  fill="white"
                />
              </svg>
            </>
          ) : (
            <>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.0612 23.938C26.343 24.2198 26.5013 24.602 26.5013 25.0005C26.5013 25.399 26.343 25.7812 26.0612 26.063C25.7794 26.3448 25.3972 26.5031 24.9987 26.5031C24.6002 26.5031 24.218 26.3448 23.9362 26.063L15.9999 18.1242L8.0612 26.0605C7.7794 26.3423 7.39721 26.5006 6.9987 26.5006C6.60018 26.5006 6.21799 26.3423 5.9362 26.0605C5.6544 25.7787 5.49609 25.3965 5.49609 24.998C5.49609 24.5995 5.6544 24.2173 5.9362 23.9355L13.8749 15.9992L5.9387 8.06049C5.6569 7.7787 5.49859 7.39651 5.49859 6.99799C5.49859 6.59948 5.6569 6.21729 5.9387 5.93549C6.22049 5.6537 6.60268 5.49539 7.0012 5.49539C7.39971 5.49539 7.7819 5.6537 8.0637 5.93549L15.9999 13.8742L23.9387 5.93424C24.2205 5.65245 24.6027 5.49414 25.0012 5.49414C25.3997 5.49414 25.7819 5.65245 26.0637 5.93424C26.3455 6.21603 26.5038 6.59823 26.5038 6.99674C26.5038 7.39526 26.3455 7.77745 26.0637 8.05924L18.1249 15.9992L26.0612 23.938Z"
                  fill="white"
                />
              </svg>
            </>
          )}
        </span>
      </button>
      <div
        className={`${sidebarClass} z-20 fixed top-0 sm:-bottom-[20vw] sm:right-auto lg:-right-[20vw] h-full w-[240px] bg-[#1E6DD5] hidden`}
      >
        <div className="numberCircle" onClick={toggleInfo}>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.0612 23.938C26.343 24.2198 26.5013 24.602 26.5013 25.0005C26.5013 25.399 26.343 25.7812 26.0612 26.063C25.7794 26.3448 25.3972 26.5031 24.9987 26.5031C24.6002 26.5031 24.218 26.3448 23.9362 26.063L15.9999 18.1242L8.0612 26.0605C7.7794 26.3423 7.39721 26.5006 6.9987 26.5006C6.60018 26.5006 6.21799 26.3423 5.9362 26.0605C5.6544 25.7787 5.49609 25.3965 5.49609 24.998C5.49609 24.5995 5.6544 24.2173 5.9362 23.9355L13.8749 15.9992L5.9387 8.06049C5.6569 7.7787 5.49859 7.39651 5.49859 6.99799C5.49859 6.59948 5.6569 6.21729 5.9387 5.93549C6.22049 5.6537 6.60268 5.49539 7.0012 5.49539C7.39971 5.49539 7.7819 5.6537 8.0637 5.93549L15.9999 13.8742L23.9387 5.93424C24.2205 5.65245 24.6027 5.49414 25.0012 5.49414C25.3997 5.49414 25.7819 5.65245 26.0637 5.93424C26.3455 6.21603 26.5038 6.59823 26.5038 6.99674C26.5038 7.39526 26.3455 7.77745 26.0637 8.05924L18.1249 15.9992L26.0612 23.938Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="p-5">
          {props?.items.map((item: any, index: any) => (
            <div key={index} className="">
              <button
                className="w-full py-2 text-left text-white font-medium bg-gray-900 hover:bg-gray-800 focus:outline-none
                border-b border-ColorWhite"
                onClick={() => handleToggle(index)}
              >
                {/* {item.title} */}
                <div className="flex flex-row">
                  <div className="group relative">
                    <img
                      className="w-[30px] relative h-[30px] overflow-hidden shrink-0 sm:w-6 sm:h-6 filter brightness-0 invert pr-1"
                      alt=""
                      src={item?.icon}
                    />
                  </div>
                  <h1
                    className={`flex m-0 h-[17px] relative text-inherit leading-[120%]  font-medium font-inherit  text-base text-sm
              my-auto
                        `}
                    id="Dashboard"
                  >
                    {item?.title}
                  </h1>
                </div>
              </button>
              {openIndex === index && (
                <div className="px-4 bg-gray-800 py-3">
                  <span
                    //   className="text-white text-[13px] text-left font-normal
                    //  font-dm-sans"
                    className={` m-0  relative text-inherit  font-medium font-inherit  text-white text-[12px]
              my-auto
                        `}
                  >
                    {ReactHtmlParser(item.content)}
                  </span>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default SideBar;
