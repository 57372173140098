import { FunctionComponent } from "react";
import { Link, useNavigate } from "react-router-dom";

type FooterType = {
  buttonText?: string;
  actionButtonText?: string;
  ready?: boolean;
  link?: any;
  submit?: any;
};

const Footer: FunctionComponent<FooterType> = ({
  buttonText,
  actionButtonText,
  ready,
  link,
  submit,
}) => {
  const history = useNavigate();

  return (
    <div className="self-stretch flex flex-col items-center justify-start gap-[24px] text-left text-xs text-dimgray-200 font-dm-sans sm:items-center sm:justify-start">
      <button
        className={`
        text-white
        cursor-pointer [border:none] py-0 px-16 bg-dimgray-200 self-stretch rounded-lg h-8 flex flex-row items-center justify-center box-border sm:self-stretch sm:w-auto sm:h-8 sm:rounded-lg sm:pt-0 sm:box-border`}
        onClick={submit}
      >
        <b
          className={`relative text-sm tracking-[0.02em] leading-[100%] font-dm-sans  text-center sm:text-xs sm:flex-1 mq350small:text-3xs
        
        `}
        >
          {buttonText}
        </b>
      </button>

      {actionButtonText === "Back" ? (
        <button
          onClick={() => history(-1)}
          className={`relative leading-[100%] font-medium mq350small:text-3xs cursor-pointer
          !text-[#593F3F] no-underline
    

    `}
        >
          {actionButtonText}
        </button>
      ) : (
        <Link
          to={link}
          className={`relative leading-[100%] font-medium mq350small:text-3xs cursor-pointer
          !text-[#593F3F] no-underline
      
      `}
        >
          {actionButtonText}
        </Link>
      )}
    </div>
  );
};

export default Footer;
