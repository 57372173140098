import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import InfiniteScroll from "react-infinite-scroll-component";
import ModalPage from "./Modal";

export default function InventoryCard(props: any) {
  const [showModalTopRight, setShowModalTopRight] = useState(false);
  const [items, setItems] = useState<any[]>([]);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const allItems = [
    {
      imgSrc: "/img1.png",
      name: "Costumes #1",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #2",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #3",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #4",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #5",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #6",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #7",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #8",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #9",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #10",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #1",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #2",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #3",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #4",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #5",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #6",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #7",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #8",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #9",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #10",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #1",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #2",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #3",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #4",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #5",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #6",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #7",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #8",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #9",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #10",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #1",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #2",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #3",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #4",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #5",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #6",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #7",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #8",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #9",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #10",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #1",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #2",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #3",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #4",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #5",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #6",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #7",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #8",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #9",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #10",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #1",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #2",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #3",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #4",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #5",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #6",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #7",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #8",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #9",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #10",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #1",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #2",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #3",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #4",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #5",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #6",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #7",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #8",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #9",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #10",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #1",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #2",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #3",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #4",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #5",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #6",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #7",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #8",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #9",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #10",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #1",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #2",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #3",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #4",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #5",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #6",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #7",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #8",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #9",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #10",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #1",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #2",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #3",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #4",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #5",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #6",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #7",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #8",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #9",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #10",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #1",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #2",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #3",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #4",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #5",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #6",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #7",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #8",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #9",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #10",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #1",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #2",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #3",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #4",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #5",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #6",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #7",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #8",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #9",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #10",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #1",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #2",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #3",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #4",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #5",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #6",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #7",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #8",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #9",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #10",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #1",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #2",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #3",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #4",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #5",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #6",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #7",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #8",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #9",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #10",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #1",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #2",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #3",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #4",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #5",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #6",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #7",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #8",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #9",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #10",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #1",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #2",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #3",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #4",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #5",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #6",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #7",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #8",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #9",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #10",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #1",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #2",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #3",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #4",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #5",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #6",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #7",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #8",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #9",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #10",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #1",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #2",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #3",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #4",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #5",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #6",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #7",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #8",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #9",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #10",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #1",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #2",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #3",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #4",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #5",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #6",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #7",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #8",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #9",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #10",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #1",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #2",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #3",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #4",
    },
    {
      imgSrc: "/img1.png",
      name: "Costumes #5",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #6",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #7",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #8",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #9",
    },
    {
      imgSrc: "/img2.png",
      name: "Costumes #10",
    },
  ];

  useEffect(() => {
    fetchItems(); // Fetch initial items

    // Add scroll event listener to window
    window.addEventListener("scroll", handleScroll);

    // Remove scroll event listener on cleanup
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const fetchItems = () => {
    // Simulate API call to fetch initial items
    const initialItems = allItems.slice(0, 13);
    setItems(initialItems);
  };

  const fetchMoreItems = () => {
    // Simulate API call to fetch more items
    const startIndex = items.length;
    const endIndex = startIndex + 13;
    const moreItems = allItems.slice(startIndex, endIndex);
    setItems((prevItems) => [...prevItems, ...moreItems]);
  };

  const handleScroll = () => {
    // Get the reference to the container element
    const container = document.getElementById("scrollableDiv");
    if (!container) return;

    // Check if the user has scrolled to the bottom of the container
    const scrollPosition = container.scrollTop + container.clientHeight;
    const totalHeight = container.scrollHeight;
    const threshold = 50; // Adjust this threshold as needed
    if (totalHeight - scrollPosition < threshold) {
      fetchMoreItems();
    }
  };

  return (
    <div
      id="scrollableDiv"
      className="w-[92%] self-stretch pl-0 rounded-xl bg-colors-white-100 p-4 text-left text-sm text-dimgray-200 font-dm-sans lg:h-auto md:w-full md:[align-self:unset] md:items-center md:justify-start md:p-4 md:box-border sm:w-full sm:[align-self:unset] sm:items-center sm:justify-center sm:p-2 sm:box-border"
      style={{
        paddingLeft: "21px",
        paddingRight: "31px",
        overflowY: "auto",
        height: "80vh", // Set the height to prevent body scroll
      }}
    >
      <InfiniteScroll
        dataLength={items.length}
        next={fetchMoreItems}
        hasMore={items.length < allItems.length}
        loader={<h4>Loading...</h4>}
        scrollableTarget="scrollableDiv"
      >
        <div className="self-stretch rounded-xl py-2 px-4 border-[2px] border-dashed border-whitesmoke-300 md:w-auto md:[align-self:unset] sm:gap-[0px] sm:py-2 sm:px-1 sm:box-border">
          <div className="grid gridBoxesCostume">
            {items.map((product, index) => (
              <div
                className="!z-5 relative border-[#E1DEEE] border-2 border-solid flex flex-col rounded-xl max-w-[180px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 flex flex-col w-full  bg-white undefined"
                key={index}
              >
                <div className="h-full">
                  <div
                    className="relative w-full flex flex-col"
                    onClick={() => setShowModalTopRight(true)}
                  >
                    <img
                      src={product?.imgSrc}
                      className="h-full w-full hover:scale-[1.02] transition duration-500"
                      alt=""
                    />
                    <span className="text-xs font-bold text-navy-700 mb-0 pb-0 mt-0 py-2 px-3">
                      {product?.name}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </InfiniteScroll>
      <ModalPage
        setShowModalTopRight={setShowModalTopRight}
        showModalTopRight={showModalTopRight}
      />
    </div>
  );
}
