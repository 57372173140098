import { useForm } from "react-hook-form";
import { init, useConnectWallet } from "@web3-onboard/react";
import { ToastContainer, toast } from "react-toastify";
import injectedModule from "@web3-onboard/injected-wallets";
import "react-placeholder/lib/reactPlaceholder.css";
import { Client, Session } from "@heroiclabs/nakama-js";
import TutorialSidebar from "../components/Tutorial";

import { useNavigate } from "react-router-dom";
import { checkSessionExpiry, reAuthenticate } from "../api/auth";

import DrawerMenuSlide from "../components/DrawerMenuSlide";
import { useAccount } from "wagmi";
import PortalDrawer from "../components/PortalDrawer";
import TermsCondition from "../components/TermsCondition";

import { useState, useCallback, useEffect, useLayoutEffect } from "react";
import DashboardSidebarComponent from "../components/DashboardSidebarComponent";
import useNavigation from "../api/route";
import { fetchDataForPosts, fetchBlogPosts } from "../api/fetch";
import Header from "../components/Header";
import CustomTooltip from "../components/tooltip";
import parse from "html-react-parser";

const injected = injectedModule();
const bscTestnetRpcUrl = "https://data-seed-prebsc-1-s1.binance.org:8545"; // BSC Testnet RPC URL

// initialize Onboard
init({
  wallets: [injected],
  chains: [
    {
      id: "0x61", // BSC Testnet Chain ID
      token: "BNB",
      label: "Binance Smart Chain Testnet",
      rpcUrl: bscTestnetRpcUrl,
    },
  ],
  appMetadata: {
    name: "Blocknative Web3-Onboard",
    description: "Demo app for Web3-Onboard",
    recommendedInjectedWallets: [
      { name: "Coinbase", url: "https://wallet.coinbase.com/" },
      { name: "MetaMask", url: "https://metamask.io" },
    ],
    agreement: {
      version: "1.0.0",
      termsUrl: "https://www.blocknative.com/terms-conditions",
      privacyUrl: "https://www.blocknative.com/privacy-policy",
    },
    gettingStartedGuide: "https://blocknative.com", // DApp guide to getting started
    explore: "https://blocknative.com",
  },
});

export default function DashboardBasic(totalTimeShovel: any, mapIcon2: any) {
  const [isDrawerMenuSlideOpen, setDrawerMenuSlideOpen] = useState(false);
  const [htmlencode, setHTMLEncode] = useState<any | null>(null);
  const [blogPosts, setBlogPosts] = useState<any | null>(null);
  const [username, setUsername] = useState("");
  const [facebookEmail, setFacebookEmail] = useState("");
  const [tutorialDashboard, setTutorialDashboard] = useState<any[]>([]);

  const [ready, setReady] = useState<boolean>(false);
  const [walletAddress, setWalletAddress] = useState<string | null>(null);
  const [toggleTutorial, setToggleTutorial] = useState<boolean>(false);

  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();

  const [client, setClient] = useState<any | null>(null);

  const { address, isConnected, status } = useAccount();
  const navigate = useNavigate();

  // check if facebook is link

  const {
    formState: { errors },
  } = useForm();

  const {
    onDashboardClick,
    onProfileClick,
    onInventoryClick,
    onMyNFTClick,
    onLogoutClick,
    onInventoryMemoryFragmentClick,
    onInventoryPlacedBookmarkClick,
    onInventoryCostumesClick,
    onInventoryAllItemClick,
    onCatalogClick,
  } = useNavigation(); // Use the custom hook
  useEffect(() => {
    const items = [
      {
        title: "",
        icon: "/phinfobold1.svg",
        content: "",
      },
      // Add more items as needed
    ];
    setTutorialDashboard(items);
  }, [status]);

  async function getProfileDetails() {
    const useSSL = window.location.protocol === "https:";
    const client = new Client(
      useSSL ? process.env.REACT_APP_CLIENT_KEY! : process.env.REACT_APP_DEV_CLIENT_KEY!,
      useSSL ? process.env.REACT_APP_API_HOST! : process.env.REACT_APP_DEV_API_HOST!,
      useSSL ? process.env.REACT_APP_API_PORT! : process.env.REACT_APP_DEV_API_PORT!,
      useSSL
    );
    setClient(client);

    const sessionData = localStorage.getItem("session");

    if (sessionData !== null) {
      let session;
      try {
        session = JSON.parse(sessionData);
      } catch (error) {
        console.error("Error parsing session data:", error);
        reAuthenticate(navigate);

        // return;
      }

      if (session && session != "") {
        let sessionCheck = Session.restore(
          session.token,
          session.refresh_token
        );

        // console.info(sessionCheck?.username);

        // set username here

        if (sessionCheck?.username) {
          setUsername(sessionCheck?.username);
        }

        let session1 = Session.restore(
          sessionCheck.token,
          sessionCheck.refresh_token
        );

        let expireTest = session1.isexpired(Date.now() / 1000);

        //@ts-ignore
        const expiresAtDate = new Date(sessionCheck?.expires_at * 1000);

        // const expiryCheck = await checkSessionExpiry(expiresAtDate);
        const currentTime = new Date();

        if (currentTime >= expiresAtDate) {
          reAuthenticate(navigate);
          if (session1.username) {
            let sessionRefresh = await client.authenticateCustom(
              session1.username,
              true
            );
          }
        } else {
          // console.log("not expired");

          session1 = Session.restore(
            sessionCheck.token,
            sessionCheck.refresh_token
          );

          const acc = client.getAccount(session1);
        }
      }
    } else {
      console.error(
        "Session data or Google token is null. Handle this case accordingly."
      );
      reAuthenticate(navigate);
    }
  }

  const postData = async () => {
    let patchData = await fetchDataForPosts();

    if (patchData) {
      setHTMLEncode(await patchData);
    }
  };

  const blogPost = async () => {
    // return

    let blogList = await fetchBlogPosts();

    setBlogPosts(blogList?.posts);
  };

  useEffect(() => {
    let isMounted = true; // Flag to check if component is mounted
    const fetchData = async () => {
      try {
        await Promise.all([blogPost(), getProfileDetails(), postData()]);
        // All API calls have completed successfully
        if (isMounted) {
          setReady(true); // Set ready state to true
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // reAuthenticate(navigate);
        // Handle error if any of the API calls fail
      }
    };

    fetchData(); // Call the function to fetch data

    return () => {
      isMounted = false; // Set isMounted to false when component unmounts
    };
  }, [facebookEmail]); // Dependencies for the useEffect

  useEffect(() => {
    if (wallet) {
      // Wallet is connected, set the wallet address
      setWalletAddress(wallet.accounts[0].address); // Assuming the first account is used
    } else {
      // Wallet is disconnected, clear the wallet address
      setWalletAddress(null);
    }
  }, [wallet, client]);

  useLayoutEffect(() => {
    if (ready) {
      window.scrollTo(0, 0);
    }
  }, [ready]);

  const openDrawerMenuSlide = useCallback(() => {
    setDrawerMenuSlideOpen(!isDrawerMenuSlideOpen);
  }, []);

  const closeDrawerMenuSlide = useCallback(() => {
    setDrawerMenuSlideOpen(false);
  }, []);

  return (
    <>
      <div
        className={`w-full h-auto relative bg-whitesmoke-100 flex flex-col items-start justify-start text-left text-5xs text-royalblue font-dm-sans lg:h-screen md:w-auto md:[align-self:unset] sm:w-auto sm:[align-self:unset] sm:pl-0 sm:box-border mobileChangess
      ${status === "connecting" ? "loadingOverlay" : ""}
      `}
      >
        <div className="self-stretch flex-1 flex flex-row items-start justify-start gap-[16px] lg:flex-1 md:self-stretch md:w-auto md:flex-1 md:gap-[0px] md:items-start md:justify-center sm:self-stretch sm:w-auto sm:flex-1 sm:gap-[0px] sm:pl-0 sm:box-border">
          <DashboardSidebarComponent
            ready={ready}
            terraquestLogoBig1="/terraquestlogobig-1@2x.png"
            onDashboardClick={onDashboardClick}
            onProfileClick={onProfileClick}
            onInventoryClick={onInventoryClick}
            onMyNFTClick={onMyNFTClick}
            onInventoryMemoryFragmentClick={onInventoryMemoryFragmentClick}
            onLogoutClick={onLogoutClick}
            onInventoryPlacedBookmarkClick={onInventoryPlacedBookmarkClick}
            onInventoryCostumesClick={onInventoryCostumesClick}
            onInventoryAllItemClick={onInventoryAllItemClick}
            onCatalogClick={onCatalogClick}
          />

          <div className="self-stretch flex-1 flex flex-col items-center justify-between lg:h-auto md:flex-1 md:gap-[16px] sm:flex-1 sm:gap-[8px] sm:items-center sm:justify-between sm:pl-0 sm:box-border">
            <div className="mobileMinWidth w-[944px] bg-colors-white-100 shadow-[0px_3.5px_5.5px_rgba(0,_0,_0,_0.02)] hidden flex-row items-center justify-between py-2 px-4 box-border lg:hidden md:flex md:self-stretch md:w-auto md:pl-4 md:pr-4 md:box-border sm:self-stretch sm:w-auto sm:pl-4 sm:box-border mobMargin">
              <img
                className="w-[92.8px] relative h-14 object-cover"
                alt=""
                src="/terraquestlogobig-2@2x.png"
              />
              <img
                className="w-12 relative h-12 overflow-hidden shrink-0 cursor-pointer"
                alt=""
                src="/iconamoonmenuburgerhorizontalbold.svg"
                onClick={openDrawerMenuSlide}
              />
            </div>
            <main className="!relative self-stretch flex flex-col items-center justify-start py-0 px-4 gap-[8px] text-left text-sm text-dimgray-200 lg:h-auto md:self-stretch md:w-auto md:flex-1 md:gap-[16px] md:p-10 md:box-border sm:self-stretch sm:w-auto sm:h-auto sm:gap-[8px] overflow-x-hidden sm:box-border mobileChangess"
            >
              {/* Call the tutorial sidebar */}
              {/* <TutorialSidebar
                setToggleTutorial={setToggleTutorial}
                toggleTutorial={toggleTutorial}
                items={tutorialDashboard}
              /> */}
              <Header ready={!ready} />
              {/* Call the tutorial sidebar */}
              {/* <TutorialSidebar
                setToggleTutorial={setToggleTutorial}
                toggleTutorial={toggleTutorial}
              /> */}
              {/* end */}
              <div
                className="self-stretch rounded-2xl bg-white flex flex-row items-start justify-start p-4 box-border max-w-full  maxContainer
              font-dm-sans "
              >
                <div className="flex-1 rounded-2xl box-border flex flex-row items-start justify-start py-0 px-0.5 max-w-full border-[2px] border-dashed border-whitesmoke-400">
                  <div className="flex-1 rounded-2xl flex flex-col items-start justify-start pt-[14.5px] px-2 pb-2 box-border gap-[8px] max-w-full">
                    <div className="self-stretch flex flex-col items-start justify-start gap-[14.5px] max-w-full">
                      <div className="flex flex-row items-start justify-start py-0 px-2">
                        <div className="flex flex-row items-start justify-start gap-[8px]">
                          <b
                            className={`relative leading-[120%] uppercase text-[#593F3F]`}
                          >
                            Dashboard
                          </b>

                          <CustomTooltip content="This is a tooltip">
                            <button
                              style={{ maxWidth: "300px", minWidth: "200px" }}
                            >
                              {" "}
                              View your game data, stay updated on the latest
                              developments, and mint specific in-game items as
                              NFTs.
                            </button>
                          </CustomTooltip>
                        </div>
                      </div>
                      <div className="self-stretch flex flex-col flex-rowDesk mq750:flex-row items-start justify-start gap-[11px] max-w-full text-center text-white mq750:flex-wrap">
                        <div className="h-auto w-[273px] rounded-lg bg-white box-border flex flex-col items-start justify-start pt-0 px-0 pb-1 min-w-[273px] border-[1px] border-solid border-whitesmoke-300 mq750:flex-1 mx-auto">
                          <div className="self-stretch flex flex-row items-center justify-start py-1 px-4">
                            <div className="flex-1 flex flex-col items-start justify-center pt-2 px-0 pb-0.5 gap-[2px]">
                              <div
                                className={`self-stretch rounded-3xl bg-mediumseagreen flex flex-row items-center justify-center py-1 px-0
                              ${!ready
                                    ? "bg-slate-200 text-transparent animate-pulse h-6 "
                                    : ""
                                  }`}
                              >
                                <b className="flex-1 relative leading-[120%] pt-1">
                                  {username}
                                </b>
                              </div>
                              <div className="self-stretch flex flex-row items-center justify-start py-1 px-0 gap-[16px] text-left text-sm text-dimgray"></div>
                            </div>
                          </div>
                          <div className="self-stretch flex flex-col items-start justify-start py-0.5 px-0">
                            <img
                              className="self-stretch h-0 relative max-w-full overflow-hidden shrink-0 object-contain"
                              alt=""
                              src="/termsof-service-link.svg"
                            />
                          </div>

                          <div className="overflow-y-auto h-[350px]">
                            {blogPosts &&
                              blogPosts.map((blogPost: any) => {
                                return (
                                  <div className="self-stretch flex-1 rounded-lg flex flex-row items-start justify-start pt-0 px-2 pb-2 gap-[4px] text-left text-3xs text-darkslategray-300 font-baloo-2">
                                    <a
                                      href={blogPost?.url}
                                      target="__blank"
                                      className="self-stretch flex-1 rounded-lg bg-whitesmoke-200 overflow-hidden flex flex-col items-center justify-start gap-[5px]
                                      no-underline"
                                    >
                                      {!ready ? (
                                        <div
                                          className={`!bg-slate-200 text-transparent animate-pulse !h-[131px] w-full "
                                              
                                          `}
                                        ></div>
                                      ) : (
                                        <img
                                          className={`
                                        
                                        self-stretch flex-1 relative rounded-lg max-w-full overflow-hidden max-h-full object-cover`}
                                          loading="lazy"
                                          alt=""
                                          src={blogPost?.feature_image}
                                        />
                                      )}

                                      <div className="self-stretch flex flex-col items-start justify-start py-0 px-2">
                                        <div
                                          className={`self-stretch relative text-xs font-semibold text-darkslategray-100
                                        ${!ready
                                              ? "bg-slate-200 text-transparent animate-pulse rounded-lg h-4 my-2"
                                              : ""
                                            }
                                        `}
                                        >
                                          {blogPost?.title}
                                        </div>
                                      </div>
                                    </a>
                                    <div className="h-20 w-1 relative rounded-lg bg-gainsboro" />
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        <div
                          className="flex-1 text-black rounded-lg bg-white box-border overflow-hidden flex flex-col items-start justify-start pt-3 pb-2 gap-[8px] mobileMinWidth min-w-[372px] max-w-full text-left text-sm text-dimgray border-[1px] border-solid border-whitesmoke-300 mq675:min-w-full
                        !px-2"
                        >
                          {!ready ? (
                            <div
                              className={`!bg-slate-200 text-transparent animate-pulse h-[70vh] w-full rounded-lg
                              
                                              
                                          `}
                            ></div>
                          ) : (
                            <div className="px-2 overflow-y-auto h-[350px] w-full">
                              {parse(htmlencode)}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-row items-start justify-start gap-[12px] text-sm md:flex-1 md:flex-col">
                      <div className="self-stretch flex flex-col items-start justify-start gap-[8px] md:self-stretch md:w-auto md:flex-1 w-[424px]">


                        {!ready ? (
                          <div
                            className={`!bg-slate-200 text-transparent animate-pulse !h-[80px] w-full rounded-lg "
                                              
                                          `}
                          ></div>
                        ) : (
                          <div
                            className="w-[424px] flex-1 rounded-lg flex flex-col items-start justify-end bg-[url('/public/all-items@3x.png')] bg-cover bg-no-repeat bg-[top] cursor-pointer md:self-stretch md:w-auto mq4k:rounded-2xl"
                            onClick={onInventoryAllItemClick}
                          >
                            <div className="self-stretch flex flex-row items-center justify-start py-0 px-4">
                              <div className="flex-1 flex flex-row items-center justify-start p-2 gap-[8px] lg:flex-1 mq4k:gap-[16px] mq4k:pt-6 mq4k:pb-6 mq4k:box-border">
                                <button className="cursor-pointer [border:none] p-0 bg-[transparent] w-6 relative rounded h-6 overflow-hidden shrink-0 md:w-5 md:h-5 mq4k:w-14 mq4k:h-14 mq4k:rounded-lg">
                                  <img
                                    className="absolute h-[41.67%] w-6/12 top-[29.17%] right-[25%] bottom-[29.17%] left-[25%] max-w-full overflow-hidden max-h-full mq4k:w-6 mq4k:h-6"
                                    alt=""
                                    src="/vector-3.svg"
                                  />
                                </button>
                                <h2 className="m-0 h-[17px] flex-1 relative text-inherit text-white leading-[170%] font-[500] font-inherit inline-block lg:flex-1 md:text-sm mq4k:text-9xl">
                                  Inventory
                                </h2>
                                <button className="cursor-pointer [border:none] p-0 bg-[transparent] w-6 relative h-6 overflow-hidden shrink-0">
                                  <img
                                    className="absolute h-[46.67%] w-[27.5%] top-[26.67%] right-[35%] bottom-[26.67%] left-[37.5%] max-w-full overflow-hidden max-h-full"
                                    alt=""
                                    src="/arrows1.svg"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                        {!ready ? (
                          <div
                            className={`!bg-slate-200 text-transparent animate-pulse !h-[80px] w-full rounded-lg "
                                              
                                          `}
                          ></div>
                        ) : (
                          <div
                            className="w-[424px] flex-1 rounded-lg flex flex-col items-start justify-end bg-[url('/public/all-items1@3x.png')] bg-cover bg-no-repeat bg-[top] cursor-pointer md:self-stretch md:w-auto mq4k:rounded-2xl"
                            onClick={onInventoryCostumesClick}
                          >
                            <div className="self-stretch flex flex-row items-center justify-start py-0 px-4">
                              <div className="flex-1 flex flex-row items-center justify-start p-2 gap-[8px] lg:flex-1 mq4k:gap-[16px] mq4k:pt-6 mq4k:pb-6 mq4k:box-border">
                                <button className="cursor-pointer [border:none] p-0 bg-[transparent] w-6 relative rounded h-6 overflow-hidden shrink-0 md:w-5 md:h-5 mq4k:w-14 mq4k:h-14 mq4k:rounded-lg">
                                  <img
                                    className="absolute h-3/6 w-6/12 top-[25%] right-[27.08%] bottom-[25%] left-[22.92%] max-w-full overflow-hidden max-h-full"
                                    alt=""
                                    src="/vector-5.svg"
                                  />
                                </button>
                                <h2 className="text-white m-0 h-[17px] flex-1 relative text-inherit leading-[170%] font-[500] font-inherit inline-block lg:flex-1 md:text-sm mq4k:text-9xl">
                                  Attires
                                </h2>
                                <div className="h-6 w-6 relative overflow-hidden shrink-0">
                                  <img
                                    className="absolute h-[46.67%] w-[27.5%] top-[26.67%] right-[35%] bottom-[26.67%] left-[37.5%] max-w-full overflow-hidden max-h-full"
                                    alt=""
                                    src="/arrows1.svg"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {!ready ? (
                          <div
                            className={`!bg-slate-200 text-transparent animate-pulse !h-[80px] w-full rounded-lg "
                                              
                                          `}
                          ></div>
                        ) : (
                          <div
                            className="w-[424px] flex-1 rounded-lg flex flex-col items-start justify-end bg-[url('/public/My_NFT.png')] bg-cover bg-no-repeat bg-[top] cursor-pointer md:self-stretch md:w-auto mq4k:rounded-2xl"
                            onClick={onMyNFTClick}
                          >
                            <div className="self-stretch flex flex-row items-center justify-start py-0 px-4">
                              <div className="flex-1 flex flex-row items-center justify-start p-2 gap-[8px] lg:flex-1 mq4k:gap-[16px] mq4k:pt-6 mq4k:pb-6 mq4k:box-border">
                                <button className="cursor-pointer [border:none] p-0 bg-[transparent] w-6 relative rounded h-6 overflow-hidden shrink-0 md:w-5 md:h-5 mq4k:w-14 mq4k:h-14 mq4k:rounded-lg">
                                  <img
                                    className="absolute h-full w-full top-[25%] right-[27.08%] bottom-[25%] left-[22.92%] max-w-full overflow-hidden max-h-full"
                                    alt=""
                                    src="/nftIcon.svg"
                                  />
                                </button>
                                <h2 className="text-white m-0 h-[17px] flex-1 relative text-inherit leading-[170%] font-[500] font-inherit inline-block lg:flex-1 md:text-sm mq4k:text-9xl">
                                  My NFT
                                </h2>
                                <div className="h-6 w-6 relative overflow-hidden shrink-0">
                                  <img
                                    className="absolute h-[46.67%] w-[27.5%] top-[26.67%] right-[35%] bottom-[26.67%] left-[37.5%] max-w-full overflow-hidden max-h-full"
                                    alt=""
                                    src="/arrows1.svg"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col w-full gap-3  mdMobile">
                        <div className="flex flex-row flex-wrap gap-3">
                          <button className="cursor-pointer p-4 bg-[transparent] self-stretch flex-1 rounded-lg [background:linear-gradient(180deg,_rgba(246,_246,_246,_0)_57%,_#e9e9e9)] border-[1px] border-solid border-lavender hover:box-border hover:border-[1px] hover:border-solid hover:border-royalblue active:box-border active:border-[1px] active:border-solid active:border-royalblue md:flex-1 md:h-auto md:items-center md:justify-center sm:p-3 sm:box-border">
                            <b className="self-stretch relative text-xs tracking-[0.02em] leading-[100%] font-dm-sans text-dimgray-300 text-center md:text-5xs sm:text-5xs fixedHeightMobile">
                              <p className="m-0">Number of items that</p>
                              <p className="m-0">have been excavated</p>
                            </b>

                            <div className="self-stretch flex-1 flex flex-col items-center justify-center">
                              <img
                                className="w-16 relative h-16 object-cover lg:w-16 lg:h-16 md:w-16 md:flex-1 sm:w-12 sm:h-12"
                                alt=""
                                src="/nodata.png"
                              />
                            </div>
                            <div className="relative text-xs tracking-[-0.02em] font-medium font-dm-sans text-dimgray-300 text-center md:text-6xs">
                              No data
                            </div>
                          </button>
                          <button className="cursor-pointer p-4 bg-[transparent] self-stretch flex-1 rounded-lg [background:linear-gradient(180deg,_rgba(246,_246,_246,_0)_57%,_#e9e9e9)] border-[1px] border-solid border-lavender hover:box-border hover:border-[1px] hover:border-solid hover:border-royalblue active:box-border active:border-[1px] active:border-solid active:border-royalblue md:flex-1 md:h-auto md:items-center md:justify-center sm:p-3 sm:box-border">
                            <b className="self-stretch relative text-xs tracking-[0.02em] leading-[100%] font-dm-sans text-dimgray-300 text-center md:text-5xs sm:text-5xs fixedHeightMobile">
                              <p className="m-0">Number of items that</p>
                              <p className="m-0">have been collected</p>
                            </b>

                            <div className="self-stretch flex-1 flex flex-col items-center justify-center">
                              <img
                                className="w-16 relative h-16 object-cover lg:w-16 lg:h-16 md:w-16 md:flex-1 sm:w-12 sm:h-12"
                                alt=""
                                src="/nodata.png"
                              />
                            </div>
                            <div className="relative text-xs tracking-[-0.02em] font-medium font-dm-sans text-dimgray-300 text-center md:text-6xs">
                              No data
                            </div>
                          </button>
                        </div>
                        <div className="flex flex-row flex-wrap gap-3">
                          <button className="cursor-pointer p-4 bg-[transparent] self-stretch flex-1 rounded-lg [background:linear-gradient(180deg,_rgba(246,_246,_246,_0)_57%,_#e9e9e9)] border-[1px] border-solid border-lavender hover:box-border hover:border-[1px] hover:border-solid hover:border-royalblue active:box-border active:border-[1px] active:border-solid active:border-royalblue md:flex-1 md:h-auto md:items-center md:justify-center sm:p-3 sm:box-border">
                            <b className="self-stretch relative text-xs tracking-[0.02em] leading-[100%] font-dm-sans text-dimgray-300 text-center md:text-5xs sm:text-5xs fixedHeightMobile">
                              Total Time Shovel
                            </b>
                            <div className="self-stretch flex-1 flex flex-col items-center justify-center">
                              <img
                                className="w-16 relative h-16 object-cover lg:w-16 lg:h-16 md:w-16 md:flex-1 sm:w-12 sm:h-12"
                                alt=""
                                src="/nodata.png"
                              />
                            </div>
                            <div className="relative text-xs tracking-[-0.02em] font-medium font-dm-sans text-dimgray-300 text-center md:text-6xs">
                              No data
                            </div>
                          </button>
                          <button className="cursor-pointer p-4 bg-[transparent] self-stretch flex-1 rounded-lg [background:linear-gradient(180deg,_rgba(246,_246,_246,_0)_57%,_#e9e9e9)] border-[1px] border-solid border-lavender hover:box-border hover:border-[1px] hover:border-solid hover:border-royalblue active:box-border active:border-[1px] active:border-solid active:border-royalblue md:flex-1 md:h-auto md:items-center md:justify-center sm:p-3 sm:box-border">
                            <b className="self-stretch relative text-xs tracking-[0.02em] leading-[100%] font-dm-sans text-dimgray-300 text-center md:text-5xs sm:text-5xs fixedHeightMobile">
                              Total Time Radar
                            </b>

                            <div className="self-stretch flex-1 flex flex-col items-center justify-center">
                              <img
                                className="w-16 relative h-16 object-cover lg:w-16 lg:h-16 md:w-16 md:flex-1 sm:w-12 sm:h-12"
                                alt=""
                                src="/nodata.png"
                              />
                            </div>
                            <div className="relative text-xs tracking-[-0.02em] font-medium font-dm-sans text-dimgray-300 text-center md:text-6xs">
                              No data
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <TermsCondition
              privacyPolicyFontWeight="bold"
              termsAndServicesFontWeight="bold"
              copyright2023FontWeight="bold"
            />
            <div
              className=" bg-colors-white-100 h-20 hidden flex-row items-center justify-between py-2 px-6 box-border md:flex md:self-stretch md:w-auto sm:flex sm:gap-[8px] sm:pl-6 sm:pt-2 sm:pb-4 sm:box-border
            fixed bottom-0 z-[9999999]"
              style={{ width: "100%" }}
            >
              <div
                className="flex flex-col items-center justify-start !text-[#2F6DD5]"
                onClick={onDashboardClick}
              >
                <img
                  className="w-8 relative rounded h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/bxsdashboard13.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Dashboard
                </b>
              </div>
              <div
                className="flex flex-col items-center justify-start"
                onClick={onInventoryPlacedBookmarkClick}
              >
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/Inventory_icon.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Inventory
                </b>
              </div>
              <div className="hidden flex-col items-center justify-start">
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/Inventory_icon.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Logout
                </b>
              </div>
              <div
                className="flex flex-col items-center justify-start"
                onClick={onProfileClick}
              >
                <div className="w-8 relative rounded h-7 overflow-hidden shrink-0 mix-blend-normal">
                  <img
                    className="absolute top-[calc(20%_-_8px)] left-[calc(20%_-_8px)] w-8 h-8"
                    alt=""
                    src="/Profile_icon.svg"
                  />
                </div>
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Profile
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {isDrawerMenuSlideOpen && (
        <PortalDrawer onOutsideClick={closeDrawerMenuSlide}>
          <DrawerMenuSlide onClose={closeDrawerMenuSlide} />
        </PortalDrawer>
      )}
    </>
  );
}
