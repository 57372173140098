import { FunctionComponent } from "react";

type ResetPasswordContainerType = {
  actionButtonText?: string;
  resetPasswordInstructions?: string;
  ready?: boolean;
};

const ResetPasswordContainer: FunctionComponent<ResetPasswordContainerType> = ({
  actionButtonText,
  resetPasswordInstructions,
  ready,
}) => {
  return (
    <div className="flex flex-col items-start justify-start gap-[16px] text-left text-10xl text-dimgray-200 font-dm-sans sm:self-stretch sm:w-auto sm:gap-[24px] sm:items-center sm:justify-start">
      <div className="flex flex-col items-start justify-start md:pr-8 md:box-border sm:items-center sm:justify-start sm:pl-4 sm:pr-4 sm:box-border">
        <h1
          className={` m-0 relative text-inherit leading-[120%] uppercase font-bold font-inherit md:text-base sm:text-xl mq350small:text-xl`}
          id="Dashboard"
        >
          {actionButtonText}
        </h1>
        <h1
          className={`
                            
                            m-0 relative text-xs leading-[100%] font-medium font-dm-sans md:text-base mq350small:text-3xs`}
          id="Dashboard"
        >
          {resetPasswordInstructions}
        </h1>
      </div>
      <img
        className={`
                          
                          w-80 relative max-h-full sm:w-40`}
        alt=""
        src="/vector-253.svg"
      />
    </div>
  );
};

export default ResetPasswordContainer;
