import React, { useState, useEffect } from "react";
import Moralis from "moralis";
import { useAccount, useChainId } from "wagmi";

const Nfts: React.FC = () => {
  const { address } = useAccount();
  const chainId = useChainId();
  const [nftCollections, setNftCollections] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [chains, setChainID] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const chainIdHex = `0x${chainId.toString(16)}`;
    setChainID(chainIdHex);

    const fetchNFTs = async () => {
      try {
        await Moralis.start({
          apiKey:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6ImRhODVhYmNkLTdjOWEtNGViOC04NzkxLTkxMmU4ZWIzOTE4NSIsIm9yZ0lkIjoiMjQwMjc4IiwidXNlcklkIjoiMjQyMjE0IiwidHlwZUlkIjoiNWZhYzRjZDAtMWYyOS00ZjcwLWIxMWItZTlmODU2YWYxYmZiIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2ODQyMjI0NTUsImV4cCI6NDgzOTk4MjQ1NX0.lq73QYbiPWv8Fnn-ifMVjvNzBtMudl6bLgUzjLdIIhk",
        });

        const response = await Moralis.EvmApi.nft.getWalletNFTs({
          chain: chainIdHex,
          address: address || "",
        });

        const nfts = response.raw.result;

        if (Array.isArray(nfts)) {
          setNftCollections(nfts);
        } else {
          setNftCollections([]);
        }
      } catch (e) {
        console.error(e);
        //@ts-ignore
        setError(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNFTs();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    //@ts-ignore
    return <div>Error loading NFTs: {error.message}</div>;
  }

  return (
    <div>
      <h1>Your NFTs</h1>

      <div className="flex flex-row gap-5">
        {nftCollections.length > 0 ? (
          nftCollections.map((collection) => {
            const metadata = JSON.parse(collection.metadata);
            return (
              <div className="w-1/5 max-w-lg border border-gray-200 rounded-lg overflow-hidden mt-1 font-semibold">
                <div
                  style={{ background: `#${metadata.background_color}` }}
                  className="p-2  mb-3"
                >
                  <img
                    src={metadata.image.replace(
                      "ipfs://",
                      "https://ipfs.io/ipfs/"
                    )}
                    alt={metadata.name}
                    className="w-24 m-auto"
                  />
                </div>

                {collection.name}
                <br />
                {metadata.name}
                <br />
                <p className="px-4 py-2 font-normal">{metadata.description}</p>
              </div>
            );
          })
        ) : (
          <p>No NFTs found.</p>
        )}
      </div>
    </div>
  );
};

export default Nfts;
