import {
  FunctionComponent,
  useMemo,
  useEffect,
  useState,
  type CSSProperties,
} from "react";

import FacebookAuth from "./Authentication/facebookAuth";
import GoogleAuth from "./Authentication/googleAuth";

type SocialMediaLoginContainerType = {
  socialMediaButtonText?: string;

  /** Style props */
  propOpacity?: CSSProperties["opacity"];
  propMixBlendMode?: CSSProperties["mixBlendMode"];
  ready?: boolean;
  clientApi?: any;
};

const SocialMediaLoginContainer: FunctionComponent<
  SocialMediaLoginContainerType
> = ({
  socialMediaButtonText,
  propOpacity,
  propMixBlendMode,
  ready,
  clientApi,
}) => {
  const optionStyle: CSSProperties = useMemo(() => {
    return {
      opacity: propOpacity,
      mixBlendMode: propMixBlendMode,
    };
  }, [propOpacity, propMixBlendMode]);
  const [isMetaMaskBrowser, setIsMetaMaskBrowser] = useState(false);
  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    if (userAgent.includes("MetaMask")) {
      setIsMetaMaskBrowser(true);
    }
  }, []);

  return (
    <div
      className="flex flex-col items-start justify-start gap-[6px] opacity-[0.2] mix-blend-luminosity text-left text-sm text-dimgray-200 font-dm-sans sm:items-center sm:justify-start"
      style={optionStyle}
    >
      <h1
        className={`
          m-0 relative text-inherit leading-[120%] uppercase font-bold font-inherit md:text-base mq350small:text-xs`}
        id="Dashboard"
      >
        {socialMediaButtonText}
      </h1>
      <div className="flex flex-row items-start justify-start gap-[4px] sm:items-start sm:justify-center">
        <FacebookAuth ready={ready} clientApi={clientApi} />
        <GoogleAuth clientApi={clientApi} />
      </div>
    </div>
  );
};

export default SocialMediaLoginContainer;
