// clientUtils.ts
import { Client } from "@heroiclabs/nakama-js";

export const routeApi = () => {
  const useSSL = window.location.protocol === "https:";
  const client = new Client(
    useSSL ? process.env.REACT_APP_CLIENT_KEY! : process.env.REACT_APP_DEV_CLIENT_KEY!,
    useSSL ? process.env.REACT_APP_API_HOST! : process.env.REACT_APP_DEV_API_HOST!,
    useSSL ? process.env.REACT_APP_API_PORT! : process.env.REACT_APP_DEV_API_PORT!,
    useSSL
  );
  return client;
};
