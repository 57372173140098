// import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Client, Session } from "@heroiclabs/nakama-js";

export default function FacebookAuthLinking(props: any) {
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [isEmail, setEmail] = useState("");
  const navigate = useNavigate();

  // useEffect(() => {
  //   // if (props.facebookLink === true) {
  //   //   props.toast.success("Successfully Link Facebook");
  //   //   setTimeout(() => {
  //   //     window.location.reload();
  //   //   }, 5000);
  //   // }
  // }, [props.facebookLink]);

  // Function to initialize Facebook
  const initializeFacebook = () => {
    FB.init({
      appId: "877488580546968",
      xfbml: true,
      version: "v18.0",
    });

    FB.login(
      function (response) {
        if (response.authResponse) {
          FB.api("/me?fields=name,email", function (response: any) {
            setEmail(response.email);
          });

          FB.getLoginStatus(function (response) {
            if (response.status === "connected") {
              const facebooktoken = response.authResponse.accessToken;

              if (facebooktoken == undefined) {
                FB.login();
              } else {
                nakamaFacebook(facebooktoken, isEmail);
              }
            }
          });
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      { scope: "email" }
    );
  };

  function onSignout() {
    setisLoggedIn(false);
  }

  async function nakamaFacebook(fbtoken: string, emailData: any) {
    const currentRoute = window.location.pathname;

    const useSSL = window.location.protocol === "https:";
    const client = new Client(
      useSSL ? process.env.REACT_APP_CLIENT_KEY! : process.env.REACT_APP_DEV_CLIENT_KEY!,
      useSSL ? process.env.REACT_APP_API_HOST! : process.env.REACT_APP_DEV_API_HOST!,
      useSSL ? process.env.REACT_APP_API_PORT! : process.env.REACT_APP_DEV_API_PORT!,
      useSSL
    );
    const sessionData = localStorage.getItem("session");

    if (sessionData !== null) {
      const session = JSON.parse(sessionData);
      if (session && session != "") {
        let sessionCheck = Session.restore(
          session.token,
          session.refresh_token
        );
        const currentRoute = window.location.pathname;

        const useSSL = window.location.protocol === "https:";
        const client = new Client(
          useSSL ? process.env.REACT_APP_CLIENT_KEY! : process.env.REACT_APP_DEV_CLIENT_KEY!,
          useSSL ? process.env.REACT_APP_API_HOST! : process.env.REACT_APP_DEV_API_HOST!,
          useSSL ? process.env.REACT_APP_API_PORT! : process.env.REACT_APP_DEV_API_PORT!,
          useSSL
        );

        const googleTokenObject = { token: fbtoken };
        try {
          const linkEmail = await client.linkFacebook(
            sessionCheck,
            googleTokenObject
          );

          const result = await client.rpc(
            sessionCheck,
            "UpdateMetadata",
            JSON.parse(`{"metadata": {"facebookToken": "${fbtoken}"}}`)
          );

          // props.setFacebookLink(true);
          props.toast.success("Successfully Link Facebook");
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        } catch (error) {
          // props.setFacebookLink(true);
          props.toast.error("Account already link to other user.");
          setTimeout(() => {
            window.location.reload();
          }, 5000);
          // Handle the error appropriately, such as redirecting to the login page or displaying an error message
        }
      }
    }
  }

  return (
    <>
      {isLoggedIn ? (
        <button onClick={onSignout} className="cursor-pointer">
          Sign Out
        </button>
      ) : (
        <>
          <button onClick={initializeFacebook} className="bg-transparent">
            <img
              className="relative w-8 h-8 overflow-hidden shrink-0"
              alt=""
              src="/facebook--original.svg"
            />
          </button>
        </>
      )}
    </>
  );
}
