import { HTMLAttributes } from "react";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps extends HTMLAttributes<HTMLDivElement> {
  children: any;
}

function ProtectedRoute({ children }: ProtectedRouteProps) {
  const isLoggedIn = localStorage.getItem("session");

  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  return children;
}

export default ProtectedRoute;
