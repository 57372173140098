// clientUtils.ts
import { Session } from "@heroiclabs/nakama-js";



export const authenticateUser = async (clientApi: any, email: string, password: string, setErrorMessage: any, errorMessage: any, setLoadingData: any, loadingData: any): Promise<Session> => {


    try {
        const session = await clientApi.authenticateEmail(email, password);

        // Save session in local storage
        localStorage.setItem("session", JSON.stringify(session));

        // Update email into metadata
        const updateMetadata = await clientApi.rpc(
            session,
            "UpdateMetadata",
            JSON.parse(`{"metadata": {"email": "${email}"}}`)
        );


        localStorage.setItem("session", JSON.stringify(session));
        // Save session in local storage again (optional, based on your requirements)
        return session;

    } catch (error) {


        throw new Error("EMAIL OR PASSWORD IS INCORRECT");
    }


};
export const authenticateCustom = async (clientApi: any): Promise<Session> => {
    return await clientApi.authenticateCustom("adminTerraquest");

}

export const checkEmailAvailability = async (clientApi: any, sessionCheckEmail: any, payload: any): Promise<Session> => {

    return await clientApi.rpc(sessionCheckEmail, "checkEmailAvailability", payload)
    // return checkEmailAvailability;

}

export const authenticateEmail = async (clientApi: any, emailInput: any, password: any): Promise<Session> => {
    return clientApi.authenticateEmail(
        emailInput,
        password,
        true

    );

}
export const listStorageObjects = async (clientApi: any, session: any, keyName: any, userID: any): Promise<Session> => {
    return clientApi.listStorageObjects(
        session,
        keyName,
        session.user_id
    );

}

export async function checkSessionExpiry(expiresAtDate: any): Promise<any> {
    // Format the date and time nicely
    const formattedExpiration = expiresAtDate.toLocaleString();
    // Convert formattedExpiration string to Date object
    const expirationDate = new Date(formattedExpiration);

    // Split the formattedExpiration into date and time parts
    const [datePart, timePart] = formattedExpiration.split(", ");

    // Split the date part into day, month, and year
    const [day, month, year] = datePart.split("/");

    // Split the time part into hours, minutes, and seconds
    const [hour, minute, second] = timePart.split(":");

    // Create a new Date object with the parsed values
    const expirationDate1 = new Date(
        year,
        month - 1,
        day,
        hour,
        minute,
        second
    );

    return expirationDate1;
}




export const registerUser = async (clientApi: any, email: any, password: string, confirmpassword: string, errorMessage: any, setErrorMessage: any, setLoadingData: any, loadingData: any) => {
    const sessionCheckEmail = await clientApi.authenticateCustom("adminTerraquest");
    // check if email(input from user) existed in database or not
    const payload = { "email": email };
    const checkEmailAvailability = await clientApi.rpc(sessionCheckEmail, "checkEmailAvailability", payload)

    if (!checkEmailAvailability.payload) {


        const session = await clientApi.authenticateEmail(email, password, true);

        //update email into metadata
        const updatemetadata = await clientApi.rpc(session, "UpdateMetadata", JSON.parse(`{"metadata": {"email": "${email}"}}`));


        //  --------------------------------------------------- Call Send Email RPC ----------------------------------------------------
        // get session to check availability
        const sessionCheckEmail = await clientApi.authenticateEmail(email, password);

        // check if email(input from user) existed in database or not
        const payload = { "email": email };
        const SendEmail = await clientApi.rpc(sessionCheckEmail, "SendEmail", payload)


        return true;
        //   navigate("/reset-password-1-email-code-sent")


        // //save session in local 


        // setSuccessMessage("REGISTER SUCCESSFUL");
        // navigate("/profile")
        // Return relevant data


    } else {
        return false;

    }


};

export const activateEmail = async (clientApi: any, email: any, password: string, confirmpassword: string, errorMessage: any, setErrorMessage: any, setLoadingData: any, loadingData: any) => {

}
export async function reAuthenticate(navigate: any): Promise<any> {
    alert("Your Session has expired. Please Re-authenticate");
    localStorage.removeItem("session");
    setTimeout(() => {
        navigate("/");
        navigate(0);
    }, 1000);
}
export async function getWalletData(session1: any, client: any): Promise<any> {
    const getWalletData = await client.rpc(session1, "getWallet", {});
    return getWalletData.payload;
}

export async function protocolData(Client: any, setClient: any): Promise<any> {

    const useSSL = window.location.protocol === "https:";
    const client = new Client(
        useSSL ? process.env.REACT_APP_CLIENT_KEY! : process.env.REACT_APP_DEV_CLIENT_KEY!,
        useSSL ? process.env.REACT_APP_API_HOST! : process.env.REACT_APP_DEV_API_HOST!,
        useSSL ? process.env.REACT_APP_API_PORT! : process.env.REACT_APP_DEV_API_PORT!,
        useSSL
    );
    return client;    
}
export async function hashPassword(password: string, bcrypt: any): Promise<string> {
    try {
        const salt = await bcrypt.genSalt(10); // Generate a salt with 10 rounds
        const hashedPassword = await bcrypt.hash(password, salt);
        return hashedPassword;
    } catch (error) {
        console.error('Error hashing password:', error);
        throw new Error('Error hashing password');
    }
}