import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import CustomTooltip from "../components/tooltip";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { reAuthenticate } from "../api/auth";
import { Client, Session } from "@heroiclabs/nakama-js";

import ModalPage from "./Modal";
import SearchList from "../components/Search";
import {
  getAllItemsSet,
  getSpecialToolID,
  calculateInitialItemCount,
} from "../api/storage";

export default function InventoryCardCatalog(props: any) {
  const navigate = useNavigate();
  const [showModalTopRight, setShowModalTopRight] = useState(false);
  const [allItems, setAllItems] = useState<any[]>([]);
  const [currentItems, setCurrentItems] = useState<any | null>(null);
  const [ready, setReady] = useState<boolean>(false);
  const [client, setClient] = useState<any | null>(null);
  const [specialToolID, setSpecialToolID] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const useSSL = window.location.protocol === "https:";
        const client = new Client(
          useSSL ? process.env.REACT_APP_CLIENT_KEY! : process.env.REACT_APP_DEV_CLIENT_KEY!,
          useSSL ? process.env.REACT_APP_API_HOST! : process.env.REACT_APP_DEV_API_HOST!,
          useSSL ? process.env.REACT_APP_API_PORT! : process.env.REACT_APP_DEV_API_PORT!,
          useSSL
        );
        setClient(client);

        const sessionData = localStorage.getItem("session");
        if (sessionData) {
          const session = JSON.parse(sessionData);
          if (session) {
            let sessionCheck = Session.restore(
              session.token,
              session.refresh_token
            );
            const expiresAtDate = new Date(sessionCheck?.expires_at * 1000);
            const currentTime = new Date();

            if (currentTime >= expiresAtDate) {
              reAuthenticate(navigate);
            } else {
              let acc = await client.getAccount(sessionCheck);
              let getAllItems = await getAllItemsSet(sessionCheck, client);
              let getSpecialToolID1 = await getSpecialToolID(
                sessionCheck,
                client
              );

              setSpecialToolID(getSpecialToolID1.collection);

              const inventoryItemIDs = getAllItems.collection.map(
                (item: any) => item.ID_no_item
              );
              const filteredCostumeSet = getAllItems.collection.filter(
                (costume: any) => inventoryItemIDs.includes(costume.ID_no_item)
              );

              const specialToolItems = getSpecialToolID1.collection;

              const filteredCostumeSetList = filteredCostumeSet.map(
                (costume: any) => {
                  const matchingToolItem = specialToolItems.find(
                    (toolItem: any) =>
                      toolItem.ID_no_item === costume.ID_no_item
                  );

                  if (matchingToolItem) {
                    return { ...costume, ...matchingToolItem };
                  }
                  return costume;
                }
              );

              setAllItems(filteredCostumeSetList);
              setSearchResults(filteredCostumeSetList);

              const initialItems = await calculateInitialItemCount(
                filteredCostumeSetList,
                isMobile
              );

              setItems(initialItems);

              const handleResize = async () => {
                const updatedItems = await calculateInitialItemCount(
                  filteredCostumeSetList,
                  isMobile
                );
                setItems(updatedItems);
              };

              window.addEventListener("resize", handleResize);
              setReady(true);

              return () => {
                window.removeEventListener("resize", handleResize);
              };
            }
          }
        } else {
          const sessionLog = await client.authenticateCustom(
            "adminTerraquest1"
          );

          let sessionCheck1 = Session.restore(
            sessionLog.token,
            sessionLog.refresh_token
          );
          const expiresAtDate = new Date(sessionCheck1?.expires_at * 1000);
          const currentTime = new Date();

          if (currentTime >= expiresAtDate) {
            reAuthenticate(navigate);
          } else {
            let acc = await client.getAccount(sessionCheck1);
            let getAllItems = await getAllItemsSet(sessionCheck1, client);

            const inventoryItemIDs = getAllItems.collection.map(
              (item: any) => item.ID_no_item
            );
            const filteredCostumeSet = getAllItems.collection.filter(
              (costume: any) => inventoryItemIDs.includes(costume.ID_no_item)
            );

            setAllItems(filteredCostumeSet);
            setSearchResults(filteredCostumeSet);

            const calculateInitialItemCount = () => {
              let initialItemCount;
              const itemWidth = 180;
              const itemHeight = 150;

              const windowWidth = window.innerWidth;
              const windowHeight = window.innerHeight;

              const containerHeight = windowHeight * 0.8;
              const containerWidth = windowWidth * 0.92 - 52;
              const verticalItemCount = Math.floor(
                containerHeight / itemHeight
              );
              const horizontalItemCount = Math.floor(
                containerWidth / itemWidth
              );

              initialItemCount = Math.min(
                verticalItemCount * horizontalItemCount,
                filteredCostumeSet.length
              );

              if (isMobile) {
                initialItemCount = 20;
              }

              const shuffledImages = filteredCostumeSet.sort(
                (a, b) => new Date(b.LastModified) - new Date(a.LastModified)
              );
              const initialItems = shuffledImages.slice(0, initialItemCount);

              setItems(initialItems);
            };

            calculateInitialItemCount();

            const handleResize = () => {
              calculateInitialItemCount();
            };
            window.addEventListener("resize", handleResize);
            setReady(true);

            return () => {
              window.removeEventListener("resize", handleResize);
            };
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const fetchMoreItems = () => {
    const startIndex = items.length;
    const endIndex = startIndex + 13;
    const moreItems = searchResults.slice(startIndex, endIndex);
    setItems((prevItems) => [...prevItems, ...moreItems]);
  };

  const onItemClick = (item: any) => {
    setCurrentItems(item);
    setShowModalTopRight(true);
  };

  // const handleSearch = (searchInput: string) => {
  //   const filteredItems = allItems.filter((item) =>
  //     item.Name.toLowerCase().includes(searchInput.toLowerCase())
  //   );
  //   setSearchResults(filteredItems);
  //   setItems(filteredItems.slice(0, items.length)); // Set initial items to display
  // };
  const handleSearch = (searchInput: string) => {
    if (searchInput.length === 12) {
      // If search input is empty, display all items
      setItems(allItems);
    } else {
      // Otherwise, filter items based on search input
      const filteredItems = allItems.filter((item) =>
        item.Name.toLowerCase().includes(searchInput.toLowerCase())
      );
      setSearchResults(filteredItems);
      setItems(filteredItems.slice(0, items.length)); // Set initial items to display
    }
  };

  return (
    <div
      id="scrollableDiv"
      className="w-full self-stretch pl-0 rounded-xl bg-colors-white-100 p-4 text-left text-sm text-dimgray-200 font-dm-sans lg:h-auto md:w-full md:[align-self:unset] md:items-center md:justify-start md:p-4 md:box-border sm:w-full sm:[align-self:unset] sm:items-center sm:justify-center sm:p-2 sm:box-border"
      style={{
        paddingLeft: "21px",
        paddingRight: "31px",
        overflowY: "auto",
        height: "80vh",
      }}
    >
      <InfiniteScroll
        dataLength={items.length}
        next={fetchMoreItems}
        hasMore={items.length < searchResults.length}
        // loader={<h4>Loading...</h4>}
        scrollableTarget="scrollableDiv"
      >
        <div className="self-stretch rounded-xl py-2 px-4 border-[2px] border-dashed border-whitesmoke-300 md:w-auto md:[align-self:unset] sm:gap-[0px] sm:py-2 sm:px-1 sm:box-border">
          <div className="h-8 flex flex-row items-center justify-start gap-[8px] sm:pl-2 sm:box-border">
            <h1
              className={`m-0 h-[17px] relative text-inherit leading-[120%] uppercase font-bold font-inherit inline-block md:text-base sm:text-sm`}
              id="Dashboard"
            >
              Catalog List
            </h1>

            <div className="group relative my-12 flex justify-center">
              <CustomTooltip content="This is a tooltip">
                <button style={{ maxWidth: "300px", minWidth: "200px" }}>
                  Catalog
                </button>
              </CustomTooltip>
            </div>
          </div>

          <SearchList onSearch={handleSearch} />
          <div className="grid gridBoxesCostume">
            {!ready ? (
              <>
                {[...Array(Math.max(15 - items.length, 0))].map((_, index) => (
                  <div
                    key={`empty-${index}`}
                    className="bg-slate-200 text-transparent animate-pulse rounded-md !z-5 relative border-[#E1DEEE] border-2 border-solid flex flex-col  max-w-[180px] h-[160px] bg-clip-border shadow-3xl shadow-shadow-500 flex flex-col w-full undefined"
                  ></div>
                ))}
              </>
            ) : (
              <>
                {items.map((product, index) => (
                  <div
                    key={index}
                    className="!z-5 relative border-2 border-[#E1DEEE] flex flex-col rounded-[13px] max-w-[180px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 flex flex-col w-full bg-white undefined hover:border-[#2F6DD5]"
                  >
                    <div className="h-full">
                      <div
                        className="relative w-full flex flex-col"
                        onClick={() => onItemClick(product)}
                      >
                        <img
                          key={index}
                          src={
                            product?.Link ? product.Link : "/coming-soon.png"
                          }
                          className="h-[140px] w-full transition duration-500 object-contain rounded-t-xl hover:scale-[1.01]"
                          alt={`Image ${index}`}
                        />
                        <span className="text-xs font-bold text-navy-700 mb-0 pb-2 mt-[1rem] pt-0 px-3 text-center block lg:w-[150px] break-words">
                          {product?.Name}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </InfiniteScroll>
      <ModalPage
        title="Catalog Details"
        setShowModalTopRight={setShowModalTopRight}
        showModalTopRight={showModalTopRight}
        currentItems={currentItems}
      />
    </div>
  );
}
