import { FunctionComponent, useState, useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import NavigationSidebar from "../components/NavigationSidebar";
import DrawerMenuSlide from "../components/DrawerMenuSlide";
import PortalDrawer from "../components/PortalDrawer";
import ResetPasswordForm from "../components/ResetPasswordForm";
import TermsCondition from "../components/TermsCondition";

export default function ForgetPasswordFlow(props: any) {
  const [isDrawerMenuSlideOpen, setDrawerMenuSlideOpen] = useState(false);
  const [ready, setReady] = useState<boolean>(true);

  const onDashboardClick = useCallback(() => {
    // Please sync "Prototype-Dashboard" to the project
  }, []);

  const openDrawerMenuSlide = useCallback(() => {
    setDrawerMenuSlideOpen(true);
  }, []);

  const closeDrawerMenuSlide = useCallback(() => {
    setDrawerMenuSlideOpen(false);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setReady(false);
    }, 2000);
  }, [ready]);

  return (
    <>
      <div className="w-full h-screen relative bg-whitesmoke-100 flex flex-col items-start justify-start text-left text-5xs text-royalblue font-dm-sans sm:w-auto sm:[align-self:unset] sm:h-screen">
        <div className="self-stretch flex-1 flex flex-row items-start justify-start gap-[16px] lg:flex-1 md:flex-1 sm:flex-1 sm:gap-[8px]">
          <NavigationSidebar
            ready={ready}
            onDashboardClick={onDashboardClick}
            itemCode="/login.svg"
            itemImageCode="/register.svg"
            loginBackgroundColor="#f9f9fb"
            loginOpacity="unset"
            bxsdashboardIconColor="#593f3f"
            registerBackgroundColor="transparent"
            registerOpacity="0.2"
            bxsdashboardIconColor1="#373737"
          />
          <div className="self-stretch flex-1 flex flex-col items-center justify-between lg:h-auto md:gap-[16px] sm:gap-[8px] sm:items-center sm:justify-between">
            <div className="w-[944px] bg-colors-white-100 shadow-[0px_3.5px_5.5px_rgba(0,_0,_0,_0.02)] hidden flex-row items-center justify-between py-2 px-4 box-border lg:hidden md:flex sm:self-stretch sm:w-auto">
              <img
                className="w-[92.8px] relative h-14 object-cover"
                alt=""
                src="/terraquestlogobig-2@2x.png"
              />
              <img
                className="w-12 relative h-12 overflow-hidden shrink-0 cursor-pointer"
                alt=""
                src="/iconamoonmenuburgerhorizontalbold.svg"
                onClick={openDrawerMenuSlide}
              />
            </div>
            <ResetPasswordForm ready={ready} clientApi={props.clientApi} />
            <TermsCondition
              privacyPolicyFontWeight="bold"
              termsAndServicesFontWeight="bold"
              copyright2023FontWeight="bold"
            />
            <div className="w-[944px] bg-colors-white-100 h-12 hidden flex-row items-center justify-between py-2 px-6 box-border md:flex md:self-stretch md:w-auto sm:flex sm:self-stretch sm:w-auto sm:gap-[8px] sm:pt-2 sm:pb-4 sm:box-border">
              <div className="flex flex-col items-center justify-start">
                <img
                  className="w-8 relative rounded h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/bxsdashboard13.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Dashboard
                </b>
              </div>
              <div className="flex flex-col items-center justify-start">
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/Inventory_icon.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Inventory
                </b>
              </div>
              <div className="hidden flex-col items-center justify-start">
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/Inventory_icon.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Logout
                </b>
              </div>
              <div className="flex flex-col items-center justify-start">
                <div className="w-8 relative rounded h-7 overflow-hidden shrink-0 mix-blend-normal">
                  <img
                    className="absolute top-[calc(50%_-_8px)] left-[calc(50%_-_8px)] w-4 h-4"
                    alt=""
                    src="/vector.svg"
                  />
                </div>
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Profile
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDrawerMenuSlideOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top"
          onOutsideClick={closeDrawerMenuSlide}
        >
          <DrawerMenuSlide onClose={closeDrawerMenuSlide} />
        </PortalDrawer>
      )}
    </>
  );
}
