//@ts-ignore

import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import "@rainbow-me/rainbowkit/styles.css";
import {
  arbitrum,
  base,
  mainnet,
  optimism,
  polygon,
  polygonAmoy,
  sepolia,
  bscTestnet,
} from "wagmi/chains";
import AuthRoute from "./Route/AuthRoute";
import ProtectedRoute from "./Route/ProtectedRoutes";

import ForgetPasswordFlow1EmailP from "./pages/ForgetPasswordFlow1EmailP";
import InGameInventory from "./pages/InGameInventory";
import MyNFTShowAllNFT from "./pages/MyNFTShowAllNFT";
import Logout from "./pages/Logout";
import Profile from "./pages/Profile";
import MemoryFragmentConvertSucces from "./pages/MemoryFragmentConvertSucces";
import MemoryFragmentConvertMement from "./pages/MemoryFragmentConvertMement";
import InGameCostumes from "./pages/InGameCostumes";
import MemoryFragment from "./pages/MemoryFragment";
import PlacedBookmark from "./pages/PlacedBookmark";
import DashboardBasic from "./pages/DashboardBasic";
import ForgetPasswordFlow from "./pages/ForgetPasswordFlow";
import RegisterFlow from "./pages/RegisterFlow";
import VerificationFlow1EmailCode from "./pages/VerificationFlow1EmailCode";
import LoginFlow from "./pages/LoginFlow";
import ForgetPasswordFlow1EmailC from "./pages/ForgetPasswordFlow1EmailC";

import { routeApi } from "./api/clientUtils";
import ModalPage from "./components/Modal";
import CatalogList from "./pages/Catalog";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import MintNFT from "./pages/Mint";
import NFTs from "./pages/NftDisplay";
import TestBrowser from "./pages/TestBrowser";
import ChangePasswordFlow1EmailC from "./pages/ChangePasswordFlow1EmailC";
import ChangePasswordFlow1EmailP from "./pages/ChangePasswordFlow1EmailP";
import Test from "./pages/Test";

const config = getDefaultConfig({
  appName: "Testing purposes",
  projectId: "65eb9c3c31f5778d4f5dfb63b296bc28",
  chains: [
    mainnet,
    polygon,
    polygonAmoy,
    optimism,
    arbitrum,
    bscTestnet,
    base,
    ...(process.env.REACT_APP_ENABLE_TESTNETS === "true" ? [sepolia] : []),
  ],
});

const queryClient = new QueryClient();

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  const clientApi = routeApi();

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/inventory":
        title = "";
        metaDescription = "";
        break;
      case "/mynfts":
        title = "";
        metaDescription = "";
        break;
      case "/logout":
        title = "";
        metaDescription = "";
        break;
      case "/catalog":
        title = "";
        metaDescription = "";
        break;
      case "/profile":
        title = "";
        metaDescription = "";
        break;
      case "/memory-fragment-convert-successfully":
        title = "";
        metaDescription = "";
        break;
      case "/memory-fragment-convert-memento":
        title = "";
        metaDescription = "";
        break;
      case "/attires":
        title = "";
        metaDescription = "";
        break;
      case "/memory-fragment":
        title = "";
        metaDescription = "";
        break;
      case "/bookmarks":
        title = "";
        metaDescription = "";
        break;
      case "/dashboard":
        title = "";
        metaDescription = "";
        break;
      case "/reset-password":
        title = "";
        metaDescription = "";
        break;
      case "/register":
        title = "";
        metaDescription = "";
        break;
      case "/verification-flow-1-email-code-sent":
        title = "";
        metaDescription = "";
        break;
      case "/login-flow-1":
        title = "";
        metaDescription = "";
        break;
      case "/reset-password-1-email-code-sent":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <Routes>
            <Route
              path="/mint"
              element={
                <ProtectedRoute>
                  <MintNFT />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="/"
              element={
                <AuthRoute>
                  <LoginFlow clientApi={clientApi} />{" "}
                </AuthRoute>
              }
            />

            <Route
              path="/test"
              element={
                <AuthRoute>
                  <TestBrowser />{" "}
                </AuthRoute>
              }
            />

            <Route
              path="/inventory"
              element={
                <ProtectedRoute>
                  <InGameInventory clientApi={clientApi} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/mynfts"
              element={
                <ProtectedRoute>
                  <MyNFTShowAllNFT />
                </ProtectedRoute>
              }
            />
            <Route
              path="/logout"
              element={
                <ProtectedRoute>
                  <Logout />
                </ProtectedRoute>
              }
            />

            <Route
              path="/testNft"
              element={
                <ProtectedRoute>
                  <NFTs />
                </ProtectedRoute>
              }
            />

            <Route path="/catalog" element={<CatalogList />} />

            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile clientApi={clientApi} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/memory-fragment-convert-successfully"
              element={
                <ProtectedRoute>
                  <MemoryFragmentConvertSucces />
                </ProtectedRoute>
              }
            />
            <Route
              path="/memory-fragment-convert-memento"
              element={
                <ProtectedRoute>
                  <MemoryFragmentConvertMement />
                </ProtectedRoute>
              }
            />
            <Route
              path="/attires"
              element={
                <ProtectedRoute>
                  <InGameCostumes clientApi={clientApi} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/memory-fragment"
              element={
                <ProtectedRoute>
                  <MemoryFragment clientApi={clientApi} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/halo"
              element={
                <ProtectedRoute>
                  <Test />
                </ProtectedRoute>
              }
            />
            <Route
              path="/bookmarks"
              element={
                <ProtectedRoute>
                  <PlacedBookmark clientApi={clientApi} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <DashboardBasic />
                </ProtectedRoute>
              }
            />
            <Route
              path="/changePass"
              element={
                <ProtectedRoute>
                  <ChangePasswordFlow1EmailC />
                </ProtectedRoute>
              }
            />
            <Route
              path="/changePasswordInput"
              element={
                <ProtectedRoute>
                  <ChangePasswordFlow1EmailP />
                </ProtectedRoute>
              }
            />


            
            <Route
              path="/reset-password"
              element={
                <AuthRoute>
                  <ForgetPasswordFlow clientApi={clientApi} />
                </AuthRoute>
              }
            />
            <Route
              path="/register"
              element={
                <AuthRoute>
                  <RegisterFlow clientApi={clientApi} />
                </AuthRoute>
              }
            />
            <Route
              path="/verification-flow-1-email-code-sent"
              element={
                <AuthRoute>
                  <VerificationFlow1EmailCode clientApi={clientApi} />
                </AuthRoute>
              }
            />
            <Route
              path="/reset-password-1-email-code-sent"
              element={
                <AuthRoute>
                  <ForgetPasswordFlow1EmailC clientApi={clientApi} />
                </AuthRoute>
              }
            />

            
            <Route
              path="/reset-password-email"
              element={
                <AuthRoute>
                  <ForgetPasswordFlow1EmailP clientApi={clientApi} />
                </AuthRoute>
              }
            />
            <Route path="/modalPage" element={<ModalPage />} />
          </Routes>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}
export default App;
