import { FunctionComponent, useState, useCallback, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import NavigationSidebar from "../components/NavigationSidebar";
import DrawerMenuSlide from "../components/DrawerMenuSlide";
import PortalDrawer from "../components/PortalDrawer";
import Header from "../components/Header";
import ResetPasswordContainer from "../components/ResetPasswordContainer";
import SocialMediaLoginContainer from "../components/SocialMediaLoginContainer";
import Footer from "../components/Footer";
import TermsCondition from "../components/TermsCondition";
import { registerUser, authenticateCustom } from "../api/auth";

export default function RegisterFlow(props: any) {
  const [ready, setReady] = useState<boolean>(true);

  const [errorMessage, setErrorMessage] = useState("");
  const [loadingData, setLoadingData] = useState(false);
  const [isDrawerMenuSlideOpen, setDrawerMenuSlideOpen] = useState(false);
  const navigate = useNavigate();

  const onDashboardClick = useCallback(() => {
    // Please sync "Prototype-Dashboard" to the project
  }, []);

  const openDrawerMenuSlide = useCallback(() => {
    setDrawerMenuSlideOpen(true);
  }, []);

  const closeDrawerMenuSlide = useCallback(() => {
    setDrawerMenuSlideOpen(false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setReady(false);
    }, 2000);
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const notify = (message: any, types: any) =>
    toast(<p style={{ fontSize: 16 }}>{message}</p>, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      rtl: false,
      pauseOnFocusLoss: true,
      draggable: true,
      pauseOnHover: true,
      type: types,
    });

  const onSubmit = async (data: any) => {
    // toast.loading("Logging you in. 🚀🚀🚀.");
    toast(<p style={{ fontSize: 16 }}>Please Wait...</p>, {
      position: "bottom-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      rtl: false,
      pauseOnFocusLoss: true,
      draggable: true,
      pauseOnHover: true,
      type: "info",
    });
    setErrorMessage("");
    setLoadingData(true);
    const email: string = data.email;
    const password: string = data.password;
    const confirmpassword: string = data.confirmpassword;


    if (password.length && confirmpassword.length < 8) {
      setTimeout(() => {
        setLoadingData(false);
        setErrorMessage("MAKE SURE PASSWORD IS MORE THAN 8 CHARACTERS");
        notify("MAKE SURE PASSWORD IS MORE THAN 8 CHARACTERS", "error");
        setTimeout(() => {
          setErrorMessage("");
        }, 4300);
      }, 4650);
    } else if (password != confirmpassword) {
      setTimeout(() => {
        setLoadingData(false);
        setErrorMessage("PASSWORD DO NOT MATCH");
        notify("PASSWORD DO NOT MATCH", "error");
        setTimeout(() => {
          setErrorMessage("");
        }, 4300);
      }, 4650);
    } else {
      try {
        const session = await registerUser(
          props.clientApi,
          email,
          password,
          confirmpassword,
          errorMessage,
          setErrorMessage,
          setLoadingData,
          loadingData
        );

        setErrorMessage("");

        if (session === true) {
          setTimeout(() => {
            setLoadingData(false);

           // Pass email and password as props to the next route
          navigate("/verification-flow-1-email-code-sent", {
            state: { email, password },
          });
          }, 3000);
        } else {
          setTimeout(() => {
            setLoadingData(false);
            setErrorMessage("The email has been registered in the system");
            notify("The email has been registered in the system", "error");
            setTimeout(() => {
              setErrorMessage("");
            }, 4300);
          }, 4650);
        }
      } catch (error: any) {
        setTimeout(() => {
          setLoadingData(false);
          setErrorMessage(error.message);
        }, 3000);
      }
    }
  };

  return (
    <>
      <div className="w-full h-screen relative bg-whitesmoke-100  flex flex-col items-start justify-start text-left text-5xs text-royalblue font-dm-sans sm:w-auto sm:[align-self:unset] sm:h-screen">
        <div className="self-stretch flex-1 flex flex-row items-start justify-start gap-[16px] lg:flex-1 md:flex-1 sm:flex-1 sm:gap-[8px]">
          <NavigationSidebar
            ready={ready}
            itemCode="/loginOpacity.svg"
            itemImageCode="/registerActive.svg"
            catalogIcon="/Book_icon.svg"
            loginBackgroundColor="transparent"
            loginOpacity="1"
            bxsdashboardIconColor="#593f3f"
            registerFontWeight="bold"
            registerBackgroundColor="#f9f9fb"
            registerOpacity="unset"
            bxsdashboardIconColor1="#2F6DD5"
            onDashboardClick={onDashboardClick}
          />
          <div className="self-stretch flex-1 flex flex-col items-center justify-between lg:h-auto md:gap-[16px] sm:gap-[8px] sm:items-center sm:justify-between">
            <div className="w-[944px] bg-colors-white-100 shadow-[0px_3.5px_5.5px_rgba(0,_0,_0,_0.02)] hidden flex-row items-center justify-between py-2 px-4 box-border lg:hidden md:flex sm:self-stretch sm:w-auto">
              <img
                className="w-[92.8px] relative h-14 object-cover"
                alt=""
                src="/terraquestlogobig-2@2x.png"
              />
              <img
                className="w-12 relative h-12 overflow-hidden shrink-0 cursor-pointer"
                alt=""
                src="/iconamoonmenuburgerhorizontalbold.svg"
                onClick={openDrawerMenuSlide}
              />
            </div>
            <main className="self-stretch flex flex-col items-center justify-start py-0 px-4 gap-[24px] text-left text-xs text-dimgray-200 font-dm-sans lg:h-auto md:h-auto md:gap-[16px] sm:w-auto sm:[align-self:unset] sm:h-auto sm:gap-[8px] sm:pl-2 sm:pr-2 sm:box-border">
              <Header ready={ready} />
              <div className="self-stretch rounded-2xl bg-colors-white-100 flex flex-col items-start justify-start p-6 lg:h-auto md:p-4 md:box-border sm:self-stretch sm:w-auto sm:flex-1 sm:items-center sm:justify-start sm:p-4 sm:box-border Ultrawide:w-[1000px] Ultrawide:self-center">
                <div className="self-stretch rounded-2xl flex flex-col items-start justify-start p-6 border-[2px] border-dashed border-whitesmoke-300 md:gap-[16px] md:pl-4 md:box-border sm:self-stretch sm:w-auto sm:flex-1 sm:gap-[0px] sm:items-center sm:justify-start sm:py-5 sm:px-0 sm:box-border">
                  <div className="self-stretch flex flex-row items-start justify-start gap-[8px] sm:items-start sm:justify-center">
                    <div className="flex-1 flex flex-col items-start justify-start py-0 pr-8 pl-0 gap-[32px] md:gap-[24px] md:pr-0 md:box-border sm:flex-1 sm:items-center sm:justify-start sm:pl-0.5 sm:pr-0.5 sm:box-border">
                      <ResetPasswordContainer
                        ready={ready}
                        actionButtonText="Create New account"
                        resetPasswordInstructions="Register to create new account"
                      />
                      <div className="self-stretch flex flex-col items-start justify-start py-0 px-4 gap-[16px] md:gap-[24px] sm:gap-[24px] sm:items-center sm:justify-start">
                        <SocialMediaLoginContainer
                          ready={ready}
                          socialMediaButtonText="Register With Social Media"
                          propOpacity="unset"
                          propMixBlendMode="normal"
                        />

                        {/* <div
                          className={`
                        ${
                          ready
                            ? "bg-slate-200 text-transparent animate-pulse rounded-lg !h-4"
                            : ""
                        }
                        relative text-xs leading-[100%] font-medium font-dm-sans mix-blend-normal mq350small:text-3xs`}
                        >
                          Alternatively, you can use a different method
                        </div> */}
                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          className="w-full"
                        >
                          <div className="self-stretch flex flex-col items-center justify-start gap-[16px] text-sm font-dm-sans sm:gap-[8px] sm:items-center sm:justify-start">
                            <div className="self-stretch flex flex-col items-start justify-start gap-[8px] sm:gap-[0px] sm:items-start sm:justify-start">
                              <b
                                className={`relative leading-[120%] uppercase md:text-xs sm:text-sm mq350small:text-xs
                              `}
                              >
                                EMAIL
                              </b>
                              <input
                                className={`[outline:none] font-dm-sans text-sm bg-colors-white-100 self-stretch rounded-lg flex flex-col items-start justify-start py-2 px-3 text-dimgray-100 border-[1px] border-solid border-silver sm:h-10 sm:gap-[8px] sm:pt-2 sm:pb-2 sm:box-border mq350small:pt-1 mq350small:pb-1 mq350small:box-border
                              `}
                                placeholder={ready ? "" : ""}
                                type="email"
                                {...register("email", {
                                  required: true,
                                  pattern:
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                })}
                              />
                            </div>
                            <div className="self-stretch flex flex-col items-start justify-start gap-[8px] sm:gap-[0px] sm:items-start sm:justify-start">
                              <b
                                className={`relative leading-[120%] uppercase md:text-xs mq350small:text-xs
`}
                              >
                                Password
                              </b>
                              <input
                                className={`[outline:none] bg-colors-white-100 self-stretch rounded-lg flex flex-col items-start justify-start py-2 px-3 font-dm-sans text-sm text-dimgray-100 border-[1px] border-solid border-silver sm:h-10 sm:gap-[8px] sm:pt-2 sm:pb-2 sm:box-border mq350small:pt-1 mq350small:pb-1 mq350small:box-border
                                `}
                                placeholder={ready ? "" : ""}
                                type="password"
                                {...register("password", { required: true })}
                              />
                            </div>
                            <div className="self-stretch flex flex-col items-start justify-start gap-[8px] sm:gap-[0px] sm:items-start sm:justify-start">
                              <b
                                className={`relative leading-[120%] uppercase md:text-xs mq350small:text-xs
                            `}
                              >
                                Confirm Password
                              </b>
                              <div className="self-stretch flex flex-col items-center justify-start gap-[16px] text-center text-colors-white-100">
                                <div className="self-stretch flex flex-col items-center justify-start">
                                  <input
                                    className={`[outline:none] font-dm-sans text-sm bg-colors-white-100 self-stretch rounded-lg flex flex-col items-start justify-start py-2 px-3 text-dimgray-100 border-[1px] border-solid border-silver sm:h-10 sm:gap-[8px] sm:pt-2 sm:pb-2 sm:box-border mq350small:pt-1 mq350small:pb-1 mq350small:box-border
                                      `}
                                    type="password"
                                    {...register("confirmpassword", {
                                      required: true,
                                    })}
                                  />
                                </div>
                                {loadingData && (
                                  <img
                                    src="/loading.gif"
                                    className="w-16 h-16"
                                  />
                                )}
                                <div className="!text-red-500">
                                  <b className="self-stretch h-[17px] relative leading-[120%] uppercase inline-block">
                                    {errorMessage}
                                  </b>
                                  {/* <b className="self-stretch h-[17px] relative leading-[120%] uppercase inline-block">
                                  Email address already in Use
                                </b> */}
                                  {/* <b className="self-stretch h-[17px] relative leading-[120%] uppercase inline-block">
                                  PASSWORDs do not match
                                </b>
                                <b className="w-[246px] h-[17px] relative leading-[120%] uppercase hidden">
                                  Verification Code Cannot Be Blank
                                </b> */}
                                </div>
                              </div>
                            </div>
                          </div>

                          {loadingData === false && (
                            <Footer
                              ready={ready}
                              buttonText="Create New Account"
                              actionButtonText="Back"
                            />
                          )}
                        </form>
                      </div>
                    </div>
                    <img
                      className={`w-[400px] relative rounded-xl h-[400px] object-cover md:flex-1 md:self-stretch md:h-auto sm:hidden
                      `}
                      alt=""
                      src="/banner1-1@2x.png"
                    />
                  </div>
                </div>
              </div>
            </main>
            <TermsCondition
              privacyPolicyFontWeight="bold"
              termsAndServicesFontWeight="bold"
              copyright2023FontWeight="bold"
            />
            <div className="w-[944px] bg-colors-white-100 h-12 hidden flex-row items-center justify-between py-2 px-6 box-border md:flex sm:flex sm:self-stretch sm:w-auto sm:gap-[8px] sm:pt-2 sm:pb-4 sm:box-border">
              <div className="flex flex-col items-center justify-start">
                <img
                  className="w-8 relative rounded h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/bxsdashboard13.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Dashboard
                </b>
              </div>
              <div className="flex flex-col items-center justify-start">
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/Inventory_icon.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Inventory
                </b>
              </div>
              <div className="hidden flex-col items-center justify-start">
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/Inventory_icon.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Logout
                </b>
              </div>
              <div className="flex flex-col items-center justify-start">
                <div className="w-8 relative rounded h-7 overflow-hidden shrink-0 mix-blend-normal">
                  <img
                    className="absolute top-[calc(50%_-_8px)] left-[calc(50%_-_8px)] w-4 h-4"
                    alt=""
                    src="/vector.svg"
                  />
                </div>
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Profile
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      {isDrawerMenuSlideOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top"
          onOutsideClick={closeDrawerMenuSlide}
        >
          <DrawerMenuSlide onClose={closeDrawerMenuSlide} />
        </PortalDrawer>
      )}
    </>
  );
}
