import { createPublicClient, http } from 'viem';
import { mainnet } from 'viem/chains'; // Import a known chain type and customize it

const bscTestnet = {
  ...mainnet,
  id: 97, // BSC Testnet chain ID
  name: 'BSC Testnet',
  network: 'bsc-testnet'
};

export const clientData = createPublicClient({
  chain: bscTestnet,
  transport: http('https://data-seed-prebsc-1-s1.binance.org:8545/')
});
