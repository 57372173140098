import React, { useEffect, useState } from "react";

const TestBrowser = () => {
  const [isMetaMaskBrowser, setIsMetaMaskBrowser] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    if (userAgent.includes("MetaMask")) {
      setIsMetaMaskBrowser(true);
    }
  }, []);

  return (
    <div>
      {isMetaMaskBrowser ? (
        <p>Site is open in MetaMask browser app!</p>
      ) : (
        <p>Site is not open in MetaMask browser app.</p>
      )}
    </div>
  );
};

export default TestBrowser;
