import { FunctionComponent, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import ResetPasswordContainer from "./ResetPasswordContainer";
import SocialMediaLoginContainer from "./SocialMediaLoginContainer";

export default function ResetPasswordForm(props: any) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    // eslint-disable-next-line
    formState: { errors },
  } = useForm();

  useEffect(() => {}, [props.ready]);
  const onPrimaryButtonClick = useCallback(() => {
    // Please sync "Login_Flow_1" to the project
  }, []);

  const onPrimaryButton1Click = useCallback(() => {
    // Please sync "Forget_Password_Flow_1_Email_Code_Sent" to the project
  }, []);

  const onSubmit = async (data: any) => {
    const email: string = data.email;
    const session = await props.clientApi.authenticateCustom(email);

    //update email into metadata
    const updatemetadata = await props.clientApi.rpc(
      session,
      "UpdateMetadata",
      JSON.parse(`{"metadata": {"email": "${email}"}}`)
    );

    //  --------------------------------------------------- Call Send Email RPC ----------------------------------------------------
    // get session to check availability
    const sessionCheckEmail = await props.clientApi.authenticateCustom(email);

    // check if email(input from user) existed in database or not
    const payload = { email: email };
    const SendEmail = await props.clientApi.rpc(
      sessionCheckEmail,
      "SendEmail",
      payload
    );

    // Save email and password in localStorage
    console.log("email", email)
    // Pass email as a prop to the next route
    navigate("/reset-password-1-email-code-sent", {
      state: { email },
    });
    // navigate("/prototypedashboardconvertmemoryfragment");
  };

  return (
    <main className="self-stretch flex flex-col items-center justify-start py-0 px-4 gap-[24px] text-left text-xs text-dimgray-200 font-dm-sans lg:h-auto md:h-auto md:gap-[16px] sm:w-auto sm:[align-self:unset] sm:flex-1 sm:gap-[8px] sm:pl-2 sm:pr-2 sm:box-border">
      <Header ready={props.ready} />
      <div className="self-stretch rounded-2xl bg-colors-white-100 flex flex-col items-start justify-start p-6 lg:h-auto md:p-4 md:box-border sm:self-stretch sm:w-auto sm:flex-1 sm:items-center sm:justify-start sm:p-4 sm:box-border mq350small:self-stretch mq350small:w-auto Ultrawide:w-[1000px] Ultrawide:items-center Ultrawide:justify-start Ultrawide:self-center">
        <div className="self-stretch rounded-2xl flex flex-col items-start justify-start pt-6 px-6 pb-10 border-[2px] border-dashed border-whitesmoke-300 md:pl-4 md:box-border sm:flex-1 sm:gap-[0px] sm:items-center sm:justify-start sm:py-5 sm:px-0 sm:box-border">
          <div className="self-stretch flex flex-row items-start justify-start gap-[8px] sm:items-start sm:justify-center">
            <div className="flex-1 flex flex-col items-start justify-start py-0 pr-8 pl-0 gap-[32px] md:pr-0 md:box-border sm:flex-1 sm:self-stretch sm:h-auto sm:gap-[16px] sm:items-center sm:justify-start sm:pl-0.5 sm:pr-0.5 sm:box-border mq350small:gap-[16px] mq350small:items-center mq350small:justify-start mq350small:pr-0 mq350small:box-border">
              <ResetPasswordContainer
                ready={props.ready}
                actionButtonText="Reset password"
                resetPasswordInstructions="Forgot your password? Follow the steps below to reset"
              />
              <div className="self-stretch flex flex-col items-start justify-start py-0 px-4 gap-[16px] sm:flex-1 sm:gap-[24px] sm:items-center sm:justify-between mq350small:gap-[32px] mq350small:items-center mq350small:justify-between">
                {/* <SocialMediaLoginContainer
                  ready={props.ready}
                  socialMediaButtonText="Continue With Social Media"
                  propOpacity="0.4"
                  propMixBlendMode="luminosity"
                /> */}
                {/* <div
                  className={`
                        ${
                          props.ready
                            ? "bg-slate-200 text-transparent animate-pulse rounded-lg !h-4"
                            : ""
                        }
                        relative text-xs leading-[100%] font-medium font-dm-sans mix-blend-normal mq350small:text-3xs`}
                >
                  Alternatively, you can use a different method:
                </div> */}
                <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                  <div className="self-stretch flex flex-col items-center justify-start text-sm font-dm-sans sm:gap-[8px] sm:items-center sm:justify-start">
                    <div className="self-stretch flex flex-col items-start justify-start gap-[8px] sm:gap-[0px] sm:items-start sm:justify-start">
                      <b
                        className={`relative leading-[120%] uppercase md:text-xs mq350small:text-xs
`}
                      >
                        Email
                      </b>
                      <input
                        className={`mb-4 [outline:none] font-dm-sans text-sm bg-colors-white-100 self-stretch rounded-lg flex flex-col items-start justify-start py-2 px-3 text-dimgray-100 border-[1px] border-solid border-silver sm:h-10 sm:gap-[8px] sm:pt-2 sm:pb-2 sm:box-border mq350small:pt-1 mq350small:pb-1 mq350small:box-border
                              
                              `}
                        placeholder={props.ready ? "" : ""}
                        type="email"
                        {...register("email", {
                          required: true,
                          pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        })}
                      />
                    </div>
                  </div>
                  {/* <b className="self-stretch relative text-sm leading-[120%] uppercase font-dm-sans text-center sm:hidden">
                  Password Reset Successful
                </b> */}
                  <div className="self-stretch flex flex-col items-center justify-start gap-[24px] sm:items-center sm:justify-start mq350small:self-stretch mq350small:w-auto">
                    <div className="self-stretch flex flex-row items-start justify-center gap-[8px]">
                      <button
                        className={`cursor-pointer [border:none] py-0 px-5 bg-dimgray-200 rounded-lg h-8 flex flex-row items-center justify-center box-border sm:w-auto sm:[align-self:unset] sm:h-8 sm:rounded-lg sm:pl-3 sm:pt-0 sm:pr-3 sm:box-border
            
            `}
                        onClick={(e) => {
                          e.preventDefault(); // Prevent form submission
                          window.location.href = "/"; // Navigate to the root
                        }}
                        type="submit" // You can change this to "button" if you want to completely disable form submission behavior.
                      >
                        <div className="flex flex-row items-center justify-center sm:w-5">
                          <img
                            className="w-[5.3px] relative h-[10.7px] object-contain sm:w-[5px] sm:self-stretch sm:h-auto"
                            alt=""
                            src="/vector3.svg"
                          />
                        </div>
                      </button>

                      <button
                        className={`cursor-pointer [border:none] py-0 px-4 bg-dimgray-200 flex-1 rounded-lg h-8 flex flex-row items-center justify-center box-border sm:flex-1 sm:h-8 sm:rounded-lg sm:pl-6 sm:pt-0 sm:pr-6 sm:box-border
                        
                        `}
                        onClick={onPrimaryButton1Click}
                      >
                        <b className="relative text-sm tracking-[0.02em] leading-[100%] font-dm-sans text-colors-white-100 text-center sm:text-xs sm:flex-1 mq350small:text-3xs">
                          Send Verification Code
                        </b>
                      </button>
                    </div>
                    {/* <div
                      className={`relative leading-[100%] font-medium mq350small:text-3xs`}
                    >
                      {props.ready ? "" : <> Create New Account</>}
                    </div> */}
                  </div>
                </form>
              </div>
            </div>
            <img
              className={`w-[400px] relative rounded-xl h-[400px] object-cover md:flex-1 md:self-stretch md:h-auto sm:hidden
                      `}
              alt=""
              src="/banner1-1@2x.png"
            />
          </div>
        </div>
      </div>
    </main>
  );
}
