
import AWS from "aws-sdk";
import { Session } from "@heroiclabs/nakama-js";

const S3_BUCKET = "terraquest-items";
const REGION = "ap-southeast-1";
const ACCESS_KEY = "AKIA4QBGOHJV6EZIBMW3";
const SECRET_ACCESS_KEY = "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";
const s3 = new AWS.S3({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
    region: REGION,
});

const params1 = {
    Bucket: S3_BUCKET,
    Delimiter: "/",
    // Prefix: s3Folder + '/'
    Prefix: "/",
};
export async function getS3Images(S3_BUCKET: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
        const bucketParams = {
            Bucket: S3_BUCKET,
        };

        s3.listObjects(bucketParams, async (err, data) => {
            if (err) {
                console.log("Error", err);
                reject(err);
            } else {
                //@ts-ignore
                resolve(data?.Contents);

            }
        });
    });
}

export async function getCostumeSet(sessionRefresh: any, client: any): Promise<any> {
    const readStorage = {
        object_ids: [
            {
                collection: "Items",
                key: "CostumeSet",
            },
        ],
    };

    try {
        const result = await client.readStorageObjects(sessionRefresh, readStorage);


        if (result.objects.length > 0 && !undefined) {
            const items = result.objects[0].value;
            return items; // Return items if found
        } else {
            return null; // Return null if no items found
        }
    } catch (error) {
        console.error("Error fetching costume set:", error);
        throw error; // Rethrow the error to handle it at a higher level
    }
}

export async function getAllItemsSet(sessionRefresh: any, client: any): Promise<any> {
    const readStorage = {
        object_ids: [
            {
                // collection: "Items",
                // key: "Full_List_Resources_Tools_OddsEnds_Pets",
                collection: "Items",
                key: "Full_List_Resources_Tools_OddsEnds_Pets",
            },
        ],
    };

    try {
        const result = await client.readStorageObjects(sessionRefresh, readStorage);


        if (result.objects.length > 0 && !undefined) {
            const items = result.objects[0].value;
            return items; // Return items if found
        } else {
            return null; // Return null if no items found
        }
    } catch (error) {
        console.error("Error fetching costume set:", error);
        throw error; // Rethrow the error to handle it at a higher level
    }
}

export async function getMyInventoryItems(sessionRefresh: any, client: any, userID: any): Promise<any> {
    const readStorage = {
        object_ids: [
            {
                collection: "Inventory",
                key: "Items",
                user_id: userID,
            },
        ],
    };

    try {
        const result = await client.readStorageObjects(sessionRefresh, readStorage);

        if (result.objects.length > 0 && !undefined) {
            const items = result.objects[0].value;
            return items; // Return items if found
        } else {
            return null; // Return null if no items found
        }
    } catch (error) {
        console.error("Error fetching inventory items:", error);
        throw error; // Rethrow the error to handle it at a higher level
    }
}


export async function getSpecialToolID(sessionRefresh: any, client: any): Promise<any> {
    const readStorage = {
        object_ids: [
            {
                collection: "Items",
                key: "Stats_Attire_Tool_ID",
            },
        ],
    };

    try {
        const result = await client.readStorageObjects(sessionRefresh, readStorage);


        if (result.objects.length > 0 && !undefined) {
            const items = result.objects[0].value;
            return items; // Return items if found
        } else {
            return null; // Return null if no items found
        }
    } catch (error) {
        console.error("Error fetching Stats_Attire_Tool_ID set:", error);
        throw error; // Rethrow the error to handle it at a higher level
    }
}



export async function getMyResourcesItems(sessionRefresh: any, client: any, userID: any): Promise<any> {
    const readStorage = {
        object_ids: [
            {
                collection: "Inventory",
                key: "ResourcesTest",
                // key: "ResourcesTest",
                user_id: userID,
            },
        ],
    };

    try {
        const result = await client.readStorageObjects(sessionRefresh, readStorage);

        if (result.objects.length > 0 && !undefined) {
            const items = result.objects[0].value;
            return items; // Return items if found
        } else {
            return null; // Return null if no items found
        }
    } catch (error) {
        console.error("Error fetching inventory items:", error);
        throw error; // Rethrow the error to handle it at a higher level
    }
}


export async function getMyInventoryEquipments(sessionRefresh: any, client: any, userID: any): Promise<any> {
    const readStorage = {
        object_ids: [
            {
                collection: "Inventory",
                key: "Equipments",
                user_id: userID,
            },
        ],
    };

    try {
        const result = await client.readStorageObjects(sessionRefresh, readStorage);

        if (result.objects.length > 0 && !undefined) {
            const items = result.objects[0].value;
            return items; // Return items if found
        } else {
            return null; // Return null if no items found
        }
    } catch (error) {
        console.error("Error fetching inventory items:", error);
        throw error; // Rethrow the error to handle it at a higher level
    }
}

export async function calculateInitialItemCount(filteredCostumeSet: any, isMobile: any): Promise<any> {

    // Calculate initial item count based on screen size for desktop
    let initialItemCount;
    const itemWidth = 180; // Width of each item in pixels
    const itemHeight = 150; // Height of each item in pixels

    // Calculate window dimensions
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    // Calculate approximate number of items to fill the container vertically and horizontally
    const containerHeight = windowHeight * 0.8; // 80% of window height for container
    const containerWidth = windowWidth * 0.92 - 52; // Adjusted width considering padding
    const verticalItemCount = Math.floor(
        containerHeight / itemHeight
    );
    const horizontalItemCount = Math.floor(
        containerWidth / itemWidth
    );

    // Adjust the initial item count based on the smaller of the two counts
    initialItemCount = Math.min(
        verticalItemCount * horizontalItemCount,
        filteredCostumeSet.length
    );

    // For mobile devices, set a fixed initial item count
    if (isMobile) {
        initialItemCount = 20; // Adjust as needed
    }

    // Randomly select initial items
    const shuffledImages = filteredCostumeSet.sort(
        () => Math.random() - 0.5
    );
    const initialItems = shuffledImages.slice(0, initialItemCount);
    return (initialItems);


}