import { ConnectButton as RainbowKitConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";
import { WriteContract } from "../components/WriteContract";
import { Account } from "../components/Account";

export default function MintNFT() {
  const { isConnected } = useAccount();
  return (
    <>
      {isConnected ? <Account /> : <RainbowKitConnectButton />}
      {isConnected && <WriteContract />}
    </>
  );
}
