import { ToastOptions } from 'react-toastify';

export function ToastMessage(setIstoast: React.Dispatch<React.SetStateAction<boolean>>, type: 'info' | 'success' | 'warning' | 'error'): ToastOptions {
  return {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    rtl: false,
    type: type,
    pauseOnFocusLoss: false,
    draggable: true,
    pauseOnHover: false,
    onClose: () => setIstoast(false),
  };
}
