import { useState, useCallback, useEffect } from "react";
import bcrypt from "bcryptjs";
import Swal from "sweetalert2";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "react-placeholder/lib/reactPlaceholder.css";
import { Client, Session } from "@heroiclabs/nakama-js";
import TutorialSidebar from "../components/Tutorial";
import { ethers } from "ethers";
import useNavigation from "../api/route";

import ProfileSidebarComponent from "../components/ProfileSidebarComponent";
import DrawerMenuSlide from "../components/DrawerMenuSlide";
import PortalDrawer from "../components/PortalDrawer";
import Header from "../components/Header";
import TermsCondition from "../components/TermsCondition";
import SocialMediaLoginContainer from "../components/SocialMediaLinkContainer";
import { useAccount, useConnect, useDisconnect, useSignMessage } from "wagmi";
import { ConnectButton as RainbowKitConnectButton } from "@rainbow-me/rainbowkit";

import { checkSessionExpiry, reAuthenticate, hashPassword } from "../api/auth";
import CustomTooltip from "../components/tooltip";

function formatEthereumAddress(address: any) {
  if (address && address.length >= 10) {
    const prefix = address.substring(0, 6);
    const suffix = address.substring(address.length - 4);
    return `${prefix}...${suffix}`;
  }
  return address;
}

export default function Profile(props: any) {
  const navigate = useNavigate();
  const [isDrawerMenuSlideOpen, setDrawerMenuSlideOpen] = useState(false);
  const [facebookEmail, setFacebookEmail] = useState("");
  const [showPasswordInputs, setShowPasswordInputs] = useState(false);
  const [password, setPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [profileList, setProfileList] = useState<any | null>(null);
  const [ready, setReady] = useState<boolean>(false);
  const [isWalletLink, setIsWalletLink] = useState<boolean>(false);
  const [walletAddress, setWalletAddress] = useState<string | null>(null);
  const [client, setClient] = useState<any | null>(null);
  const [toggleTutorial, setToggleTutorial] = useState<boolean>(false);
  const [tutorialProfile, setTutorialProfile] = useState<any[]>([]);
  const { address, isConnected } = useAccount();

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    onDashboardClick,
    onProfileClick,
    onInventoryClick,
    onMyNFTClick,
    onLogoutClick,
    onInventoryMemoryFragmentClick,
    onInventoryPlacedBookmarkClick,
    onInventoryCostumesClick,
    onInventoryAllItemClick,
    onCatalogClick,
  } = useNavigation(); // Use the custom hook

  async function getProfileDetails() {
    const useSSL = window.location.protocol === "https:";
    const client = new Client(
      useSSL ? process.env.REACT_APP_CLIENT_KEY! : process.env.REACT_APP_DEV_CLIENT_KEY!,
      useSSL ? process.env.REACT_APP_API_HOST! : process.env.REACT_APP_DEV_API_HOST!,
      useSSL ? process.env.REACT_APP_API_PORT! : process.env.REACT_APP_DEV_API_PORT!,
      useSSL
    );
    setClient(client);

    const sessionData = localStorage.getItem("session");

    if (sessionData !== null) {
      const session = JSON.parse(sessionData);

      if (session && session != "") {
        let session1 = Session.restore(session.token, session.refresh_token);

        // Create a new Date object with the Unix timestamp in milliseconds
        //@ts-ignore
        const expiresAtDate = new Date(session1?.expires_at * 1000);

        // const expiryCheck = await checkSessionExpiry(expiresAtDate);
        const currentTime = new Date();

        if (currentTime >= expiresAtDate) {
          // Navigate to login or take appropriate action
          reAuthenticate(navigate);
        } else {
          const acc = client.getAccount(session1);

          // getWallet
          const getWalletData = await client.rpc(session1, "getWallet", {});

          if (getWalletData.payload === null) {
            setIsWalletLink(true);
          } else {
            //@ts-ignore
            setWalletAddress(getWalletData.payload);
          }

          setProfileList((await acc).user);

          if ((await acc).user?.facebook_id) {
            const facebookId = (await acc).user?.facebook_id;
            const userMetadata = (await acc).user?.metadata;
            let facebookTokens; // Declare facebookTokens here

            if (typeof userMetadata === "string") {
              facebookTokens = JSON.parse(userMetadata)?.facebookToken;
            } else {
            }

            // Check if facebookTokens is defined before passing it to getFacebookEmail
            if (facebookTokens) {
              getFacebookEmail(facebookTokens, facebookId);
            }
          }
        }
      } else {
        // Navigate to login or take appropriate action
        reAuthenticate(navigate);
      }
    } else {
      console.error(
        "Session data or Google token is null. Handle this case accordingly."
      );
      // Navigate to login or take appropriate action
      reAuthenticate(navigate);
    }
  }

  const handleAddPasswordClick = async () => {

    try {
      const useSSL = window.location.protocol === "https:";
      const client = new Client(
        useSSL ? process.env.REACT_APP_CLIENT_KEY! : process.env.REACT_APP_DEV_CLIENT_KEY!,
        useSSL ? process.env.REACT_APP_API_HOST! : process.env.REACT_APP_DEV_API_HOST!,
        useSSL ? process.env.REACT_APP_API_PORT! : process.env.REACT_APP_DEV_API_PORT!,
        useSSL
      );
      setClient(client);
      const sessionData = localStorage.getItem("session");

      if (sessionData !== null) {
        const session = JSON.parse(sessionData);

        if (session && session != "") {
          let sessionCheck = Session.restore(
            session.token,
            session.refresh_token
          );
          // Create a new Date object with the Unix timestamp in milliseconds
          //@ts-ignore
          const expiresAtDate = new Date(sessionCheck?.expires_at * 1000);

          const currentTime = new Date();

          if (currentTime >= expiresAtDate) {
            // Navigate to login or take appropriate action
            reAuthenticate(navigate);
          } else {
            let acc = await client.getAccount(sessionCheck);

            let userMetadataString = acc.user?.metadata;

            if (userMetadataString) {
              // Parse the metadata string
              let userMetadata = JSON.parse(userMetadataString);

              // Access the email
              let email = userMetadata.email;

              // check if email(input from user) existed in database or not
              const payload = { email: email };
              const SendEmail = await props.clientApi.rpc(
                sessionCheck,
                "SendEmail",
                payload
              );

              // // Pass email as a prop to the next route
              navigate("/changePass", {
                state: { email },
              });
            } else {
              console.log("No user metadata found.");
            }



          }
        }
      } else {
        reAuthenticate(navigate);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      reAuthenticate(navigate);
    }

  };
  const handleCurrentPasswordChange = (event: any) => {
    setCurrentPassword(event.target.value);
  };
  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event: any) => {
    setConfirmPassword(event.target.value);
  };


  useEffect(() => {
    let isMounted = true; // Flag to check if component is mounted
    const fetchData = async () => {
      try {
        await Promise.all([getProfileDetails()]);
        if (isMounted) {
          setReady(true); // Set ready state to true
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        reAuthenticate(navigate);
        // Handle error if any of the API calls fail
      }
    };

    const items = [
      {
        title: "Change Password",
        icon: "/phinfobold1.svg",
        content:
          "Click on <b>‘Change Password’</b>. Enter your old password, new password, and confirm your new password.",
      },
      {
        title: "Reset Password",
        icon: "/gridicons_external.svg",
        content:
          "To reset your password, log out of your account. Then click on <b>'Forgot password?'</b> and follow the steps.",
      },
      {
        title: "Bind Your Web3 Wallet",
        icon: "/gridicons_external.svg",
        content:
          "Click <b>'Connect Wallet'</b> to link your Web3 wallet to your dashboard.<br/> <br/>Go to your <b>Profile</b> and select <b>'Bind Wallet'</b>. Then, sign the Signature Request to link your connected wallet to your TerraQuest account.<br/><br/>NOTE: Currently, once your wallet is bound, it cannot be changed.",
      },
      // Add more items as needed
    ];
    setTutorialProfile(items);
    fetchData(); // Call the function to fetch data

    return () => {
      isMounted = false; // Set isMounted to false when component unmounts
    };
  }, [facebookEmail, isWalletLink]); // Dependencies for the useEffect
  useEffect(() => { }, [address]);

  // Function to initialize Facebook
  const getFacebookEmail = (facebookToken: any, facebookId: any) => {
    FB.init({
      appId: "877488580546968",
      xfbml: true,
      version: "v18.0",
    });

    // Define the Facebook Graph API endpoint
    const apiUrl = `https://graph.facebook.com/v18.0/${facebookId}?fields=email&access_token=${facebookToken}`;

    // Make a GET request to the Facebook Graph API endpoint
    axios
      .get(apiUrl)
      .then((response) => {
        // Handle the response
        // Extract the email from the response
        const email: string = response.data.email;
        if (response.data.email) {
          setFacebookEmail(response.data.email);
        } else {
          setFacebookEmail(response.data.id);
        }

        // Do whatever you need with the email
      })
      .catch((error) => {
        // Handle errors
        console.error(
          "Error retrieving Facebook details:",
          error.response.data
        );
      });
  };

  const updateWalletDatas = async (signature: any) => {
    setIsWalletLink(false);
    if (address) {
      const sessionData = localStorage.getItem("session");
      if (sessionData !== null) {
        const session = JSON.parse(sessionData);

        const useSSL = window.location.protocol === "https:";
        const client = new Client(
          useSSL ? process.env.REACT_APP_CLIENT_KEY! : process.env.REACT_APP_DEV_CLIENT_KEY!,
          useSSL ? process.env.REACT_APP_API_HOST! : process.env.REACT_APP_DEV_API_HOST!,
          useSSL ? process.env.REACT_APP_API_PORT! : process.env.REACT_APP_DEV_API_PORT!,
          useSSL
        );
        setClient(client);

        if (session) {
          let sessionCheck = Session.restore(session.token, session.refresh_token);

          const payload = { wallet: address, signature: signature };

          try {
            const walletStore = await client.rpc(sessionCheck, "updateWallet", payload);

            //@ts-ignore
            const response = JSON.parse(walletStore.payload);

            if (response === true) {
              toast(
                <p style={{ fontSize: 16 }}>Wallet address already exists. Use Another.</p>,
                {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: false,
                  rtl: false,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  pauseOnHover: true,
                  type: "error",
                }
              );
            } else {
              setWalletAddress(address);

              toast(
                <p style={{ fontSize: 16 }}>Successfully Bind Your Wallet</p>,
                {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: false,
                  rtl: false,
                  pauseOnFocusLoss: false,
                  draggable: true,
                  pauseOnHover: true,
                  type: "success",
                }
              );
            }
          } catch (error) {
            toast(
              <p style={{ fontSize: 16 }}>An error occurred while binding your wallet.</p>,
              {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                rtl: false,
                pauseOnFocusLoss: false,
                draggable: true,
                pauseOnHover: true,
                type: "error",
              }
            );
          }
        }

      }
    }
  };

  const onSubmit = async () => {
    const email = JSON.parse(profileList.metadata)?.email;

    let error = "";

    if (showPasswordInputs === true) {
      if (confirmPassword === password) {
        // const sessionData = localStorage.getItem("session");

        // if (sessionData !== null) {
        const session = await client.authenticateCustom("adminTerraquest");

        if (session) {
          let sessionCheck = Session.restore(
            session.token,
            session.refresh_token
          );
          const currentTimeInSec = new Date().getTime() / 1000; // Corrected line

          // check if email(input from user) existed in database or not


          const dataHashPass = await hashPassword(currentPassword, bcrypt);


          const payload = { email: email, hashKey: dataHashPass };
          const ResetPasswordData = await client.rpc(
            sessionCheck,
            "ResetPassword",
            payload
          );


        } else {
          // await thiscreateSession();
        }
      } else {
        alert("password dont match");
      }
    }
  };
  const openDrawerMenuSlide = useCallback(() => {
    setDrawerMenuSlideOpen(!isDrawerMenuSlideOpen);
  }, []);

  const closeDrawerMenuSlide = useCallback(() => {
    setDrawerMenuSlideOpen(false);
  }, []);

  const { signMessageAsync } = useSignMessage();

  const handleSignMessage = async () => {
    try {
      const signature = await signMessageAsync({ message: "yo!" });
      updateWalletDatas(signature);

      // Assuming signature is a string
      const signer = ethers.utils.verifyMessage(
        "Please sign to prove that it is you!",
        signature
      );
    } catch (error) {
      toast(
        <p style={{ fontSize: 16 }}>
          Please Connect your wallet first before Binding
        </p>,
        {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          rtl: false,
          pauseOnFocusLoss: false,
          draggable: true,
          pauseOnHover: true,
          type: "error",
        }
      );
    }
  };
  return (
    <>
      <div className="w-full h-screen relative bg-whitesmoke-100 flex flex-col items-start justify-start text-left text-5xs text-royalblue font-dm-sans lg:h-screen md:w-auto md:[align-self:unset] sm:w-auto sm:[align-self:unset] sm:pl-0 sm:box-border">
        <div className="self-stretch flex-1 flex flex-row items-start justify-start gap-[16px] lg:flex-1 md:self-stretch md:w-auto md:flex-1 md:gap-[0px] md:items-start md:justify-center sm:self-stretch sm:w-auto sm:flex-1 sm:gap-[0px] sm:pl-0 sm:box-border">
          <ProfileSidebarComponent
            ready={ready}
            terraquestLogoBig1="/terraquestlogobig-1@2x.png"
            onDashboardClick={onDashboardClick}
            onProfileClick={onProfileClick}
            onInventoryClick={onInventoryClick}
            onMyNFTClick={onMyNFTClick}
            onLogoutClick={onLogoutClick}
            onInventoryPlacedBookmarkClick={onInventoryPlacedBookmarkClick}
            onInventoryMemoryFragmentClick={onInventoryMemoryFragmentClick}
            onInventoryCostumesClick={onInventoryCostumesClick}
            onInventoryAllItemClick={onInventoryAllItemClick}
            onCatalogClick={onCatalogClick}
          />
          <div className="self-stretch flex-1 flex flex-col items-center justify-between lg:h-auto md:flex-1 md:gap-[16px] sm:flex-1 sm:gap-[8px] sm:items-center sm:justify-between sm:pl-0 sm:box-border">
            <div className="w-[944px] bg-colors-white-100 shadow-[0px_3.5px_5.5px_rgba(0,_0,_0,_0.02)] hidden flex-row items-center justify-between py-2 px-4 box-border lg:hidden md:flex md:self-stretch md:w-auto md:pl-4 md:pr-4 md:box-border sm:self-stretch sm:w-auto sm:pl-4 sm:box-border">
              <img
                className="w-[92.8px] relative h-14 object-cover"
                alt=""
                src="/terraquestlogobig-2@2x.png"
              />
              <img
                className="w-12 relative h-12 overflow-hidden shrink-0 cursor-pointer"
                alt=""
                src="/iconamoonmenuburgerhorizontalbold.svg"
                onClick={openDrawerMenuSlide}
              />
            </div>
            <main className="self-stretch flex flex-col items-center justify-start py-0 px-4 gap-[8px] text-left text-sm text-dimgray-200  lg:h-auto md:self-stretch md:w-auto md:flex-1 md:gap-[16px] md:p-10 md:box-border sm:self-stretch sm:w-auto sm:h-auto sm:gap-[8px] md:pl-0 md:pr-0 sm:box-border">
              <Header ready={!ready} />
              {/* Call the tutorial sidebar */}
              <TutorialSidebar
                setToggleTutorial={setToggleTutorial}
                toggleTutorial={toggleTutorial}
                items={tutorialProfile}
              />
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="w-full maxContainer font-dm-sans"
              >
                <div className="self-stretch rounded-2xl bg-colors-white-100 flex flex-col items-start justify-start p-4 lg:h-auto md:self-stretch md:w-auto md:flex-1 md:items-center md:justify-start md:p-4 md:box-border sm:self-stretch sm:w-auto sm:items-center sm:justify-center sm:p-2 sm:box-border">
                  <div className="self-stretch rounded-2xl flex flex-col items-start justify-start py-2 px-4 border-[2px] border-dashed border-whitesmoke-300 md:self-stretch md:w-auto md:flex-1 sm:self-stretch sm:w-auto sm:gap-[0px] sm:p-4 sm:box-border">
                    <div className="h-8 flex flex-row items-center justify-start gap-[8px] sm:pl-2 sm:box-border">
                      <h1
                        className={`m-0 h-[17px] relative text-inherit leading-[120%] uppercase font-bold font-inherit inline-block md:text-base sm:text-sm
                        `}
                        id="Dashboard"
                      >
                        Profile
                      </h1>

                      <div className="group relative my-12 flex justify-center">
                        <CustomTooltip content="This is a tooltip">
                          <button
                            style={{ maxWidth: "300px", minWidth: "200px" }}
                          >
                            {" "}
                            Your account is shared between the TerraQuest game
                            and dashboard. If your details seem incorrect,
                            please try logging in again with another account.
                          </button>
                        </CustomTooltip>
                      </div>
                    </div>
                    <section className="self-stretch flex flex-col items-start justify-start gap-[8px] text-left text-sm text-dimgray-200 font-baloo-2">
                      <div className="w-[816px] hidden flex-col items-start justify-start gap-[8px] sm:gap-[0px]">
                        <div className="relative font-semibold">Username</div>
                        <input
                          className="[outline:none] font-dm-sans text-sm bg-colors-white-100 self-stretch rounded-lg flex flex-col items-start justify-start py-2 px-3 text-dimgray-100 border-[1px] border-solid border-silver-200 sm:h-10 sm:gap-[8px] sm:pt-2 sm:pb-2 sm:box-border"
                          placeholder={profileList?.username || ""}
                          value={profileList?.username}
                          type="text"
                          readOnly
                        />
                      </div>
                      <div className="self-stretch flex flex-col items-start justify-start gap-[8px] sm:gap-[0px]">
                        <div
                          className={`relative font-semibold flex 
                        ${!ready &&
                            "bg-slate-200 text-transparent animate-pulse rounded-lg h-4"
                            }
                        `}
                        >
                          Username &nbsp;
                          <CustomTooltip content="This is a tooltip">
                            <button
                              style={{ maxWidth: "300px", minWidth: "200px" }}
                            >
                              {" "}
                              This is the username you chose during character
                              creation in the game. Otherwise, a username will
                              be randomly generated for you.
                            </button>
                          </CustomTooltip>
                        </div>
                        <input
                          className={`changeInputWidth w-[97%] [outline:none] font-dm-sans text-sm bg-colors-white-100 self-stretch rounded-lg flex flex-col items-start justify-start py-2 px-3 text-dimgray-100 border-[1px] border-solid border-silver sm:h-10 sm:gap-[8px] sm:pt-2 sm:pb-2 sm:box-border mq350small:pt-1 mq350small:pb-1 mq350small:box-border
                          ${!ready
                              ? "bg-slate-200 text-transparent animate-pulse rounded-lg !h-5 border-none"
                              : ""
                            }
                          `}
                          placeholder={
                            // (profileList?.metadata &&
                            //   JSON.parse(profileList.metadata)?.email) ||
                            // (ready && "Lorem Ipsum")
                            profileList?.username || (ready && "")
                          }
                          value={profileList?.username}
                          readOnly
                          type="text"
                        />
                      </div>

                      <div
                        className={`self-stretch flex flex-col items-start justify-start gap-[8px] sm:gap-[0px]                     
                      `}
                      >

                        <button
                          className={`bg-dimgray-200 cursor-pointer [border:none] py-0 px-16 bg-dimgray rounded-lg h-8 flex flex-row items-center justify-end box-border sm:h-8 sm:rounded sm:pt-0 sm:box-border
                            ${!ready &&
                            "bg-slate-200 text-transparent animate-pulse rounded-lg h-12"
                            }
                            `}
                          onClick={handleAddPasswordClick}
                        >
                          {ready && (
                            <p className="font-medium relative text-sm tracking-[0.02em] leading-[100%] font-dm-sans text-colors-white-100 text-left sm:text-xs">
                              Change Password
                            </p>
                          )}
                        </button>
                      </div>

                      <div
                        className={`flex flex-col items-start justify-start gap-[8px] sm:gap-[0px]
                      
                      `}
                      >
                        <div
                          className={`relative font-semibold
                        ${!ready
                              ? "bg-slate-200 text-transparent animate-pulse rounded-lg !h-4"
                              : ""
                            }
                        `}
                        >
                          Linked Wallet
                        </div>
                        <div className="flex flex-row items-start justify-start gap-[8px] text-colors-white-100 font-dm-sans">
                          {!isWalletLink ? (
                            <div
                              className={`
                              !text-white
                            bg-dimgray-200 rounded-lg bg-dimgray h-8 flex flex-row items-center justify-end py-0 px-16 box-border sm:h-8 sm:rounded-lg sm:pl-4 sm:pr-4 sm:box-border
                            ${!ready &&
                                "bg-slate-200 text-transparent animate-pulse rounded-lg h-12"
                                }
                            `}
                            >
                              <div className="!text-white">
                                {formatEthereumAddress(walletAddress)}
                              </div>
                            </div>
                          ) : (
                            <button
                              className={`cursor-pointer [border:none] py-0 px-16 bg-[#2F6DD5] rounded-lg h-8 flex flex-row items-center justify-end box-border sm:h-8 sm:rounded sm:pt-0 sm:box-border
                       ${!ready &&
                                "bg-slate-200 text-transparent animate-pulse rounded-lg h-12"
                                }
                      `}
                              onClick={handleSignMessage}
                              type="button"
                            >
                              {ready && (
                                <p className="font-medium relative text-sm tracking-[0.02em] leading-[100%] font-dm-sans text-colors-white-100 text-left sm:text-xs">
                                  Bind Web3 Wallet
                                </p>
                              )}
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col items-start justify-start gap-[2px] sm:gap-[4px] w-full">
                        <div
                          className={`relative font-semibold
                        ${!ready
                              ? "bg-slate-200 text-transparent animate-pulse rounded-lg !h-4"
                              : ""
                            }
                        `}
                        >
                          Social Media
                        </div>
                        {/* {JSON.stringify(profileList)}
ss */}
                        {(!profileList?.facebook_id || !profileList?.google_id) && (
                          <SocialMediaLoginContainer />
                        )}


                        {profileList?.facebook_id && (
                          <>
                            <div className="flex flex-column w-full">
                              <div className="flex flex-row gap-5 w-full">
                                <img src="/facebookProfile.svg" />
                                <div className="w-full pr-10">
                                  <input
                                    className={`changeInputWidth !w-[100%] [outline:none] font-dm-sans text-sm bg-colors-white-100 self-stretch rounded-lg flex flex-col items-start justify-start py-2 px-3 text-dimgray-100 border-[1px] border-solid border-silver sm:h-10 sm:gap-[8px] sm:pt-2 sm:pb-2 sm:box-border mq350small:pt-1 mq350small:pb-1 mq350small:box-border
                                ${!ready
                                        ? "bg-slate-200 text-transparent animate-pulse rounded-lg !h-5 border-none"
                                        : ""
                                      }
                                `}
                                    placeholder={facebookEmail || ""}
                                    value={facebookEmail}
                                    type="text"
                                    readOnly
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {profileList?.google_id && (
                          <>
                            <div className="flex flex-column w-full">
                              <div className="flex flex-row gap-5 w-full">
                                <img src="/googleProfile.svg" />
                                <div className="w-full pr-10">
                                  <input
                                    className={`changeInputWidth !w-[100%] [outline:none] font-dm-sans text-sm bg-colors-white-100 self-stretch rounded-lg flex flex-col items-start justify-start py-2 px-3 text-dimgray-100 border-[1px] border-solid border-silver sm:h-10 sm:gap-[8px] sm:pt-2 sm:pb-2 sm:box-border mq350small:pt-1 mq350small:pb-1 mq350small:box-border
                                ${!ready
                                        ? "bg-slate-200 text-transparent animate-pulse rounded-lg !h-5 border-none"
                                        : ""
                                      }
                                `}
                                    placeholder={
                                      (profileList?.metadata &&
                                        JSON.parse(profileList.metadata)
                                          ?.email) ||
                                      (ready && "Lorem Ipsum")
                                    }
                                    value={
                                      JSON.parse(profileList.metadata)?.email
                                    }
                                    type="text"
                                    readOnly
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </section>
                  </div>
                </div>
              </form>
            </main>
            <ToastContainer />
            <TermsCondition
              privacyPolicyFontWeight="bold"
              termsAndServicesFontWeight="bold"
              copyright2023FontWeight="bold"
            />
            <div
              className="w-[944px] bg-colors-white-100 h-20 hidden flex-row items-center justify-between py-2 px-6 box-border md:flex md:self-stretch md:w-auto sm:flex sm:gap-[8px] sm:pl-6 sm:pt-2 sm:pb-4 sm:box-border
             z-[9999999] fixed bottom-0 sm:w-full"
            >
              <div
                className="flex flex-col items-center justify-start !text-[#2F6DD5]"
                onClick={onDashboardClick}
              >
                <img
                  className="w-8 relative rounded h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/bxsdashboard13.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Dashboard
                </b>
              </div>
              <div
                className="flex flex-col items-center justify-start"
                onClick={onInventoryPlacedBookmarkClick}
              >
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/Inventory_icon.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Inventory
                </b>
              </div>
              <div className="hidden flex-col items-center justify-start">
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/Inventory_icon.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Logout
                </b>
              </div>
              <div
                className="flex flex-col items-center justify-start"
                onClick={onProfileClick}
              >
                <div className="w-8 relative rounded h-7 overflow-hidden shrink-0 mix-blend-normal">
                  <img
                    className="absolute top-[calc(20%_-_8px)] left-[calc(20%_-_8px)] w-8 h-8"
                    alt=""
                    src="/Profile_icon.svg"
                  />
                </div>
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Profile
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {isDrawerMenuSlideOpen && (
        <PortalDrawer onOutsideClick={closeDrawerMenuSlide}>
          <DrawerMenuSlide onClose={closeDrawerMenuSlide} />
        </PortalDrawer>
      )}
    </>
  );
}
