import React, { useEffect, useState } from 'react';
import { clientData } from '../hooks/client';
import { ethers } from 'ethers';
import { parseEventLogs } from 'viem';
import { abi } from '../contract/contractABI'; // Ensure the correct path to your ABI file

const Test = () => {
  const [eventLogs, setEventLogs] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [status, setStatus] = useState(null); // State to hold the transaction status

  useEffect(() => {
    const getTransactionReceipt = async (clientData, { hash }) => {
      try {
        const provider = new ethers.providers.JsonRpcProvider(clientData.transport.url); // Use ethers provider with the client transport URL
        const receipt = await provider.getTransactionReceipt(hash);
        return receipt;
      } catch (error) {
        console.error('Error fetching transaction receipt:', error);
        return null;
      }
    };

    const initialize = async () => {
      setFetching(true); // Set fetching to true when we start fetching

      try {
        const receipt = await getTransactionReceipt(clientData, {
          hash: '0x774fae7ea7268f9d395ea5c6d3a228feaa33436d435f6341fcc35e00362ff834',
        });

        if (receipt) {
          setStatus(receipt.status === 1 ? 'Success' : 'Failure'); // Set the transaction status

          if (receipt.logs) {
            const logs = parseEventLogs({
              abi: abi,
              eventName: 'Transfer',
              logs: receipt.logs,
            });

            setEventLogs(logs);
          } else {
            console.error('No logs found in the transaction receipt.');
          }
        } else {
          console.error('No transaction receipt found.');
        }

      } catch (error) {
        console.error('Error initializing contract event listener:', error);
      } finally {
        setFetching(false); // Ensure fetching is set to false once we're done
      }
    };

    initialize();
  }, []);

  // Custom replacer function to handle BigInt serialization
  const stringifyWithBigInt = (obj) => {
    return JSON.stringify(obj, (key, value) =>
      typeof value === 'bigint' ? value.toString() : value
    );
  };

  return (
    <div>
      <h2>Event Logs</h2>
      {fetching ? (
        <p>Loading...</p>
      ) : (
        <>
          <p>Transaction Status: {status}</p> {/* Display the transaction status */}
          <ul>
            {eventLogs.map((log, index) => (
              <li key={index}>{stringifyWithBigInt(log)}</li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default Test;
