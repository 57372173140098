
// clientUtils.ts
//     export async function startTransaction(sessionRefresh: any, client: any, payload: any): Promise<any> {
//     // return await client.rpc(sessionRefresh, "NftMintItem", payload)
//     return await client.rpc(sessionRefresh, "NftMintItem", payload)
//     // return checkEmailAvailability;

// }

export async function startTransaction(sessionRefresh: any, client: any, payload: any, timeout = 30000): Promise<any> {
    return Promise.race([
      client.rpc(sessionRefresh, "NftMintItem", payload),
      new Promise((_, reject) =>
        setTimeout(() => reject(new Error('Request timed out')), timeout)
      )
    ]);
  }

  export async function burnTransaction(sessionRefresh: any, client: any, payload: any, timeout = 30000): Promise<any> {
    return Promise.race([
      client.rpc(sessionRefresh, "NftBurnItem", payload),
      new Promise((_, reject) =>
        setTimeout(() => reject(new Error('Request timed out')), timeout)
      )
    ]);
  }
  

export async function mintEvent(writeContract:any, abi:any): Promise<any> {
    return  await writeContract({
        address: '0xFBA3912Ca04dd458c843e2EE08967fC04f3579c2',
        abi: abi,
        functionName: 'mint',
        args: ['0xFBA3912Ca04dd458c843e2EE08967fC04f3579c2'],
      });
}
export async function burnEvent(writeContract: any, abi: any, tokenId:any): Promise<any> {
    return await writeContract({
        address: '0xb7A9EA6b7D355d91287088d9833A93f648686065',
        abi: abi,
        functionName: 'burn', // Assuming 'burn' is the function name to burn tokens
        args: [tokenId] // Example: burning 100 tokens; adjust the argument as per your contract's requirements
    });
}

