import { FunctionComponent, useState, useEffect } from "react";
import Property1Normal1 from "./Property1Normal1";
import Property1Normal from "./Property1Normal";
import { Client, Session } from "@heroiclabs/nakama-js";
import { checkSessionExpiry } from "../api/auth";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import { ConnectButton as RainbowKitConnectButton } from "@rainbow-me/rainbowkit";
import { Link } from "react-router-dom";

const sessionData = localStorage.getItem("session");

type NavigationSidebarType = {
  ready?: boolean;

  /** Action props */
  onDashboardClick?: () => void;
};

function formatEthereumAddress(address: any) {
  if (address && address.length >= 10) {
    const prefix = address.substring(0, 6);
    const suffix = address.substring(address.length - 4);
    return `${prefix}...${suffix}`;
  }
  return address;
}

const Header: FunctionComponent<NavigationSidebarType> = ({ ready }) => {
  const { address, isConnected } = useAccount();

  useEffect(() => {}, []); // Dependencies for the useEffect

  useEffect(() => {
    // if (isConnected && address) {
    //   setWalletAddress(address);
    //   updateWalletDatas(address);
    // }
  }, [isConnected, address]);

  return (
    <section className="self-stretch flex flex-row items-center justify-end py-4 px-0 gap-[8px] md:hidden">
      <Link
        to="https://discord.com/invite/terraquest-official"
        target="__blank"
      >
        <img
          className="flex-1 relative rounded-2xl max-w-8 overflow-hidden max-h-8"
          alt=""
          src="social/Discord.svg"
        />
      </Link>

      <Link to="https://twitter.com/TerraQuest_" target="__blank">
        <img
          className="flex-1 relative rounded-2xl max-w-8 overflow-hidden max-h-8"
          alt=""
          src="social/X.svg"
        />
      </Link>
      <img
        className="flex-1 relative rounded-2xl max-w-full overflow-hidden max-h-full"
        alt=""
        src="/sparator.svg"
      />
      <Property1Normal1
        phplayFill="/phplayfill.svg"
        x923749237493="Play Game"
        property1NormalHeight="32px"
        property1NormalBorder="none"
        property1NormalWidth="unset"
        x923749237493Display="inline-block"
        x923749237493FontFamily="Hind"
        x923749237493FontWeight="unset"
      />

      <>
        {sessionData && (
          <RainbowKitConnectButton />

          //   <>
          //     {walletAddress ? (
          //       <div
          //         className={`
          //                     bg-dimgray-200 rounded-lg bg-dimgray h-10 flex flex-row items-center justify-end py-0 px-10 box-border sm:h-8 sm:rounded-lg sm:pl-2 sm:pr-2 sm:box-border
          //                     text-white
          //                     `}
          //       >
          //         <div>{formatEthereumAddress(walletAddress)}</div>
          //       </div>
          //     ) : (
          //       <RainbowKitConnectButton />
          //     )}
          //   </>
          // ) : (
          //   <Property1Normal
          //     x923749237493="Login"
          //     property1NormalHeight="32px"
          //     property1NormalBorder="none"
          //     x923749237493Display="inline-block"
          //     x923749237493FontFamily="Hind"
          //     x923749237493FontWeight="unset"
          //   />
        )}
      </>
    </section>
  );
};

export default Header;
