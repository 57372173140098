import { useState, useCallback, useEffect } from "react";
import "react-placeholder/lib/reactPlaceholder.css";
import { Client, Session } from "@heroiclabs/nakama-js";
import { useNavigate } from "react-router-dom";
import InventoryAllItemSidebarCom from "../components/InventoryAllItemSidebarCom";
import useNavigation from "../api/route";
import { checkSessionExpiry, reAuthenticate } from "../api/auth";
import TutorialSidebar from "../components/Tutorial";

import {
  getS3Images,
  getCostumeSet,
  getMyInventoryItems,
  getAllItemsSet,
} from "../api/storage";

import DrawerMenuSlide from "../components/DrawerMenuSlide";
import PortalDrawer from "../components/PortalDrawer";
import Header from "../components/Header";
import TermsCondition from "../components/TermsCondition";

import AWS from "aws-sdk";
import { useMediaQuery } from "react-responsive"; // Import from the library
import InventoryCardAlItem from "../components/InventoryCardAlItem";

export default function InGameCostumes(props: any) {
  const [isDrawerMenuSlideOpen, setDrawerMenuSlideOpen] = useState(false);
  const [tutorialInventory, setTutorialInventory] = useState<any[]>([]);

  const [ready, setReady] = useState<boolean>(false);

  const [client, setClient] = useState<any | null>(null);

  // const [allItems, setallItems] = useState<any | null>(null);
  const [images, setImages] = useState<any[]>([]); // Ensure the initial state matches the expected type
  const [profileData, setProfileData] = useState<any | null>(null); // Ensure the initial state matches the expected type

  const isMobile = useMediaQuery({ maxWidth: 768 }); // Adjust the breakpoint as needed
  const [toggleTutorial, setToggleTutorial] = useState<boolean>(false);

  // const [allItems, setallItems] = useState<any | null>(null);
  const [currentBackground, setCurrentBackground] = useState(
    // "/achievementflag1-2@2x.png"
    ""
  );
  const [currentItems, setCurrentItems] = useState<any | null>(null);
  const S3_BUCKET = "terraquest-items";
  const REGION = "ap-southeast-1";
  const ACCESS_KEY = "AKIA4QBGOHJV6EZIBMW3";
  const SECRET_ACCESS_KEY = "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";
  const s3 = new AWS.S3({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
    region: REGION,
  });

  const params1 = {
    Bucket: S3_BUCKET,
    Delimiter: "/",
    // Prefix: s3Folder + '/'
    Prefix: "/",
  };

  // check if facebook is link

  const onItemClick = (item: any) => {
    setCurrentItems(item);

    if (item && item.Key) {
      setCurrentBackground(
        `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${item?.Key}`
      );
    }
  };

  const {
    onDashboardClick,
    onProfileClick,
    onInventoryClick,
    onMyNFTClick,
    onLogoutClick,
    onInventoryMemoryFragmentClick,
    onInventoryPlacedBookmarkClick,
    onInventoryCostumesClick,
    onInventoryAllItemClick,
    onCatalogClick,
  } = useNavigation(); // Use the custom hook

  const navigate = useNavigate();
  async function getProfileDetails() {
    const useSSL = window.location.protocol === "https:";
    const client = new Client(
      useSSL ? process.env.REACT_APP_CLIENT_KEY! : process.env.REACT_APP_DEV_CLIENT_KEY!,
      useSSL ? process.env.REACT_APP_API_HOST! : process.env.REACT_APP_DEV_API_HOST!,
      useSSL ? process.env.REACT_APP_API_PORT! : process.env.REACT_APP_DEV_API_PORT!,
      useSSL
    );
    setClient(client);
    const sessionData = localStorage.getItem("session");

    if (sessionData !== null) {
      const session = JSON.parse(sessionData);

      if (session && session != "") {
        let sessionCheck = Session.restore(
          session.token,
          session.refresh_token
        );

        // Create a new Date object with the Unix timestamp in milliseconds
        //@ts-ignore
        const expiresAtDate = new Date(sessionCheck?.expires_at * 1000);

        const currentTime = new Date();

        if (currentTime >= expiresAtDate) {
          // Navigate to login or take appropriate action

          reAuthenticate(navigate);
        }
      }
    } else {
      // reAuthenticate(navigate);
    }
  }

  useEffect(() => {
    let isMounted = true; // Flag to check if component is mounted
    const fetchData = async () => {
      try {
        await Promise.all([getProfileDetails()]);

        // All API calls have completed successfully
        if (isMounted) {
          setReady(true); // Set ready state to true
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // reAuthenticate(navigate);
        // Handle error if any of the API calls fail
      }
    };

    fetchData(); // Call the function to fetch data
    const items = [
      {
        title: "inventory Item List",
        icon: "/phinfobold1.svg",
        content:
          "Click on <b>‘Change Password’</b>. Enter your old password, new password, and confirm your new password.",
      },
      // Add more items as needed
    ];
    setTutorialInventory(items);

    return () => {
      isMounted = false; // Set isMounted to false when component unmounts
    };
  }, []); // Dependencies for the useEffect

  const openDrawerMenuSlide = useCallback(() => {
    setDrawerMenuSlideOpen(!isDrawerMenuSlideOpen);
  }, []);

  const closeDrawerMenuSlide = useCallback(() => {
    setDrawerMenuSlideOpen(false);
  }, []);

  return (
    <>
      <div className="w-full h-screen relative bg-whitesmoke-100 flex flex-col items-start justify-start text-left text-5xs text-royalblue font-dm-sans lg:h-screen md:w-auto md:[align-self:unset] sm:w-auto sm:[align-self:unset] sm:pl-0 sm:box-border">
        <div className="self-stretch flex-1 flex flex-row items-start justify-start gap-[16px] lg:flex-1 md:self-stretch md:w-auto md:flex-1 md:gap-[0px] md:items-start md:justify-center sm:self-stretch sm:w-auto sm:flex-1 sm:gap-[0px] sm:pl-0 sm:box-border">
          <InventoryAllItemSidebarCom
            terraquestLogoBig1="/terraquestlogobig-1@2x.png"
            ready={ready}
            onDashboardClick={onDashboardClick}
            onProfileClick={onProfileClick}
            onInventoryClick={onInventoryClick}
            onMyNFTClick={onMyNFTClick}
            onLogoutClick={onLogoutClick}
            onInventoryMemoryFragmentClick={onInventoryMemoryFragmentClick}
            onInventoryPlacedBookmarkClick={onInventoryPlacedBookmarkClick}
            onInventoryCostumesClick={onInventoryCostumesClick}
            onInventoryAllItemClick={onInventoryAllItemClick}
            onCatalogClick={onCatalogClick}
          />
          <div className="self-stretch flex-1 flex flex-col items-center justify-between lg:h-auto md:flex-1 md:gap-[16px] sm:flex-1 sm:gap-[8px] sm:items-center sm:justify-between sm:pl-0 sm:box-border">
            <div className="w-[944px] bg-colors-white-100 shadow-[0px_3.5px_5.5px_rgba(0,_0,_0,_0.02)] hidden flex-row items-center justify-between py-2 px-4 box-border lg:hidden md:flex md:self-stretch md:w-auto md:pl-4 md:pr-4 md:box-border sm:self-stretch sm:w-auto sm:pl-4 sm:box-border">
              <img
                className="w-[92.8px] relative h-14 object-cover"
                alt=""
                src="/terraquestlogobig-2@2x.png"
              />
              <img
                className="w-12 relative h-12 overflow-hidden shrink-0 cursor-pointer"
                alt=""
                src="/iconamoonmenuburgerhorizontalbold.svg"
                onClick={openDrawerMenuSlide}
              />
            </div>
            <main
              className={`self-stretch flex flex-col items-center justify-start py-0 px-4 gap-[8px] lg:h-auto md:self-stretch md:w-auto md:h-auto md:gap-[16px] sm:w-full sm:[align-self:unset] sm:h-[84vh]  sm:gap-[8px] sm:pl-2 sm:pr-2 sm:box-border 
            ${!toggleTutorial ? "w-full" : "w-[80%]"}
            `}
            >
              <Header ready={!ready} />
              {/* Call the tutorial sidebar */}
              {/* <TutorialSidebar
                setToggleTutorial={setToggleTutorial}
                toggleTutorial={toggleTutorial}
                items={tutorialInventory}
              /> */}
              <InventoryCardAlItem
                ready={ready}
                clientApi={props.clientApi}
                itemLabel="My Bookmarks"
                itemImageUrl="/mapicon-2@2x.png"
                inventoryItemImageUrl="/mapicon-2@2x.png"
                inventoryItemIconUrl="/mapicon-2@2x.png"
                inventoryItemThumbnailUrl="/mapicon-2@2x.png"
                inventoryItemIconUrl2="/mapicon-2@2x.png"
                onPrimaryButtonClick={""}
              />
            </main>
            <TermsCondition
              privacyPolicyFontWeight="bold"
              termsAndServicesFontWeight="bold"
              copyright2023FontWeight="bold"
            />
            <div
              className="w-[944px] bg-colors-white-100 h-20 hidden flex-row items-center justify-between py-2 px-6 box-border md:flex md:self-stretch md:w-auto sm:flex sm:gap-[8px] sm:pl-6 sm:pt-2 sm:pb-4 sm:box-border
             z-[9999999] fixed bottom-0 sm:w-full"
            >
              <div
                className="flex flex-col items-center justify-start !text-[#2F6DD5]"
                onClick={onDashboardClick}
              >
                <img
                  className="w-8 relative rounded h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/bxsdashboard13.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Dashboard
                </b>
              </div>
              <div
                className="flex flex-col items-center justify-start"
                onClick={onInventoryPlacedBookmarkClick}
              >
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/Inventory_icon.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Inventory
                </b>
              </div>
              <div className="hidden flex-col items-center justify-start">
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/Inventory_icon.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Logout
                </b>
              </div>
              <div
                className="flex flex-col items-center justify-start"
                onClick={onProfileClick}
              >
                <div className="w-8 relative rounded h-7 overflow-hidden shrink-0 mix-blend-normal">
                  <img
                    className="absolute top-[calc(20%_-_8px)] left-[calc(20%_-_8px)] w-8 h-8"
                    alt=""
                    src="/Profile_icon.svg"
                  />
                </div>
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Profile
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDrawerMenuSlideOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top"
          onOutsideClick={closeDrawerMenuSlide}
        >
          <DrawerMenuSlide onClose={closeDrawerMenuSlide} />
        </PortalDrawer>
      )}
    </>
  );
}
