import { useState, useEffect, FunctionComponent } from "react";

interface detailsData {
  image: any;
  data?: any;
  bg?: string;
  ready?: boolean;
}
const DetailsBookmarkDesktop: FunctionComponent<detailsData> = (props: any) => {
  return (
    <>
      <div className="rounded-lg bg-colors-white-100 shadow-[0px_3.5px_5.5px_rgba(0,_0,_0,_0.02)] flex flex-col items-start justify-start pt-0 px-0 pb-2 gap-[8px] text-base border-[1px] border-solid border-whitesmoke-200 md:flex sm:hidden">
        <div
          className={`w-[276px] rounded-t-lg rounded-b-none h-60 flex flex-col items-center justify-center  bg-cover bg-no-repeat bg-[top]
          bg-[url('/public/preview@3x.png')]
            `}
        >
          <img
            className="w-40 relative h-40 object-cover"
            alt=""
            src={props?.image || "/achievementflag1-2@2x.png"}
          />
        </div>
        <div className="self-stretch flex flex-col items-start justify-start py-2 px-4 gap-[6px] lg:pb-2 lg:box-border md:gap-[4px] md:pt-2 md:pb-2 md:box-border">
          <div className="self-stretch h-[35px] flex flex-row items-center justify-start gap-[8px]">
            <b
              className={`h-4 relative tracking-[0.02em] leading-[100%] inline-block lg:text-xl
                
                  ${
                    !props.ready
                      ? "bg-slate-200 text-transparent animate-pulse rounded-lg"
                      : ""
                  }
                  `}
            >
              {props.data?.title || "Bookmark#1"}
            </b>
            <img
              className="w-7 relative h-7 overflow-hidden shrink-0 hidden"
              alt=""
              src="/mdipencil.svg"
            />
          </div>
          <div className="flex flex-col items-start justify-start gap-[4px] text-xs">
            <div className="flex flex-row items-center justify-start gap-[11.65px]">
              <b
                className={`w-[75px] relative tracking-[0.02em] leading-[100%] inline-block shrink-0 lg:text-3xs
                  
                  `}
              >
                ID
              </b>
              <div className="flex flex-row items-center justify-start py-[4.661417484283447px] px-0 gap-[4.66px]">
                <div
                  className={`relative leading-[19.77px] uppercase font-medium lg:text-3xs
                    ${
                      !props.ready
                        ? "bg-slate-200 text-transparent animate-pulse rounded-lg"
                        : ""
                    }
                    `}
                >
                  0x238j23 .... 23kjn
                </div>
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src="/phcopybold.svg"
                />
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-[11.65px]">
              <b
                className={`w-[75px] relative tracking-[0.02em] leading-[100%] inline-block shrink-0 lg:text-3xs
                  `}
              >
                Expiry
              </b>
              <b
                className={`relative leading-[19.77px] lg:text-3xs
                  ${
                    !props.ready
                      ? "bg-slate-200 text-transparent animate-pulse rounded-lg"
                      : ""
                  }
                  `}
              >
                {props.data?.expiry || " 50 Days 12 hours 2 Minutes"}
              </b>
            </div>
            <div className="flex flex-row items-center justify-start gap-[11.65px]">
              <b
                className={`w-[75px] relative tracking-[0.02em] leading-[100%] inline-block shrink-0 lg:text-3xs
                  `}
              >
                Date Create
              </b>
              <div
                className={`relative leading-[19.77px] uppercase font-medium lg:text-3xs
                  ${
                    !props.ready
                      ? "bg-slate-200 text-transparent animate-pulse rounded-lg"
                      : ""
                  }
                  `}
              >
                {props.data?.date || " 11 / 12 / 2024"}
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-[11.65px]">
              <b
                className={`w-[75px] relative tracking-[0.02em] leading-[100%] inline-block shrink-0 lg:text-3xs`}
              >
                Owned by
              </b>
              <div
                className={`relative leading-[19.77px] uppercase font-medium lg:text-3xs
                  ${
                    !props.ready
                      ? "bg-slate-200 text-transparent animate-pulse rounded-lg"
                      : ""
                  }`}
              >
                {props.data?.owned || "ANDI HAW"}
              </div>
            </div>
            <div className="flex flex-row items-start justify-start gap-[11.65px]">
              <b
                className={`w-[75px] relative tracking-[0.02em] leading-[100%] inline-block shrink-0 lg:text-3xs
                  `}
              >
                Content
              </b>
              <div className="relative leading-[19.77px] font-medium lg:text-3xs">
                <ul className="m-0 font-inherit text-inherit pl-4">
                  <li
                    className={`mb-0 ${
                      !props.ready
                        ? "bg-slate-200 text-transparent animate-pulse rounded-lg mb-2"
                        : ""
                    }`}
                  >
                    Resources
                  </li>
                  <li
                    className={`mb-0
                      ${
                        !props.ready
                          ? "bg-slate-200 text-transparent animate-pulse rounded-lg mb-2"
                          : ""
                      }
                      `}
                  >
                    NPC
                  </li>
                  <li
                    className={`
                      ${
                        !props.ready
                          ? "bg-slate-200 text-transparent animate-pulse rounded-lg"
                          : ""
                      }
                      `}
                  >
                    Lorem ipsum
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsBookmarkDesktop;
