import { HTMLAttributes, useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface ProtectedRouteProps extends HTMLAttributes<HTMLDivElement> {
  children: any;
}

function AuthRoute({ children }: ProtectedRouteProps) {
  const isLoggedIn = localStorage.getItem("session");
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      return navigate("/dashboard");
    }
    // eslint-disable-next-line
  }, []);

  if (!isLoggedIn) {
    return children;
  }
}
export default AuthRoute;
