import { FunctionComponent, useMemo, type CSSProperties } from "react";
import { Link } from "react-router-dom";

type Property1Normal1Type = {
  phplayFill?: string;
  x923749237493?: string;

  /** Style props */
  property1NormalHeight?: CSSProperties["height"];
  property1NormalBorder?: CSSProperties["border"];
  property1NormalWidth?: CSSProperties["width"];
  x923749237493Display?: CSSProperties["display"];
  x923749237493FontFamily?: CSSProperties["fontFamily"];
  x923749237493FontWeight?: CSSProperties["fontWeight"];
  ready?: any;
};

const Property1Normal1: FunctionComponent<Property1Normal1Type> = ({
  phplayFill,
  x923749237493,
  property1NormalHeight,
  property1NormalBorder,
  property1NormalWidth,
  x923749237493Display,
  x923749237493FontFamily,
  x923749237493FontWeight,
  ready,
}) => {
  const property1Normal1Style: CSSProperties = useMemo(() => {
    return {
      height: "40px",
      borderRadius: "12px",
      width: property1NormalWidth,
    };
  }, [property1NormalHeight, property1NormalBorder, property1NormalWidth]);

  const x9237492374931Style: CSSProperties = useMemo(() => {
    return {
      display: x923749237493Display,
      fontFamily: x923749237493FontFamily,
      fontWeight: x923749237493FontWeight,
    };
  }, [x923749237493Display, x923749237493FontFamily, x923749237493FontWeight]);

  return (
    <a
      className="rounded-lg bg-royalblue h-10 flex flex-row items-center justify-center py-0 px-6 box-border gap-[8px] text-left text-sm text-colors-white-100 font-dm-sans"
      style={property1Normal1Style}
      href="https://terraquest.xyz/"
      target="__blank"
    >
      <img
        className="w-4 relative h-4 overflow-hidden shrink-0"
        alt=""
        src={phplayFill}
      />
      <p
        className="font-medium relative tracking-[0.02em] leading-[100%]"
        style={x9237492374931Style}
      >
        {x923749237493}
      </p>
    </a>
  );
};

export default Property1Normal1;
