import { useState } from "react";

export default function SearchList({
  onSearch,
}: {
  onSearch: (searchInput: string) => void;
}) {
  const [searchInput, setSearchInput] = useState("");

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchInput(value);
    if (value.trim() === "") {
      // If input is empty, trigger search with an empty string or other indicator
      onSearch("showAllItems");
    } else {
      onSearch(value); // Trigger search with current input value
    }
  };

  return (
    <form>
      <div
        className="sm:flex items-center bg-white rounded-lg overflow-hidden px-2 py-0 justify-between 
      border-2 border-gray-300  h-14  text-sm focus:outline-none w-full
      
      mb-4"
      >
        <input
          className="text-base text-gray-400 flex-grow outline-none px-2"
          type="text"
          placeholder="Search Item here...."
          value={searchInput}
          onChange={handleSearchChange}
        />
        <div className="ms:flex items-center rounded-lg space-x-4 mx-auto py-1">
          <button
            type="button" // Use button type instead of submit
            className="bg-[#1676FD] text-white text-base rounded-lg px-10 py-2 font-thin"
            onClick={() => onSearch(searchInput)} // Trigger search on button click
          >
            Search
          </button>
        </div>
      </div>
    </form>
  );
}
