import { FunctionComponent, useMemo, type CSSProperties } from "react";

type TermsConditionType = {
  /** Style props */
  privacyPolicyFontWeight?: CSSProperties["fontWeight"];
  termsAndServicesFontWeight?: CSSProperties["fontWeight"];
  copyright2023FontWeight?: CSSProperties["fontWeight"];
};

const TermsCondition: FunctionComponent<TermsConditionType> = ({
  privacyPolicyFontWeight,
  termsAndServicesFontWeight,
  copyright2023FontWeight,
}) => {
  const privacyPolicyStyle: CSSProperties = useMemo(() => {
    return {
      fontWeight: privacyPolicyFontWeight,
    };
  }, [privacyPolicyFontWeight]);

  const termsAndServicesStyle: CSSProperties = useMemo(() => {
    return {
      fontWeight: termsAndServicesFontWeight,
    };
  }, [termsAndServicesFontWeight]);

  const copyright2023Style: CSSProperties = useMemo(() => {
    return {
      fontWeight: copyright2023FontWeight,
    };
  }, [copyright2023FontWeight]);

  return (
    <section className="self-stretch flex flex-row items-end justify-between py-4 px-6 text-left text-xs text-darkgray font-dm-sans md:hidden sm:hidden mq350small:hidden">
      <div className="h-[15.1px] flex flex-row items-center justify-start gap-[16px] sm:gap-[16px]">
        <a
          href="https://terraquest.xyz/policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          <b className="relative leading-[133.2%] sm:text-3xs">
            Privacy Policy
          </b>
        </a>
        <img
          className="w-0.5 relative max-w-full overflow-hidden h-[14.5px]"
          alt=""
          src="/sparator.svg"
        />
        <a
          href="https://terraquest.xyz/terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          <b className="relative leading-[133.2%] sm:text-3xs">
            Terms and Services
          </b>
        </a>
      </div>
      <b className="relative leading-[133.2%] sm:text-3xs sm:text-right sm:flex-1">
        Copyright © 2024 TerraQuest
      </b>
    </section>
  );
};

export default TermsCondition;
