import {
  FunctionComponent,
  useState,
  useMemo,
  type CSSProperties,
  useCallback,
} from "react";
import LoginRegisterFirst from "./LoginRegisterFirst";
import PortalPopup from "./PortalPopup";
import { useNavigate, useLocation, Link } from "react-router-dom";

type NavigationSidebarButtonsType = {
  itemCode?: string;
  itemImageCode?: string;

  catalogIcon?: string;
  /** Style props */
  loginBackgroundColor?: CSSProperties["backgroundColor"];
  loginOpacity?: CSSProperties["opacity"];
  bxsdashboardIconColor?: CSSProperties["color"];
  gitbookColor?: CSSProperties["color"];
  gitbookFontWeight?: CSSProperties["fontWeight"];
  catalogColor?: CSSProperties["color"];
  registerBackgroundColor?: CSSProperties["backgroundColor"];
  registerOpacity?: CSSProperties["opacity"];
  catalogFontWeight?: CSSProperties["fontWeight"];

  bxsdashboardIconColor1?: CSSProperties["color"];

  loginFontWeight?: CSSProperties["fontWeight"];
  registerFontWeight?: CSSProperties["fontWeight"];

  /** Action props */
  onDashboardClick?: () => void;
  onProfileClick?: () => void;
  ready?: any;
  itemLabel?: any;
};

const NavigationSidebar: FunctionComponent<NavigationSidebarButtonsType> = ({
  itemCode,
  itemImageCode,
  loginBackgroundColor,
  loginOpacity,
  bxsdashboardIconColor,
  registerBackgroundColor,
  registerOpacity,
  bxsdashboardIconColor1,
  onDashboardClick,
  catalogIcon,
  catalogColor,
  catalogFontWeight,
  gitbookColor,
  gitbookFontWeight,
  onProfileClick,
  loginFontWeight,
  registerFontWeight,
  ready,
}) => {
  const [isLoginRegisterFirstPopupOpen, setLoginRegisterFirstPopupOpen] =
    useState(false);
  const dashboard4Style: CSSProperties = useMemo(() => {
    return {
      backgroundColor: loginBackgroundColor,
      opacity: loginOpacity,
    };
  }, [loginBackgroundColor, loginOpacity]);

  const loginStyle: CSSProperties = useMemo(() => {
    return {
      color: bxsdashboardIconColor,
      fontWeight: loginFontWeight,
    };
  }, [bxsdashboardIconColor, loginFontWeight]);

  const catalogStyle: CSSProperties = useMemo(() => {
    return {
      color: catalogColor,
      fontWeight: catalogFontWeight,
    };
  }, [catalogColor, catalogFontWeight]);
  const gitbookStyle: CSSProperties = useMemo(() => {
    return {
      color: gitbookColor,
      fontWeight: gitbookFontWeight,
    };
  }, [gitbookColor, gitbookFontWeight]);
  const profileStyle: CSSProperties = useMemo(() => {
    return {
      backgroundColor: registerBackgroundColor,
      opacity: registerOpacity,
    };
  }, [registerBackgroundColor, registerOpacity]);

  const registerStyle: CSSProperties = useMemo(() => {
    return {
      color: bxsdashboardIconColor1,
      fontWeight: registerFontWeight,
    };
  }, [bxsdashboardIconColor1, registerFontWeight]);
  const location1 = useLocation();
  const navigate = useNavigate();

  const openLoginRegisterFirstPopup = useCallback(() => {
    // setLoginRegisterFirstPopupOpen(true);
    navigate("/");
  }, []);

  const closeLoginRegisterFirstPopup = useCallback(() => {
    // setLoginRegisterFirstPopupOpen(false);
  }, []);
  const onRegisterClick = useCallback(() => {
    // navigate("/register");
    location.href = "/register";
  }, [navigate]);
  const onCatalogClick = useCallback(() => {
    // navigate("/register");
    location.href = "/catalog";
  }, [navigate]);

  return (
    <>
      <nav
        className="sticky top-0 m-0 self-stretch w-60 rounded-tl-none rounded-tr-2xl rounded-br-2xl rounded-bl-none bg-colors-white-100 flex flex-col items-start justify-start p-4 box-border cursor-pointer md:hidden sm:hidden mq350small:hidden
        h-screen
        "
        onClick={openLoginRegisterFirstPopup}
      >
        <div className="flex flex-col items-start justify-start gap-[8px] h-full">
          <>
            <img
              className="w-52 relative h-24 object-cover"
              alt=""
              src="/logo208x96-1@2x.png"
            />
            <div className="w-52 h-24 hidden flex-col items-center justify-center">
              <img
                className="w-[133.1px] relative h-20 object-cover lg:w-[199px]"
                alt=""
                src="/terraquestlogobig-1@2x.png"
              />
            </div>
            <img
              className="self-stretch relative max-w-full overflow-hidden max-h-full opacity-[0.2]"
              alt=""
              src="/sparator.svg"
            />
          </>

          <div
            className="flex flex-col items-start justify-between p-2 gap-[16px] lg:gap-[16px] h-full"
            style={{ alignItems: "justify-between" }}
          >
            <div>
              <button
                className={`cursor-pointer [border:none] py-1 px-4 bg-[transparent] self-stretch rounded-lg flex flex-row items-center justify-start gap-[8px] opacity-[0.2] lg:pt-0.5 lg:pb-0.5 lg:box-border
              group
              `}
                onClick={onDashboardClick}
                style={dashboard4Style}
              >
                <div
                  className={`
              ${
                location1.pathname !== "/" &&
                "group-hover:bg-[#593F3F] group-hover:rounded-[4px]"
              }`}
                >
                  <img
                    className={`w-6 relative rounded h-6 overflow-hidden shrink-0 
                  ${
                    location1.pathname !== "/" &&
                    "group-hover:brightness-[1100]"
                  }`}
                    alt=""
                    src={itemCode}
                  />
                </div>

                <div
                  className={`w-[104px] relative text-xs tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-darkslategray text-left inline-block shrink-0 lg:text-xs
                ${location.pathname !== "/" && "group-hover:font-extrabold"}`}
                  style={loginStyle}
                >
                  Login
                </div>
              </button>
              <button
                className={`cursor-pointer [border:none] py-1 px-4 bg-whitesmoke-100 self-stretch rounded-lg flex flex-row items-center justify-start gap-[8px] lg:pt-0.5 lg:pb-0.5 lg:box-border group`}
                onClick={onRegisterClick}
                style={profileStyle}
              >
                <div
                  className={`
              ${
                location1.pathname !== "/register" &&
                "group-hover:bg-[#593F3F] group-hover:rounded-[4px]"
              }`}
                >
                  <img
                    className={`w-6 relative rounded h-6 overflow-hidden shrink-0 
                  ${
                    location1.pathname !== "/register" &&
                    "group-hover:brightness-[1100]"
                  }`}
                    alt=""
                    src={itemImageCode}
                  />
                </div>
                <div
                  className={`w-[104px] relative text-xs tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-dimgray-200 text-left inline-block shrink-0 lg:text-xs
               ${
                 location.pathname !== "/register" &&
                 "group-hover:font-extrabold"
               }
                
                `}
                  style={registerStyle}
                >
                  Register
                </div>
              </button>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start p-2 gap-[8px] lg:gap-[8px]">
              <button
                className={`group cursor-pointer [border:none] py-1 px-4 bg-[transparent] self-stretch rounded-lg flex flex-row items-center justify-start gap-[8px]  lg:pt-0.5 lg:pb-0.5 lg:box-border
`}
                onClick={onCatalogClick}
              >
                <div
                  className={`
              ${
                location.pathname !== "/catalog" &&
                "group-hover:bg-[#593F3F] group-hover:rounded-[4px]"
              }`}
                >
                  <img
                    className={`w-6 relative rounded h-6 overflow-hidden shrink-0
                ${
                  location.pathname !== "/catalog" &&
                  "group-hover:brightness-[1100]"
                }`}
                    alt=""
                    src={catalogIcon}
                  />
                </div>

                <div
                  className={`w-[104px] relative text-xs tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-darkslategray-100 text-left inline-block shrink-0 lg:text-xs
              ${
                location.pathname !== "/catalog" && "group-hover:font-extrabold"
              }`}
                  style={catalogStyle}
                >
                  Catalog
                </div>
              </button>

              {/* GitBook */}
              <Link
                className={`group cursor-pointer [border:none] py-1 px-4 bg-[transparent] self-stretch rounded-lg flex flex-row items-center justify-start gap-[8px]  lg:pt-0.5 lg:pb-0.5 lg:box-border
          
`}
                to="https://docs.terraquest.xyz/terraquest-litepaper/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className={`
              ${
                location.pathname !== "/gitbook" &&
                "group-hover:bg-[#593F3F] group-hover:rounded-[4px]"
              }`}
                >
                  <img
                    className={`w-6 relative rounded h-6 overflow-hidden shrink-0
                ${
                  location.pathname !== "/gitbook" &&
                  "group-hover:brightness-[1100]"
                }`}
                    alt=""
                    src="/gitbook.svg"
                  />
                </div>

                <div
                  className={`
            !text-[#593F3F]
            w-[104px] relative text-xs tracking-[0.02em] leading-[100%] font-medium font-dm-sans  text-left inline-block shrink-0 lg:text-xs
              ${
                location.pathname !== "/catalog" && "group-hover:font-extrabold"
              }`}
                  style={gitbookStyle}
                >
                  Gitbook
                </div>
              </Link>
              {/* end Gitbook */}
              {/* GitBook */}
              <Link
                className={`group cursor-pointer [border:none] py-1 px-4 bg-[transparent] self-stretch rounded-lg flex flex-row items-center justify-start gap-[8px]  lg:pt-0.5 lg:pb-0.5 lg:box-border
`}
                to="https://blog.terraquest.xyz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  className={`
              ${
                location.pathname !== "/gitbook" &&
                "group-hover:bg-[#593F3F] group-hover:rounded-[4px]"
              }`}
                >
                  <img
                    className={`w-6 relative rounded h-6 overflow-hidden shrink-0
                ${
                  location.pathname !== "/gitbook" &&
                  "group-hover:brightness-[1100]"
                }`}
                    alt=""
                    src="/blog.svg"
                  />
                </div>

                <div
                  className={`
            !text-[#593F3F]
            w-[104px] relative text-xs tracking-[0.02em] leading-[100%] font-medium font-dm-sans  text-left inline-block shrink-0 lg:text-xs
              ${
                location.pathname !== "/catalog" && "group-hover:font-extrabold"
              }`}
                  style={gitbookStyle}
                >
                  Blog
                </div>
              </Link>
              {/* end Gitbook */}
            </div>

            <button className="cursor-pointer [border:none] py-1 px-4 bg-[transparent] rounded-lg hidden flex-row items-center justify-start gap-[8px] opacity-[0.2]">
              <img
                className="w-6 relative rounded h-6 overflow-hidden shrink-0"
                alt=""
                src="/bxsdashboard.svg"
              />
              <div className="w-[104px] relative text-xs tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-darkslategray text-left inline-block shrink-0 lg:text-base">
                My Bookmarks
              </div>
              <div className="w-4 relative h-4 overflow-hidden shrink-0" />
            </button>
            <button className="cursor-pointer [border:none] py-1 px-4 bg-[transparent] rounded-lg hidden flex-row items-center justify-start gap-[8px] opacity-[0.2]">
              <img
                className="w-6 relative rounded h-6 overflow-hidden shrink-0"
                alt=""
                src="/bxsdashboard.svg"
              />
              <div className="w-[104px] relative text-xs tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-darkslategray text-left inline-block shrink-0 lg:text-base">
                Memory Fragments
              </div>
            </button>
            <button className="cursor-pointer [border:none] py-1 px-4 bg-[transparent] rounded-lg hidden flex-row items-center justify-start gap-[8px] opacity-[0.2]">
              <img
                className="w-6 relative rounded h-6 overflow-hidden shrink-0"
                alt=""
                src="/bxsdashboard.svg"
              />
              <div className="w-[104px] relative text-xs tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-darkslategray text-left inline-block shrink-0 lg:text-base">
                Attires
              </div>
            </button>
            <button className="cursor-pointer [border:none] py-1 px-4 bg-[transparent] rounded-lg hidden flex-row items-center justify-start gap-[8px] opacity-[0.2]">
              <img
                className="w-6 relative rounded h-6 overflow-hidden shrink-0"
                alt=""
                src="/bxsdashboard.svg"
              />
              <div className="w-[104px] relative text-xs tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-darkslategray text-left inline-block shrink-0 lg:text-base">
                My Inventory
              </div>
            </button>
          </div>
        </div>
      </nav>

      {isLoginRegisterFirstPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeLoginRegisterFirstPopup}
        >
          <LoginRegisterFirst onClose={closeLoginRegisterFirstPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default NavigationSidebar;
