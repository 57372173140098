import { useState, useCallback, useEffect, CSSProperties } from "react";
// import toast, { Toaster } from "react-hot-toast";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import DrawerMenuSlide from "../components/DrawerMenuSlide";
import PortalDrawer from "../components/PortalDrawer";
import TermsCondition from "../components/TermsCondition";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useForm } from "react-hook-form";
import ResetPasswordContainer from "../components/ResetPasswordContainer";
import SocialMediaLoginContainer from "../components/SocialMediaLoginContainer";
import NavigationSidebar from "../components/NavigationSidebar";

import { authenticateUser } from "../api/auth";

type ApiAccountCustom = {
  id: string;
  // Add other properties if there are any
};
export default function LoginFlow(props: any) {
  const [isLoginRegisterFirstPopupOpen, setLoginRegisterFirstPopupOpen] =
    useState(false);
  const [isDrawerMenuSlideOpen, setDrawerMenuSlideOpen] = useState(false);

  const [isWalletMenuOpen, setWalletMenuOpen] = useState(false);

  const closeLoginRegisterFirstPopup = useCallback(() => {
    setLoginRegisterFirstPopupOpen(false);
  }, []);

  const openDrawerMenuSlide = useCallback(() => {
    setDrawerMenuSlideOpen(true);
  }, []);

  const navigate = useNavigate();
  const closeDrawerMenuSlide = useCallback(() => {
    setDrawerMenuSlideOpen(false);
  }, []);

  const closeWalletMenu = useCallback(() => {
    setWalletMenuOpen(false);
  }, []);
  const [ready, setReady] = useState<boolean>(true);

  const [errorMessage, setErrorMessage] = useState("");
  const [loadingData, setLoadingData] = useState(false);
  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  useEffect(() => {
    setTimeout(() => {
      setReady(false);
    }, 2000);
  }, [loadingData]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const notify = (message: any, types: any) =>
    toast(<p style={{ fontSize: 16 }}>{message}</p>, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      rtl: false,
      pauseOnFocusLoss: true,
      draggable: true,
      pauseOnHover: true,
      type: types,
    });

  const onSubmit = async (data: any) => {
    // toast.loading("Logging you in. 🚀🚀🚀.");
    toast(<p style={{ fontSize: 16 }}>Logging you in. 🚀🚀🚀.</p>, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      rtl: false,
      pauseOnFocusLoss: true,
      draggable: true,
      pauseOnHover: true,
      type: "info",
    });
    setLoadingData(true);
    setErrorMessage("");
    const email: string = data.email;
    const password: string = data.password;
    let latestErrorMessage = ""; // Capture the latest errorMessage

    try {
      const sessionCheckEmail = await props.clientApi.authenticateCustom(
        "adminTerraquest"
      );

      // check if email(input from user) existed in database or not
      const payload = { email: email };

      const checkEmailAvailability = await props.clientApi.rpc(
        sessionCheckEmail,
        "checkEmailAvailability",
        payload
      );

      if (checkEmailAvailability.payload === false) {
        latestErrorMessage =
          "The email didn't register in the system. Please Register";

        setTimeout(() => {
          setLoadingData(false);
          setErrorMessage("No account found. Please Register");
          notify(latestErrorMessage, "error");
          setTimeout(() => {
            setErrorMessage("");
          }, 5300);
        }, 5650);
      } else {
        const session = await authenticateUser(
          props.clientApi,
          email,
          password,
          errorMessage,
          setErrorMessage,
          setLoadingData,
          loadingData
        );

        setErrorMessage("");

        localStorage.setItem("authToken", session?.token);
        localStorage.setItem("refreshToken", session?.refresh_token);

        // if (session?.username) {
        //   let test: ApiAccountCustom = {
        //     id: session?.username,
        //   };

        if (session) {
          setTimeout(() => {
            setLoadingData(false);
            const errorMessage = "Logged In! Please Wait...";
            setErrorMessage(errorMessage);
            notify(errorMessage, "success");
            setTimeout(() => {
              setErrorMessage("");
              navigate(0);
              navigate("/dashboard"); // Navigate to "/dashboard"
            }, 2000);
          }, 5650);
        }
      }
    } catch (error) {
      // setLoadingData(false);
      //@ts-ignore
      if (error?.message === "EMAIL OR PASSWORD IS INCORRECT") {
        latestErrorMessage = errorMessage;

        setTimeout(() => {
          setLoadingData(false);
          const errorMessage = "Incorrect email or password. Please try again.";
          setErrorMessage(errorMessage);
          notify(errorMessage, "error");
          setTimeout(() => {
            setErrorMessage("");
          }, 5300);
        }, 5650);
      }

      // else {
      //   setLoadingData(false);
      //   const errorMessage =
      //     //@ts-ignore
      //     error?.message || "An unknown error occurred.";
      //   setErrorMessage(errorMessage);
      //   notify(errorMessage, "error");
      //   setTimeout(() => {
      //     setErrorMessage("");
      //   }, 5300);
      // }
    }
  };

  function onDashboardClick(): void {
    throw new Error("Function not implemented.");
  }

  return (
    <>
      <div className="w-full h-screen relative bg-whitesmoke-100  flex flex-col items-start justify-start text-left text-5xs text-royalblue font-dm-sans sm:w-auto sm:[align-self:unset] sm:h-screen">
        <div className="self-stretch flex-1 flex flex-row items-start justify-start gap-[16px] lg:flex-1 md:flex-1 sm:flex-1 sm:gap-[8px]">
          <NavigationSidebar
            ready={ready}
            itemCode="/login.svg"
            itemImageCode="/register.svg"
            catalogIcon="/Book_icon.svg"
            loginBackgroundColor="#f9f9fb"
            loginOpacity="unset"
            bxsdashboardIconColor="#2F6DD5"
            registerBackgroundColor="transparent"
            loginFontWeight="bold"
            registerOpacity="1"
            bxsdashboardIconColor1="#593F3F"
            onDashboardClick={onDashboardClick}
          />
          <div className="self-stretch flex-1 flex flex-col items-center justify-between lg:h-auto md:gap-[16px] sm:gap-[8px] sm:items-center sm:justify-between">
            <div className="w-[944px] bg-colors-white-100 shadow-[0px_3.5px_5.5px_rgba(0,_0,_0,_0.02)] hidden flex-row items-center justify-between py-2 px-4 box-border lg:hidden md:flex sm:self-stretch sm:w-auto mq350small:h-8">
              <img
                className="w-[92.8px] relative h-14 object-cover mq350small:h-8"
                alt=""
                src="/terraquestlogobig-2@2x.png"
              />
              <img
                className="w-12 relative h-12 overflow-hidden shrink-0 cursor-pointer mq350small:w-8 mq350small:h-8"
                alt=""
                src="/iconamoonmenuburgerhorizontalbold.svg"
                onClick={openDrawerMenuSlide}
              />
            </div>
            <main className="self-stretch flex flex-col items-center justify-start py-0 px-4 gap-[24px] text-left text-xs text-dimgray-200 font-dm-sans lg:h-auto md:h-auto md:gap-[16px] sm:w-auto sm:[align-self:unset] sm:h-auto sm:gap-[8px] sm:items-center sm:justify-start sm:pl-1 sm:pr-1 sm:box-border Ultrawide:items-center Ultrawide:justify-start">
              <Header ready={ready} />
              <div className="self-stretch rounded-2xl bg-colors-white-100 flex flex-col items-start justify-start p-6 lg:h-auto md:p-4 md:box-border sm:w-auto sm:[align-self:unset] sm:items-center sm:justify-start sm:p-4 sm:box-border Ultrawide:w-[1000px] Ultrawide:items-center Ultrawide:justify-center Ultrawide:self-center">
                <div className="self-stretch rounded-2xl flex flex-col items-start justify-start pt-6 px-6 pb-10 border-[2px] border-dashed border-whitesmoke-300 md:pl-4 md:box-border sm:gap-[0px] sm:items-center sm:justify-start sm:py-5 sm:px-0 sm:box-border">
                  <div className="self-stretch flex flex-row items-start justify-start gap-[8px] sm:items-start sm:justify-center">
                    <div className="flex-1 flex flex-col items-start justify-start py-0 pr-8 pl-0 gap-[32px] md:pr-0 md:box-border sm:flex-1 sm:items-center sm:justify-start sm:pl-0.5 sm:pr-0.5 sm:box-border mq350small:gap-[8px]">
                      <ResetPasswordContainer
                        ready={ready}
                        actionButtonText="Welcome to the TerraQuest Dashboard"
                        resetPasswordInstructions="Please sign in below"
                      />
                      <div className="self-stretch flex flex-col items-start justify-start py-0 px-4 gap-[16px] sm:gap-[24px] sm:items-center sm:justify-start mq350small:gap-[4px]">
                        <SocialMediaLoginContainer
                          socialMediaButtonText="LOGIN With Social Media"
                          propOpacity="unset"
                          propMixBlendMode="normal"
                          ready={ready}
                          clientApi={props.clientApi}
                        />
                        {/* <div
                          className={`
                        ${
                          ready
                            ? "bg-slate-200 text-transparent animate-pulse rounded-lg !h-4"
                            : ""
                        }
                        relative text-xs leading-[100%] font-medium font-dm-sans mix-blend-normal mq350small:text-3xs`}
                        >
                          Alternatively, you can use a different method:
                        </div> */}
                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          className="w-full"
                        >
                          <div className="self-stretch flex flex-col items-center justify-start gap-[16px] text-sm font-dm-sans sm:gap-[8px] sm:items-center sm:justify-start">
                            <div className="self-stretch flex flex-col items-start justify-start gap-[8px]  sm:items-start sm:justify-start">
                              <b
                                className={`relative leading-[120%] uppercase md:text-xs mq350small:text-xs
`}
                              >
                                Email
                              </b>
                              <div className="self-stretch flex flex-col items-center justify-start gap-[16px] text-right text-xs font-dm-sans">
                                <input
                                  className={`[outline:none] font-dm-sans text-sm bg-colors-white-100 self-stretch rounded-lg flex flex-col items-start justify-start py-2 px-3 text-dimgray-100 border-[1px] border-solid border-silver sm:h-10 sm:gap-[8px] sm:pt-2 sm:pb-2 sm:box-border mq350small:pt-1 mq350small:pb-1 mq350small:box-border

                              `}
                                  {...register("email", {
                                    required: true,
                                    pattern:
                                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                  })}
                                  placeholder={ready ? "" : ""}
                                  type="text"
                                />
                              </div>
                              <div className="self-stretch flex flex-col items-start justify-start gap-[8px] sm:gap-[0px] sm:items-start sm:justify-start">
                                <b
                                  className={`relative leading-[120%] uppercase md:text-xs mq350small:text-xs
`}
                                >
                                  Password
                                </b>
                                <div className="self-stretch flex flex-col items-center justify-start gap-[16px] text-right text-xs font-dm-sans">
                                  <div className="self-stretch flex flex-col items-center justify-start gap-[8px]">
                                    <input
                                      className={`[outline:none] bg-colors-white-100 self-stretch rounded-lg flex flex-col items-start justify-start py-2 px-3 font-dm-sans text-sm text-dimgray-100 border-[1px] border-solid border-silver sm:h-10 sm:gap-[8px] sm:pt-2 sm:pb-2 sm:box-border mq350small:pt-1 mq350small:pb-1 mq350small:box-border

                                  `}
                                      placeholder={ready ? "" : ""}
                                      type="password"
                                      {...register("password", {
                                        required: true,
                                        minLength: 8,
                                      })}
                                    />
                                    <Link
                                      to={"/reset-password"}
                                      className={`self-stretch relative leading-[100%] mq350small:text-3xs mb-2 !no-underline !text-[#593F3F] `}
                                    >
                                      <div>
                                        <span
                                          className={`font-medium
`}
                                        >{`Forgot `}</span>
                                        <b
                                          className={`text-royalblue
                                    `}
                                        >
                                          Password
                                        </b>
                                        <span
                                          className={`font-medium
`}
                                        >
                                          ?
                                        </span>
                                      </div>
                                    </Link>
                                  </div>
                                  {loadingData && (
                                    <img
                                      src="/loading.gif"
                                      className="w-16 h-16"
                                    />
                                  )}
                                  {errorMessage !== "" && (
                                    <b className="relative text-sm leading-[120%] uppercase font-dm-sans text-firebrick text-center mq350small:text-3xs">
                                      {errorMessage}
                                    </b>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {loadingData === false && (
                            <Footer
                              ready={ready}
                              buttonText="Login"
                              actionButtonText="Create New Account"
                              link={"/register"}
                            />
                          )}
                        </form>
                      </div>
                    </div>

                    <img
                      className={`w-[400px] relative rounded-xl h-[400px] object-cover md:flex-1 md:self-stretch md:h-auto sm:hidden
                      `}
                      alt=""
                      src="/banner1-1@2x.png"
                    />
                  </div>
                </div>
              </div>
            </main>
            <TermsCondition
              privacyPolicyFontWeight="bold"
              termsAndServicesFontWeight="bold"
              copyright2023FontWeight="bold"
            />
            <div className="w-[944px] bg-colors-white-100 h-12 hidden flex-row items-center justify-between py-2 px-6 box-border md:flex sm:flex sm:self-stretch sm:w-auto sm:gap-[8px] sm:pt-2 sm:pb-4 sm:box-border">
              <div className="flex flex-col items-center justify-start">
                <img
                  className="w-8 relative rounded h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/bxsdashboard13.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Dashboard
                </b>
              </div>
              <div className="flex flex-col items-center justify-start">
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/Inventory_icon.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Inventory
                </b>
              </div>
              <div className="hidden flex-col items-center justify-start">
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/Inventory_icon.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Logout
                </b>
              </div>
              <div className="flex flex-col items-center justify-start">
                <div className="w-8 relative rounded h-7 overflow-hidden shrink-0 mix-blend-normal">
                  <img
                    className="absolute top-[calc(50%_-_8px)] left-[calc(50%_-_8px)] w-4 h-4"
                    alt=""
                    src="/vector.svg"
                  />
                </div>
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Profile
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
      {isDrawerMenuSlideOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top"
          onOutsideClick={closeDrawerMenuSlide}
        >
          <DrawerMenuSlide onClose={closeDrawerMenuSlide} />
        </PortalDrawer>
      )}
    </>
  );
}
