import { FunctionComponent, useState, useCallback, useEffect } from "react";
import DashboardSidebarComponent from "../components/DashboardSidebarComponent";
import DrawerMenuSlide from "../components/DrawerMenuSlide";
import PortalDrawer from "../components/PortalDrawer";
import Header from "../components/Header";
import TermsCondition from "../components/TermsCondition";
import { Navigate, useNavigate } from "react-router-dom";
import useNavigation from "../api/route";
import LogoutSidebarComponent from "../components/LogoutSidebarComponent";

const Logout: FunctionComponent = () => {
  const [ready, setReady] = useState<boolean>(false);
  const [isDrawerMenuSlideOpen, setDrawerMenuSlideOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 3000);
  }, []);
  const {
    onDashboardClick,
    onProfileClick,
    onInventoryClick,
    onCatalogClick,
    onMyNFTClick,
    onLogoutClick,
    onInventoryMemoryFragmentClick,
    onInventoryPlacedBookmarkClick,
    onInventoryCostumesClick,
    onInventoryAllItemClick,
  } = useNavigation(); // Use the custom hook
  const openDrawerMenuSlide = useCallback(() => {
    setDrawerMenuSlideOpen(true);
  }, []);

  const closeDrawerMenuSlide = useCallback(() => {
    setDrawerMenuSlideOpen(false);
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem("session");
    setTimeout(() => {
      navigate("/");
      navigate(0);
      // window.location.reload(); // Reload the page
    }, 1000);
  }, []);

  return (
    <>
      <div className="w-full h-screen relative bg-whitesmoke-100 overflow-hidden flex flex-col items-start justify-start text-left text-5xs text-royalblue font-dm-sans lg:h-screen md:w-auto md:[align-self:unset] sm:w-auto sm:[align-self:unset] sm:pl-0 sm:box-border">
        <div className="self-stretch flex-1 flex flex-row items-start justify-start gap-[16px] lg:flex-1 md:self-stretch md:w-auto md:flex-1 md:gap-[0px] md:items-start md:justify-center sm:self-stretch sm:w-auto sm:flex-1 sm:gap-[0px] sm:pl-0 sm:box-border">
          <LogoutSidebarComponent
            ready={ready}
            terraquestLogoBig1="/terraquestlogobig-1@2x.png"
            onDashboardClick={onDashboardClick}
            onProfileClick={onProfileClick}
            onInventoryClick={onInventoryClick}
            onMyNFTClick={onMyNFTClick}
            onInventoryMemoryFragmentClick={onInventoryMemoryFragmentClick}
            onLogoutClick={onLogoutClick}
            onInventoryPlacedBookmarkClick={onInventoryPlacedBookmarkClick}
            onInventoryCostumesClick={onInventoryCostumesClick}
            onInventoryAllItemClick={onInventoryAllItemClick}
            onCatalogClick={onCatalogClick}
          />
          <div className="self-stretch flex-1 flex flex-col items-center justify-between lg:h-auto md:flex-1 md:gap-[16px] sm:flex-1 sm:gap-[8px] sm:items-center sm:justify-between sm:pl-0 sm:box-border">
            <div className="w-[944px] bg-colors-white-100 shadow-[0px_3.5px_5.5px_rgba(0,_0,_0,_0.02)] hidden flex-row items-center justify-between py-2 px-4 box-border lg:hidden md:flex md:self-stretch md:w-auto md:pl-4 md:pr-4 md:box-border sm:self-stretch sm:w-auto sm:pl-4 sm:box-border">
              <img
                className="w-[92.8px] relative h-14 object-cover"
                alt=""
                src="/terraquestlogobig-2@2x.png"
              />
              <img
                className="w-12 relative h-12 overflow-hidden shrink-0 cursor-pointer"
                alt=""
                src="/iconamoonmenuburgerhorizontalbold.svg"
                onClick={openDrawerMenuSlide}
              />
            </div>
            <main className="self-stretch flex flex-col items-center justify-start py-0 px-4 gap-[8px] text-left text-5xl text-dimgray-200 font-dm-sans lg:h-auto md:self-stretch md:w-auto md:flex-1 md:gap-[16px] md:p-10 md:box-border sm:self-stretch sm:w-auto sm:flex-1 sm:gap-[8px] sm:pl-6 sm:pr-6 sm:box-border">
              <Header ready={!ready} />
              <div className="self-stretch rounded-2xl bg-colors-white-100 flex flex-col items-start justify-start p-4 lg:h-auto md:self-stretch md:w-auto md:flex-1 md:items-center md:justify-start md:p-4 md:box-border sm:self-stretch sm:w-auto sm:flex-1 sm:items-center sm:justify-center sm:p-2 sm:box-border">
                <div className="self-stretch rounded-2xl flex flex-col items-start justify-start p-4 border-[2px] border-dashed border-whitesmoke-300 md:self-stretch md:w-auto md:flex-1 md:pl-8 md:pr-8 md:box-border sm:self-stretch sm:w-auto sm:flex-1 sm:gap-[0px] sm:items-center sm:justify-start sm:py-2 sm:px-6 sm:box-border">
                  <img
                    className="w-[200px] relative h-[200px] overflow-hidden shrink-0 lg:h-[200px] md:h-[180px] sm:w-60 sm:h-40"
                    alt=""
                    src="/cautionred.svg"
                  />

                  <div className="flex flex-col items-start justify-start gap-[24px] sm:gap-[16px]">
                    <h1
                      className={`m-0 relative text-inherit leading-[120%] uppercase font-bold font-inherit md:text-xl sm:text-base sm:leading-[60px] sm:text-center sm:self-stretch sm:w-auto sm:h-10 sm:active:self-stretch sm:active:w-auto
                      `}
                      id="Dashboard"
                    >
                      Confirm logout?
                    </h1>
                    <div className="relative text-sm tracking-[0.02em] leading-[150%] font-medium font-dm-sans sm:text-xs sm:text-center sm:self-stretch sm:w-auto">
                      <p
                        className={`m-0
                      `}
                      >
                        You will be logged out of the TerraQuest dashboard.
                      </p>
                      <p
                        className={`m-0
                      `}
                      >
                        Do you want to proceed?
                      </p>
                    </div>
                    <div className="flex flex-row items-start justify-start gap-[16px]">
                      <button
                        className={`cursor-pointer [border:none] py-0 px-6 bg-dimgray-200 w-40 rounded-lg h-8 flex flex-row items-center justify-center box-border hover:bg-rosybrown active:[background:linear-gradient(#fff,_#fff),_#362222] sm:w-[120px] sm:h-8
                      `}
                        onClick={() => {
                          if (ready) {
                            window.history.go(-1);
                          }
                        }}
                      >
                        <b className="relative text-sm tracking-[0.02em] leading-[100%] font-dm-sans text-colors-white-100 text-left md:text-xs sm:text-xs">
                          Cancel
                        </b>
                      </button>
                      <button
                        className={`cursor-pointer py-0 px-6 bg-[transparent] w-40 rounded-lg box-border h-8 flex flex-row items-center justify-center mix-blend-normal border-[1px] border-solid border-dimgray-200 sm:w-[120px] sm:h-8
                      `}
                      >
                        <b
                          className="relative text-sm tracking-[0.02em] leading-[100%] font-dm-sans text-dimgray-200 text-left md:text-xs sm:text-xs"
                          onClick={logout}
                        >
                          Confirm
                        </b>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <TermsCondition
              privacyPolicyFontWeight="bold"
              termsAndServicesFontWeight="bold"
              copyright2023FontWeight="bold"
            />
            <div className="w-[944px] bg-colors-white-100 h-12 hidden flex-row items-center justify-between py-2 px-6 box-border md:flex md:self-stretch md:w-auto sm:flex sm:gap-[8px] sm:pl-6 sm:pt-2 sm:pb-4 sm:box-border">
              <div className="flex flex-col items-center justify-start">
                <img
                  className="w-8 relative rounded h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/bxsdashboard13.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Dashboard
                </b>
              </div>
              <div className="flex flex-col items-center justify-start">
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/Inventory_icon.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Inventory
                </b>
              </div>
              <div className="hidden flex-col items-center justify-start">
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/Inventory_icon.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Logout
                </b>
              </div>
              <div className="flex flex-col items-center justify-start">
                <div className="w-8 relative rounded h-7 overflow-hidden shrink-0 mix-blend-normal">
                  <img
                    className="absolute top-[calc(50%_-_8px)] left-[calc(50%_-_8px)] w-4 h-4"
                    alt=""
                    src="/vector.svg"
                  />
                </div>
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Profile
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDrawerMenuSlideOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top"
          onOutsideClick={closeDrawerMenuSlide}
        >
          <DrawerMenuSlide onClose={closeDrawerMenuSlide} />
        </PortalDrawer>
      )}
    </>
  );
};

export default Logout;
