import { FunctionComponent, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAccount } from "wagmi";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import DashboardButtons from "./DashboardButtons";
import Property1Normal1 from "./Property1Normal1";
import NavigationSidebar from "./NavigationSidebar";

type DrawerMenuSlideType = {
  onClose?: () => void;
  isDrawerMenuSlideOpen?: boolean;
  setDrawerMenuSlideOpen?: any;
  openDrawerMenuSlide?: () => void;
};

const DrawerMenuSlide: FunctionComponent<DrawerMenuSlideType> = ({
  onClose,
}) => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );

    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const { address, isConnected, status } = useAccount();

  const isSessionAvailable = () => {
    const session = localStorage.getItem("session");
    return !!session;
  };

  const isConnecting = status === "connecting";

  useEffect(() => {
  }, [status]);

  return (
    <div className="relative w-full h-full">
      {status === "connecting" ? (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 pointer-events-auto">
          <div className="text-white text-lg font-bold">Connecting...</div>
        </div>
      ) : (
        <div
          className="relative overflow-hidden flex flex-row items-start justify-start [&.animate]:animate-[0.25s_ease_0s_1_normal_forwards_slide-in-top] opacity-[0] max-h-[90%]"
          data-animate-on-scroll
        >
          <section className="flex-1 bg-colors-white-100 shadow-[0px_3.5px_5.5px_rgba(0,_0,_0,_0.02)] flex flex-col items-center justify-start pt-0 px-0 pb-10 gap-[24px]">
            <section className="self-stretch bg-colors-white-100 shadow-[0px_3.5px_5.5px_rgba(0,_0,_0,_0.02)] flex flex-row items-center justify-between p-4 md:flex sm:self-stretch sm:w-auto">
              <img
                className="w-[106px] relative h-16 object-cover"
                alt=""
                src="/terraquestlogobig-21@2x.png"
              />
              <img
                className="w-12 relative h-12 overflow-hidden shrink-0"
                alt=""
                src="/iconamoonmenuburgerhorizontalbold.svg"
                onClick={onClose}
              />
            </section>
            <section className="self-stretch flex flex-col items-center justify-start py-0 px-6 gap-[21px]">
              <img
                className="self-stretch relative max-w-full overflow-hidden max-h-full"
                alt=""
                src="/vector-101.svg"
              />

              {isSessionAvailable() ? (
                <DashboardButtons />
              ) : (
                <section className="flex flex-col items-start justify-start text-left text-base text-dimgray-200 font-dm-sans">
                  <Link to={"/"} className="no-underline">
                    <button className="!no-underline cursor-pointer [border:none] py-4 px-6 bg-[transparent] w-60 rounded-lg flex flex-row items-center justify-start box-border gap-[8px] mix-blend-normal">
                      <img
                        className="w-8 relative rounded h-8 overflow-hidden shrink-0"
                        alt=""
                        src="/loginOpacity.svg"
                      />
                      <div className="!no-underline relative text-base tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-dimgray-200 text-left">
                        Login
                      </div>
                    </button>
                  </Link>
                  <Link to={"/register"} className="no-underline">
                    <button className="!no-underline cursor-pointer [border:none] py-4 px-6 bg-[transparent] w-60 rounded-lg flex flex-row items-center justify-start box-border gap-[8px] mix-blend-normal">
                      <img
                        className="w-8 relative rounded h-8 overflow-hidden shrink-0"
                        alt=""
                        src="/register.svg"
                      />
                      <div className="!no-underline relative text-base tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-dimgray-200 text-left">
                        Register
                      </div>
                    </button>
                  </Link>

                  <Link to={"/catalog"} className="no-underline">
                    <button className="!no-underline cursor-pointer [border:none] py-4 px-6 bg-[transparent] w-60 rounded-lg flex flex-row items-center justify-start box-border gap-[8px] mix-blend-normal">
                      <img
                        className="w-8 relative rounded h-8 overflow-hidden shrink-0"
                        alt=""
                        src="/register.svg"
                      />
                      <div className="!no-underline relative text-base tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-dimgray-200 text-left">
                        Catalog
                      </div>
                    </button>
                  </Link>
                </section>
              )}
              <Property1Normal1
                phplayFill="/phplayfill1.svg"
                x923749237493="Play Game"
                property1NormalHeight="40px"
                property1NormalBorder="none"
                property1NormalWidth="320px"
                x923749237493Display="inline-block"
                x923749237493FontFamily="'DM Sans'"
                x923749237493FontWeight="unset"
              />

              <ConnectButton.Custom>
                {({
                  account,
                  chain,
                  openAccountModal,
                  openChainModal,
                  openConnectModal,
                  authenticationStatus,
                  mounted,
                }) => {

                  const ready = mounted && authenticationStatus !== "loading";
                  const connected =
                    ready &&
                    account &&
                    chain &&
                    (!authenticationStatus ||
                      authenticationStatus === "authenticated");

                  return (
                    <div
                      className="w-full px-8"
                      {...(!ready && {
                        "aria-hidden": true,
                        style: {
                          opacity: 0,
                          pointerEvents: "none",
                          userSelect: "none",
                        },
                      })}
                    >
                      {(() => {
                        if (!connected) {
                          return (
                            <button
                              onClick={openConnectModal}
                              className="cursor-pointer [border:none] py-0 px-6 bg-dimgray-200 w-80 rounded-lg h-10 flex flex-row items-center justify-center box-border gap-[8px]"
                            >
                              <img
                                className="w-4 relative h-4 overflow-hidden shrink-0 hidden"
                                alt=""
                                src="/phplayfill1.svg"
                              />
                              <img
                                className="w-4 relative rounded-sm h-4 overflow-hidden shrink-0"
                                alt=""
                                src="/bxsdashboard15.svg"
                              />
                              <b className="relative text-sm tracking-[0.02em] leading-[100%] font-dm-sans text-colors-white-100 text-left">
                                Connect Wallet
                              </b>
                            </button>
                          );
                        }

                        if (chain.unsupported) {
                          return (
                            <button onClick={openChainModal} type="button">
                              Wrong network
                            </button>
                          );
                        }

                        return (
                          <div
                            style={{ display: "flex", gap: 12 }}
                            className="rounded-lg bg-royalblue h-10 flex flex-row items-center justify-center py-0 px-6 box-border gap-[8px] text-left text-sm text-colors-white-100 font-dm-sans"
                          >
                            <button
                              onClick={openChainModal}
                              style={{ display: "flex", alignItems: "center" }}
                              type="button"
                            >
                              {chain.hasIcon && (
                                <div
                                  style={{
                                    background: chain.iconBackground,
                                    width: 12,
                                    height: 12,
                                    borderRadius: 999,
                                    overflow: "hidden",
                                    marginRight: 4,
                                  }}
                                >
                                  {chain.iconUrl && (
                                    <img
                                      alt={chain.name ?? "Chain icon"}
                                      src={chain.iconUrl}
                                      style={{ width: 12, height: 12 }}
                                    />
                                  )}
                                </div>
                              )}
                              {chain.name}
                            </button>

                            <button onClick={openAccountModal} type="button">
                              {account.displayName}
                              {account.displayBalance
                                ? ` (${account.displayBalance})`
                                : ""}
                            </button>
                          </div>
                        );
                      })()}
                    </div>
                  );
                }}
              </ConnectButton.Custom>
            </section>
          </section>
        </div>
      )}
    </div>
  );
};

export default DrawerMenuSlide;
