import { useEffect, useState } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Client } from "@heroiclabs/nakama-js";

type ApiAccountCustom = {
  id: string;
  // Add other properties if there are any
};

export default function GoogleAuth(props: any) {
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingData, setLoadingData] = useState(false);
  const [isMetaMaskBrowser, setIsMetaMaskBrowser] = useState(false);
  const navigate = useNavigate();
  const notify = (message: any, types: any) =>
    toast(<p style={{ fontSize: 16 }}>{message}</p>, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      rtl: false,
      pauseOnFocusLoss: true,
      draggable: true,
      pauseOnHover: true,
      type: types,
    });
  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    if (userAgent.includes("MetaMask")) {
      setIsMetaMaskBrowser(true);
    }
  }, []);

  function nakamaGoogle(googletoken: string, email: string) {
    // Check the current route
    const currentRoute = window.location.pathname;

    let loadingToastId: string; // Declare loadingToastId variable with explicit type

    const useSSL = window.location.protocol === "https:";
    const client = new Client(
      useSSL ? process.env.REACT_APP_CLIENT_KEY! : process.env.REACT_APP_DEV_CLIENT_KEY!,
      useSSL ? process.env.REACT_APP_API_HOST! : process.env.REACT_APP_DEV_API_HOST!,
      useSSL ? process.env.REACT_APP_API_PORT! : process.env.REACT_APP_DEV_API_PORT!,
      useSSL
    );

    client
      .authenticateGoogle(googletoken)
      .then(async (session) => {
        // Show loading toast
        setLoadingData(false);
        //@ts-ignore

        if (loadingData === false) {
          //@ts-ignore
          loadingToastId = toast.loading("Logging you in. 🚀🚀🚀.", {
            position: "bottom-right",
            autoClose: false,
          });
        }


        const useSSL = true;
        let socket = client.createSocket(useSSL);
        const appearOnline = true;

        await socket
          .connect(session, appearOnline)
          .then(async (res) => {
            return;
          })
          .catch((error) => {
            return error;
          });

        // test later check first

        // if (session?.username) {
        //   let test: ApiAccountCustom = {
        //     id: session?.username,
        //   };
        //   await client.linkCustom(session, test);
        // }

        // end

        const googleTokenObject = { token: googletoken };

        const linkEmail = await client.linkGoogle(session, googleTokenObject);

        // call the linkEmail ID

        const emailData = email;

        const result = await client.rpc(
          session,
          "UpdateMetadata",
          JSON.parse(`{"metadata": {"email": "${emailData}"}}`)
        );

        const acc = client.getAccount(session);


        setLoadingData(true);

        // Conditionally navigate based on the current route
        setTimeout(() => {
          setLoadingData(true);
          const errorMessage = "Logged In! Please Wait...";
          setErrorMessage(errorMessage);
          notify(errorMessage, "success");
          setTimeout(() => {
            setErrorMessage("");
            localStorage.setItem("session", JSON.stringify(session));
            if (currentRoute === "/register") {
              navigate("/profile");
            } else {
              navigate("/");
              location.reload();
            }
            toast.update(loadingToastId, {
              render: "Logged In!",
              type: "success",
              isLoading: false,
            });
          }, 3500);
        }, 4000);
      })
      .catch((error) => {
        // Hide loading toast and show error toast
        toast.update(loadingToastId, {
          render: "Error logging in.",
          type: "error",
          isLoading: false,
        });
        console.error("Authentication error:", error.message);
      });
  }

  function decodeJwtResponse(token: string) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  const handleSuccess = (credentialResponse: any) => {
    const decoded = decodeJwtResponse(credentialResponse?.credential);
    const email = decoded.email;

    nakamaGoogle(credentialResponse?.credential, email);
    // Handle successful login
  };

  const handleError = () => {
    console.log("Login Failed");
    // Handle login failure
  };

  return (
    <>
      {isMetaMaskBrowser ? (
        <img src="/Google-Disable.svg" />
      ) : (
        <div className="reactGooglelogin">
          <GoogleOAuthProvider clientId="741684497200-o12il63a8dkjqc1jsk7tdc423f2drn3r.apps.googleusercontent.com">
            <GoogleLogin
              type="icon"
              ux_mode="popup"
              onSuccess={handleSuccess}
              onError={handleError}
            />
          </GoogleOAuthProvider>
        </div>
      )}

      <ToastContainer />
    </>
  );
}
