import { FunctionComponent, useState, useCallback, useEffect } from "react";
import InventoryPlacedBookmarkSide from "../components/InventoryPlacedBookmarkSide";
import DrawerMenuSlide from "../components/DrawerMenuSlide";
import PortalDrawer from "../components/PortalDrawer";
import TopbarMenuComponent from "../components/TopbarMenuComponent";
import InventoryCard from "../components/InventoryCard";
import TermsCondition from "../components/TermsCondition";
import useNavigation from "../api/route"; // Import the custom hook
import Header from "../components/Header";

export default function PlacedBookmark(props: any) {
  const [isDrawerMenuSlideOpen, setDrawerMenuSlideOpen] = useState(false);
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 3000);
  }, []);
  const {
    onDashboardClick,
    onProfileClick,
    onInventoryClick,
    onMyNFTClick,
    onLogoutClick,
    onInventoryPlacedBookmarkClick,
    onInventoryMemoryFragmentClick,
    onInventoryCostumesClick,
    onInventoryAllItemClick,
    onCatalogClick,
  } = useNavigation(); // Use the custom hook

  const onPrimaryButtonClick = useCallback(() => {
    // Please sync "Placed_Bookmark_Open_Wallet" to the project
  }, []);

  const openDrawerMenuSlide = useCallback(() => {
    setDrawerMenuSlideOpen(true);
  }, []);

  const closeDrawerMenuSlide = useCallback(() => {
    setDrawerMenuSlideOpen(false);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 3000);
  }, []);

  return (
    <>
      <div className="w-full h-auto relative bg-whitesmoke-100 flex flex-col items-start justify-start text-left text-5xs text-royalblue font-dm-sans lg:h-auto md:w-auto md:[align-self:unset] sm:w-auto sm:[align-self:unset] sm:pl-0 sm:box-border">
        <div
          className="self-stretch flex-1 flex flex-row items-start justify-start gap-[16px] lg:flex-1 md:self-stretch md:w-auto md:flex-1 md:gap-[0px] md:items-start md:justify-center sm:self-stretch sm:w-auto sm:flex-1 sm:gap-[0px] sm:pl-0 sm:box-border
        overflow-x-hidden"
        >
          <InventoryPlacedBookmarkSide
            onInventoryAllItemClick={onInventoryAllItemClick}
            onInventoryPlacedBookmarkClick={onInventoryPlacedBookmarkClick}
            onInventoryMemoryFragmentClick={onInventoryMemoryFragmentClick}
            onInventoryCostumesClick={onInventoryCostumesClick}
            ready={ready}
            terraquestLogoBig1="/terraquestlogobig-1@2x.png"
            onDashboardClick={onDashboardClick}
            onProfileClick={onProfileClick}
            onInventoryClick={onInventoryClick}
            onMyNFTClick={onMyNFTClick}
            onLogoutClick={onLogoutClick}
            onCatalogClick={onCatalogClick}
          />
          <div
            className="self-stretch flex-1 flex flex-col items-center justify-between lg:h-auto md:flex-1 md:gap-[16px] sm:flex-1 sm:gap-[8px] sm:items-center sm:justify-between sm:pl-0 sm:box-border w-full sm:!pr-0"
            style={{ paddingRight: "28px" }}
          >
            <div className="w-full bg-colors-white-100 shadow-[0px_3.5px_5.5px_rgba(0,_0,_0,_0.02)] hidden flex-row items-center justify-between py-2 px-4 box-border lg:hidden md:flex md:self-stretch md:w-auto md:pl-4 md:pr-4 md:box-border sm:self-stretch sm:w-auto sm:pl-4 sm:box-border">
              <img
                className="w-[92.8px] relative h-14 object-cover"
                alt=""
                src="/terraquestlogobig-2@2x.png"
              />
              <img
                className="w-12 relative h-12 overflow-hidden shrink-0 cursor-pointer"
                alt=""
                src="/iconamoonmenuburgerhorizontalbold.svg"
                onClick={openDrawerMenuSlide}
              />
            </div>
            <main className="self-stretch flex flex-col items-center justify-start py-0 px-4 gap-[8px] lg:h-auto md:self-stretch md:w-auto md:h-auto md:gap-[16px] sm:w-full sm:[align-self:unset] sm:h-auto sm:gap-[8px] sm:pl-2 sm:pr-2 sm:box-border w-full">
              <Header ready={!ready} />
              <InventoryCard
                ready={ready}
                clientApi={props.clientApi}
                itemLabel="My Bookmarks"
                itemImageUrl="/mapicon-2@2x.png"
                inventoryItemImageUrl="/mapicon-2@2x.png"
                inventoryItemIconUrl="/mapicon-2@2x.png"
                inventoryItemThumbnailUrl="/mapicon-2@2x.png"
                inventoryItemIconUrl2="/mapicon-2@2x.png"
                onPrimaryButtonClick={onPrimaryButtonClick}
              />
            </main>
            <TermsCondition
              privacyPolicyFontWeight="bold"
              termsAndServicesFontWeight="bold"
              copyright2023FontWeight="bold"
            />
            <div
              className="w-[944px] bg-colors-white-100 h-20 hidden flex-row items-center justify-between py-2 px-6 box-border md:flex md:self-stretch md:w-auto sm:flex sm:gap-[8px] sm:pl-6 sm:pt-2 sm:pb-4 sm:box-border
             z-[9999999] fixed bottom-0 sm:w-full"
            >
              <div
                className="flex flex-col items-center justify-start !text-[#2F6DD5]"
                onClick={onDashboardClick}
              >
                <img
                  className="w-8 relative rounded h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/bxsdashboard13.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Dashboard
                </b>
              </div>
              <div
                className="flex flex-col items-center justify-start"
                onClick={onInventoryPlacedBookmarkClick}
              >
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/Inventory_icon.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Inventory
                </b>
              </div>
              <div className="hidden flex-col items-center justify-start">
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/Inventory_icon.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Logout
                </b>
              </div>
              <div
                className="flex flex-col items-center justify-start"
                onClick={onProfileClick}
              >
                <div className="w-8 relative rounded h-7 overflow-hidden shrink-0 mix-blend-normal">
                  <img
                    className="absolute top-[calc(20%_-_8px)] left-[calc(20%_-_8px)] w-8 h-8"
                    alt=""
                    src="/Profile_icon.svg"
                  />
                </div>
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Profile
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDrawerMenuSlideOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top"
          onOutsideClick={closeDrawerMenuSlide}
        >
          <DrawerMenuSlide onClose={closeDrawerMenuSlide} />
        </PortalDrawer>
      )}
    </>
  );
}
