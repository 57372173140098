import { useEffect, useState, useCallback } from "react";
import { useMediaQuery } from "react-responsive";
import CustomTooltip from "../components/tooltip";

import Moralis from "moralis";
import { useAccount, useChainId } from "wagmi";
import ModalPage from "./Modal";

// Initialize Moralis outside the component
Moralis.start({
  apiKey: process.env.REACT_APP_MORALIS_API_KEY,
});

export default function InventoryCardCatalog(props: any) {
  const [showModalTopRight, setShowModalTopRight] = useState(false);
  const [currentItems, setCurrentItems] = useState<any | null>(null);
  const [ready, setReady] = useState<boolean>(false);
  const [items, setItems] = useState<any[]>([]);
  const { address } = useAccount();
  const chainId = useChainId();
  const [nftCollections, setNftCollections] = useState<any[]>([]);
  const [chains, setChainID] = useState("");
  const [cursor, setCursor] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [isNft, setIsNft] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState(""); // Add this state variable

  const disableTotal = true;

  const fetchData = async (cursor: any) => {
    const chainIdHex = `0x${chainId.toString(16)}`;
    setChainID(chainIdHex);

    try {
      const response = await Moralis.EvmApi.nft.getWalletNFTs({
        limit: 50,
        excludeSpam: true,
        cursor: cursor,
        chain: chainIdHex,
        address: address || "",
      });

      const responseCollection = await Moralis.EvmApi.nft.getWalletNFTCollections({
        limit: 50,
        excludeSpam: true,
        cursor: cursor,
        chain: chainIdHex,
        address: address || "",
      });
      const nfts = response?.raw?.result;

      //@ts-ignore
      setCursor(response?.raw?.cursor);

      if (Array.isArray(nfts)) {
        setNftCollections(nfts);
      } else {
        setNftCollections([]);
      }

      const calculateInitialItemCount = () => {
        let initialItemCount;
        const itemWidth = 180;
        const itemHeight = 150;

        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        const containerHeight = windowHeight * 0.8;
        const containerWidth = windowWidth * 0.92 - 52;
        const verticalItemCount = Math.floor(containerHeight / itemHeight);
        const horizontalItemCount = Math.floor(containerWidth / itemWidth);

        initialItemCount = Math.min(
          verticalItemCount * horizontalItemCount,
          nfts.length
        );

        if (isMobile) {
          initialItemCount = 20;
        }

        const shuffledImages = nfts.sort(
          //@ts-ignore
          (a, b) => new Date(b.LastModified) - new Date(a.LastModified)
        );
        const initialItems = shuffledImages.slice(0, initialItemCount);

        setItems(initialItems);
      };

      calculateInitialItemCount();

      const handleResize = () => {
        calculateInitialItemCount();
      };
      window.addEventListener("resize", handleResize);
      setReady(true);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const chainIdHex = `0x${chainId.toString(16)}`;
    setChainID(chainIdHex);

    fetchData("");
  }, []);

  const nextNFT = useCallback(() => {
    fetchData(cursor);
  }, [cursor]);

  const onItemClick = (item: any) => {
    setIsNft(true);
    setCurrentItems(item);
    setShowModalTopRight(true);
  };

  // Filter NFT collections based on search input
  const filteredCollections = nftCollections.filter((collection) =>
    collection.token_id.includes(searchInput)
  );

  return (
    <div
      id="scrollableDiv"
      className="w-full self-stretch pl-0 rounded-xl bg-colors-white-100 p-4 text-left text-sm text-dimgray-200 font-dm-sans lg:h-auto md:w-full md:[align-self:unset] md:items-center md:justify-start md:p-4 md:box-border sm:w-full sm:[align-self:unset] sm:items-center sm:justify-center sm:p-2 sm:box-border"
      style={{
        paddingLeft: "21px",
        paddingRight: "31px",
        overflowY: "auto",
        height: "80vh",
      }}
    >
      <div className="self-stretch rounded-xl py-2 px-4 border-[2px] border-dashed border-whitesmoke-300 md:w-auto md:[align-self:unset] sm:gap-[0px] sm:py-2 sm:px-1 sm:box-border">
        <div className="h-8 flex flex-row items-center justify-start gap-[8px] sm:pl-2 sm:box-border">
          <h1
            className={`m-0 h-[17px] relative text-inherit leading-[120%] uppercase font-bold font-inherit inline-block md:text-base sm:text-sm
                        `}
            id="Dashboard"
          >
            Terraquest NFT
          </h1>

          <div className="group relative my-12 flex justify-center">
            <CustomTooltip content="This is a tooltip">
              <button style={{ maxWidth: "300px", minWidth: "200px" }}>
                {" "}
                TerraQuest NFTs in your Web3 wallet will appear here. Please
                ensure that your wallet is connected to view your TerraQuest
                NFTs.
              </button>
            </CustomTooltip>
          </div>
        </div>

        <div className="py-4 relative mx-auto text-gray-600 flex justify-between items-center">
          <input
            className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none
            w-full"
            type="search"
            name="search"
            placeholder="Search"
            value={searchInput} // Bind the input value to the state
            onChange={(e) => setSearchInput(e.target.value)} // Update the state on change
          />
          <button type="submit" className="pl-2 mr-4">
            <img src="/button.svg" />
          </button>
        </div>
        <div className="grid gridBoxesCostume">
          {!ready ? (
            <>
              {[...Array(Math.max(15 - filteredCollections.length, 0))].map(
                (_, index) => (
                  <div
                    key={`empty-${index}`}
                    className={`
                          bg-slate-200 text-transparent animate-pulse rounded-lg
                !z-5 relative border-[#E1DEEE] border-2 border-solid flex flex-col rounded-xl max-w-[180px] h-[160px] bg-clip-border shadow-3xl shadow-shadow-500 flex flex-col w-full  undefined
                
                `}
                  ></div>
                )
              )}
            </>
          ) : (
            <>
              {filteredCollections.length > 0 ? (
                filteredCollections.map((collection) => {
                  const metadata = JSON.parse(collection.metadata);
                  return (
                    <div
                      key={collection.token_id} // Ensure to add a unique key
                      className="!z-5 relative border-[#E1DEEE] border-2 border-solid flex flex-col rounded-xl max-w-[180px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 flex flex-col w-full bg-white undefined
                      hover:border-[#2F6DD5]"
                    >
                      <div className="h-full">
                        <div className="relative w-full flex flex-col"
                         onClick={() => onItemClick(collection)}>
                          <img
                            src={
                              metadata?.image
                                ? metadata.image.includes("ipfs://")
                                  ? metadata.image.replace(
                                      "ipfs://",
                                      "https://ipfs.io/ipfs/"
                                    )
                                  : metadata.image
                                  : "/coming-soon.png"
                            }
                            alt={metadata?.name || "defaultName"}
                            className="h-[150px] w-full  transition duration-500 object-cover rounded-t-lg
                            hover:scale-[1.01]"
                          />
                          
                          <span className="text-xs font-bold text-navy-700 mb-0 pb-2 mt-[1rem] pt-0 px-3 text-left block lg:w-[150px] break-words">
                          {collection.token_id}<br/>
                            {collection.name}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No NFTs found.</p>
              )}
            </>
          )}
        </div>

        {filteredCollections.length >= 50 && <button onClick={nextNFT}>next</button>}
      </div>
      <ModalPage
        title="NFT Details"
        setShowModalTopRight={setShowModalTopRight}
        showModalTopRight={showModalTopRight}
        currentItems={currentItems}
        isNft={isNft}
      />
    </div>
  );
}
