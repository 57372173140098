import React from "react";
import MappleToolTip from "reactjs-mappletooltip";

interface TooltipProps {
  content: string;
  children: React.ReactNode;
}

const CustomTooltip: React.FC<TooltipProps> = ({ content, children }) => {
  return (
    <MappleToolTip mappleType={"contra"}>
      <div className="tooltip-content">
        <img
          className="h-[18px] w-[18px] relative overflow-hidden shrink-0 cursor-pointer"
          loading="lazy"
          alt=""
          src="/infoicon.svg"
        />
      </div>
      {children}
    </MappleToolTip>
  );
};

export default CustomTooltip;
