import { FunctionComponent, useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Session } from "@heroiclabs/nakama-js";
import { useForm } from "react-hook-form";
import NavigationSidebar from "../components/NavigationSidebar";
import DrawerMenuSlide from "../components/DrawerMenuSlide";
import PortalDrawer from "../components/PortalDrawer";
import Header from "../components/Header";
import ResetPasswordContainer from "../components/ResetPasswordContainer";
import SocialMediaLoginContainer from "../components/SocialMediaLoginContainer";
import TermsCondition from "../components/TermsCondition";

type ForgetPasswordFlow1EmailPType = {
  onClose?: () => void;
  clientApi?: any;
};

const ForgetPasswordFlow1EmailP: FunctionComponent<
  ForgetPasswordFlow1EmailPType
> = ({ onClose, clientApi }) => {
  const [isDrawerMenuSlideOpen, setDrawerMenuSlideOpen] = useState(false);
  const [ready, setReady] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setReady(false);
    }, 2000);
  }, [ready]);

  const [errorMessage, setErrorMessage] = useState("");
  const [loadingData, setLoadingData] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const email = location.state && location.state.email;

  const {
    register,
    handleSubmit,
    // eslint-disable-next-line
    formState: { errors },
  } = useForm();

  const onDashboardClick = useCallback(() => {
    // Please sync "Prototype-Dashboard" to the project
  }, []);

  const openDrawerMenuSlide = useCallback(() => {
    setDrawerMenuSlideOpen(true);
  }, []);

  const closeDrawerMenuSlide = useCallback(() => {
    setDrawerMenuSlideOpen(false);
  }, []);

  const onSubmit = async (data: any) => {
    // const email = localStorage.getItem("email") ?? "";
    const cpassword: string = data.cpassword;
    const password: string = data.password;

    if (cpassword === password) {
      // const sessionData = localStorage.getItem("session");

      // if (sessionData !== null) {
      const session = await clientApi.authenticateCustom("adminTerraquest");
      // const session = JSON.parse(sessionData);

      if (session) {
        let sessionCheck = Session.restore(
          session.token,
          session.refresh_token
        );
        const currentTimeInSec = new Date().getTime() / 1000; // Corrected line

        if (!sessionCheck.isexpired(currentTimeInSec)) {
          // Session valid so restore it
          // console.log("Session Restored");

          // check if email(input from user) existed in database or not
          const payload = { username: email, new_password: password };
          const checkEmailAvailability = await clientApi.rpc(
            sessionCheck,
            "ForgotPassword",
            payload
          );

          navigate("/");

          // Swal.fire({
          //   position: "bottom-end",
          //   icon: "success",
          //   title: "Successfully Reset Password",
          //   showConfirmButton: false,
          //   timer: 1500,
          // }).then(() => {
          //   navigate("/login");
          // });
        } else {
          console.log("Session Expired");
        }
      } else {
        // await this.createSession()
      }
    } else {
      alert("password dont match");
    }
  };

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <>
      <div className="w-full h-screen relative bg-whitesmoke-100 overflow-hidden flex flex-col items-start justify-start gap-[8px] text-left text-5xs text-royalblue font-dm-sans sm:w-auto sm:[align-self:unset] sm:h-screen">
        <div className="self-stretch flex-1 flex flex-row items-start justify-start gap-[16px] z-[0] lg:flex-1 md:flex-1 sm:flex-1 sm:gap-[8px]">
          <NavigationSidebar
            ready={ready}
            onDashboardClick={onDashboardClick}
            itemCode="/login.svg"
            itemImageCode="/register.svg"
            loginBackgroundColor="#f9f9fb"
            loginOpacity="unset"
            bxsdashboardIconColor="#593f3f"
            registerBackgroundColor="transparent"
            registerOpacity="0.2"
            bxsdashboardIconColor1="#373737"
          />
          <div className="self-stretch flex-1 flex flex-col items-center justify-between lg:h-auto md:gap-[16px] sm:gap-[8px] sm:items-center sm:justify-between">
            <div className="w-[944px] bg-colors-white-100 shadow-[0px_3.5px_5.5px_rgba(0,_0,_0,_0.02)] hidden flex-row items-center justify-between py-2 px-4 box-border lg:hidden md:flex sm:self-stretch sm:w-auto">
              <img
                className="w-[92.8px] relative h-14 object-cover"
                alt=""
                src="/terraquestlogobig-2@2x.png"
              />
              <img
                className="w-12 relative h-12 overflow-hidden shrink-0 cursor-pointer"
                alt=""
                src="/iconamoonmenuburgerhorizontalbold.svg"
                onClick={openDrawerMenuSlide}
              />
            </div>
            <main className="self-stretch flex flex-col items-center justify-start py-0 px-4 gap-[24px] text-left text-sm text-dimgray-200 font-dm-sans lg:h-auto md:h-auto md:gap-[16px] sm:w-auto sm:[align-self:unset] sm:h-auto sm:gap-[8px] sm:pl-2 sm:pr-2 sm:box-border">
              <Header ready={ready} />
              <div className="self-stretch rounded-2xl bg-colors-white-100 flex flex-col items-start justify-start p-6 lg:h-auto md:p-4 md:box-border sm:self-stretch sm:w-auto sm:flex-1 sm:items-center sm:justify-start sm:p-4 sm:box-border mq350small:self-stretch mq350small:w-auto Ultrawide:w-[1000px] Ultrawide:self-center">
                <div className="self-stretch rounded-2xl flex flex-col items-start justify-start pt-6 px-6 pb-10 border-[2px] border-dashed border-whitesmoke-300 md:pl-4 md:box-border sm:self-stretch sm:w-auto sm:flex-1 sm:gap-[0px] sm:items-center sm:justify-start sm:py-5 sm:px-0 sm:box-border">
                  <div className="self-stretch flex flex-row items-start justify-start gap-[8px] sm:items-start sm:justify-center">
                    <div className="flex-1 flex flex-col items-start justify-start py-0 pr-8 pl-0 gap-[32px] md:pr-0 md:box-border sm:flex-1 sm:items-center sm:justify-start sm:pl-0.5 sm:pr-0.5 sm:box-border mq350small:flex-1 mq350small:items-center mq350small:justify-start">
                      <ResetPasswordContainer
                        ready={ready}
                        actionButtonText="Reset password"
                        resetPasswordInstructions="Forgot your password? Follow the steps below to reset"
                      />
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="self-stretch flex flex-col items-start justify-start py-0 px-4 gap-[16px] md:gap-[16px] sm:gap-[24px] sm:items-center sm:justify-start mq350small:self-stretch mq350small:w-auto mq350small:items-center mq350small:justify-start">
                          {/* <SocialMediaLoginContainer
                            ready={ready}
                            socialMediaButtonText="Continue With Social Media"
                            propOpacity="0.2"
                            propMixBlendMode="luminosity"
                          /> */}
                          {/* <div
                            className={`
                        ${
                          ready
                            ? "bg-slate-200 text-transparent animate-pulse rounded-lg !h-4"
                            : ""
                        }
                        relative text-xs leading-[100%] font-medium font-dm-sans mix-blend-normal mq350small:text-3xs`}
                          >
                            Alternatively, you can use a different method
                          </div> */}
                          <div className="flex flex-col items-start justify-start">
                            <h1
                              className={`m-0 relative text-inherit leading-[120%] uppercase font-bold font-inherit md:text-sm mq350small:text-xs
                              `}
                              id="Dashboard"
                            >
                              Enter your new password
                            </h1>
                          </div>
                          <div className="w-[332px] h-[162px] flex flex-col items-center justify-start gap-[16px] sm:self-stretch sm:w-auto sm:gap-[8px] sm:items-center sm:justify-start mq350small:self-stretch mq350small:w-auto">
                            <div className="self-stretch flex flex-col items-start justify-start gap-[8px] sm:gap-[0px] sm:items-start sm:justify-start">
                              <b
                                className={`relative leading-[120%] uppercase md:text-xs sm:text-sm mq350small:text-xs
`}
                              >
                                Password
                              </b>
                              <input
                                className={`[outline:none] bg-colors-white-100 self-stretch rounded-lg flex flex-col items-start justify-start py-2 px-3 font-dm-sans text-sm text-dimgray-100 border-[1px] border-solid border-silver-200 sm:h-10 sm:gap-[8px] sm:pt-2 sm:pb-2 sm:box-border
`}
                                placeholder={ready ? "" : ""}
                                type="password"
                                {...register("password", {
                                  required: true,
                                  minLength: 8,
                                })}
                              />
                            </div>
                            <div className="self-stretch flex flex-col items-start justify-start gap-[8px] sm:gap-[0px] sm:items-start sm:justify-start">
                              <b
                                className={`relative leading-[120%] uppercase md:text-xs sm:text-sm mq350small:text-xs
                              
                              `}
                              >
                                Confirm Password
                              </b>
                              <input
                                className={`[outline:none] bg-colors-white-100 self-stretch rounded-lg flex flex-col items-start justify-start py-2 px-3 font-dm-sans text-sm text-dimgray-100 border-[1px] border-solid border-silver-200 sm:h-10 sm:gap-[8px] sm:pt-2 sm:pb-2 sm:box-border
                                `}
                                placeholder={ready ? "" : ""}
                                type="password"
                                {...register("cpassword", {
                                  required: true,
                                  minLength: 8,
                                })}
                              />
                            </div>
                          </div>
                          <div className="self-stretch flex flex-col items-center justify-start sm:items-center sm:justify-start mq350small:self-stretch mq350small:w-auto">
                            <div className="self-stretch flex flex-row items-start justify-center gap-[8px] mq350small:self-stretch mq350small:w-auto">
                              <button
                                className={`cursor-pointer [border:none] py-0 px-5 bg-dimgray-200 rounded-lg h-8 flex flex-row items-center justify-center box-border sm:w-auto sm:[align-self:unset] sm:h-8 sm:rounded-lg sm:pt-0 sm:box-border
         
          `}
                              >
                                <img
                                  className={`w-[5.3px] relative h-[10.7px] object-contain
            
            `}
                                  alt=""
                                  src="/vector2.svg"
                                />
                              </button>
                              <button
                                className={`cursor-pointer [border:none] py-0 px-2 bg-dimgray-200 h-8 flex-1 rounded-lg flex flex-row items-center justify-center box-border md:pl-4 md:pr-4 md:box-border sm:flex-1 sm:h-8 sm:rounded-lg sm:pt-0 sm:box-border
                              `}
                              >
                                <b className="h-3.5 flex-1 relative text-sm tracking-[0.02em] leading-[100%] inline-block font-dm-sans text-colors-white-100 text-center sm:text-xs sm:flex-1 mq350small:text-3xs">
                                  Confirm New Password
                                </b>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <img
                      className={`w-[400px] relative rounded-xl h-[400px] object-cover md:flex-1 md:self-stretch md:h-auto sm:hidden
                      `}
                      alt=""
                      src="/banner1-1@2x.png"
                    />
                  </div>
                </div>
              </div>
            </main>
            <TermsCondition
              privacyPolicyFontWeight="bold"
              termsAndServicesFontWeight="bold"
              copyright2023FontWeight="bold"
            />
            <div className="w-[944px] bg-colors-white-100 h-12 hidden flex-row items-center justify-between py-2 px-6 box-border md:flex sm:flex sm:self-stretch sm:w-auto sm:gap-[8px] sm:pt-2 sm:pb-4 sm:box-border">
              <div className="flex flex-col items-center justify-start">
                <img
                  className="w-8 relative rounded h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/bxsdashboard13.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Dashboard
                </b>
              </div>
              <div className="flex flex-col items-center justify-start">
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/Inventory_icon.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Inventory
                </b>
              </div>
              <div className="hidden flex-col items-center justify-start">
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/Inventory_icon.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Logout
                </b>
              </div>
              <div className="flex flex-col items-center justify-start">
                <div className="w-8 relative rounded h-7 overflow-hidden shrink-0 mix-blend-normal">
                  <img
                    className="absolute top-[calc(50%_-_8px)] left-[calc(50%_-_8px)] w-4 h-4"
                    alt=""
                    src="/vector.svg"
                  />
                </div>
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Profile
                </b>
              </div>
            </div>
          </div>
        </div>
        <div
          className="h-full my-0 mx-[!important] absolute top-[-0.5px] right-[0px] flex flex-col items-end justify-between py-6 px-4 box-border [&.animate]:animate-[0.25s_ease_0s_1_normal_forwards_slide-in-right] opacity-[0] max-w-[90%] overflow-auto z-[1] sm:hidden sm:self-stretch sm:w-auto"
          data-animate-on-scroll
        >
          <div />
        </div>
      </div>
      {isDrawerMenuSlideOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top"
          onOutsideClick={closeDrawerMenuSlide}
        >
          <DrawerMenuSlide onClose={closeDrawerMenuSlide} />
        </PortalDrawer>
      )}
    </>
  );
};

export default ForgetPasswordFlow1EmailP;
