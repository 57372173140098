import { FunctionComponent, useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import VerificationInput from "react-verification-input";
import NavigationSidebar from "../components/NavigationSidebar";
import DrawerMenuSlide from "../components/DrawerMenuSlide";
import PortalDrawer from "../components/PortalDrawer";
import Header from "../components/Header";
import FormCard from "../components/FormCard";
import ContainerButtonForm from "../components/ContainerButtonForm";
import TermsCondition from "../components/TermsCondition";
import { authenticateEmail, listStorageObjects } from "../api/auth";
import SocialMediaLoginContainer from "../components/SocialMediaLoginContainer";

export default function ForgetPasswordFlow1EmailC(props: any) {
  const [verificationCode, setVerificationCode] = useState("");
  const [isDrawerMenuSlideOpen, setDrawerMenuSlideOpen] = useState(false);
  const [ready, setReady] = useState<boolean>(true);

  const [errorMessage, setErrorMessage] = useState("");
  const [loadingData, setLoadingData] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setReady(false);
    }, 2000);
  }, [ready]);

  const location = useLocation();
  const email = location.state && location.state.email;


  useEffect(() => {
    // Add further debugging if needed
  }, [email]);


  const onDashboardClick = useCallback(() => {
    // Please sync "Prototype-Dashboard" to the project
  }, []);

  const onPrimaryButtonClick = useCallback(() => {
    // Please sync "Login_Flow_1" to the project
    // alert("test");
  }, []);

  const onPrimaryButtonContainerClick = useCallback(() => {
    // const emailInput = localStorage.getItem("email") ?? "";

    const fetchData = async () => {
      if (email !== null) {
        const session = await props.clientApi.authenticateCustom(email);

        const getVerificationCode = await props.clientApi.listStorageObjects(
          session,
          "Email Verification Code",
          session.user_id
        );


        const activationCode =
          (
            getVerificationCode.objects?.[0]?.value as {
              activationCode?: string;
            }
          )?.activationCode || "";


        if (activationCode === verificationCode) {
          // console.log("user account doesn't exist");
          const session = await props.clientApi.authenticateCustom(email);

          const payload = { id: session.user_id };
          //update email into metadata
          const verifyEmail = await props.clientApi.rpc(
            session,
            "VerifyEmail",
            payload
          );
          const readStorage = {
            object_ids: [
              {
                collection: "Email Verification Code",
                key: "EmailVerificationCode", // Replace later
                user_id: session?.user_id,
              },
            ],
          };
          if (verifyEmail) {
            const deleteStorage = await props.clientApi.deleteStorageObjects(
              session,
              readStorage
            );

            // Pass email as a prop to the next route
            navigate("/reset-password-email", {
              state: { email },
            });
            // navigate("/prototypedashboardconvertmemoryfragment");
          }
        } else {
          setErrorMessage("Code does not match. Please Try again");
        }

        // setSuccessMessage("REGISTER SUCCESSFUL");
        // navigate("/profile")
      } else {
        console.error(
          "Session data or Google token is null. Handle this case accordingly."
        );
      }
    };

    fetchData();
  }, [props.clientApi, verificationCode]);

  const openDrawerMenuSlide = useCallback(() => {
    setDrawerMenuSlideOpen(true);
  }, []);

  const closeDrawerMenuSlide = useCallback(() => {
    setDrawerMenuSlideOpen(false);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      // setReady(false);
    }, 2000);
  }, [props.location]);

  return (
    <>
      <div className="w-full h-screen relative bg-whitesmoke-100  flex flex-col items-start justify-start text-left text-5xs text-royalblue font-dm-sans sm:w-auto sm:[align-self:unset] sm:h-screen">
        <div className="self-stretch flex-1 flex flex-row items-start justify-start gap-[16px] lg:flex-1 md:flex-1 sm:flex-1 sm:gap-[8px]">
          <NavigationSidebar
            ready={ready}
            onDashboardClick={onDashboardClick}
            itemCode="/login.svg"
            itemImageCode="/register.svg"
            loginBackgroundColor="#f9f9fb"
            loginOpacity="unset"
            bxsdashboardIconColor="#593f3f"
            registerBackgroundColor="transparent"
            registerOpacity="0.2"
            bxsdashboardIconColor1="#373737"
          />
          <div className="self-stretch flex-1 flex flex-col items-center justify-between lg:h-auto md:gap-[16px] sm:gap-[8px] sm:items-center sm:justify-between">
            <div className="w-[944px] bg-colors-white-100 shadow-[0px_3.5px_5.5px_rgba(0,_0,_0,_0.02)] hidden flex-row items-center justify-between py-2 px-4 box-border lg:hidden md:flex sm:self-stretch sm:w-auto">
              <img
                className="w-[92.8px] relative h-14 object-cover"
                alt=""
                src="/terraquestlogobig-2@2x.png"
              />
              <img
                className="w-12 relative h-12 overflow-hidden shrink-0 cursor-pointer"
                alt=""
                src="/iconamoonmenuburgerhorizontalbold.svg"
                onClick={openDrawerMenuSlide}
              />
            </div>
            <main className="self-stretch flex flex-col items-center justify-start py-0 px-4 gap-[24px] text-left text-10xl text-dimgray-200 font-dm-sans lg:h-auto md:h-auto md:gap-[16px] sm:w-auto sm:[align-self:unset] sm:h-auto sm:gap-[8px] sm:pl-2 sm:pr-2 sm:box-border">
              <Header ready={ready} />
              <div className="self-stretch rounded-2xl bg-colors-white-100 flex flex-col items-start justify-start p-6 lg:h-auto md:p-4 md:box-border sm:self-stretch sm:w-auto sm:flex-1 sm:items-center sm:justify-start sm:p-4 sm:box-border Ultrawide:w-[1000px] Ultrawide:self-center">
                <div className="self-stretch rounded-2xl flex flex-col items-start justify-start pt-6 px-6 pb-10 border-[2px] border-dashed border-whitesmoke-300 md:pl-4 md:box-border sm:self-stretch sm:w-auto sm:flex-1 sm:gap-[0px] sm:items-center sm:justify-start sm:py-5 sm:px-0 sm:box-border">
                  <div className="self-stretch flex flex-row items-start justify-start gap-[8px] sm:items-start sm:justify-center">
                    <div className="flex-1 flex flex-col items-start justify-start py-0 pr-8 pl-0 gap-[32px] md:pr-0 md:box-border sm:flex-1 sm:items-center sm:justify-start sm:pl-0.5 sm:pr-0.5 sm:box-border">
                      <div className="flex flex-col items-start justify-start gap-[16px] sm:self-stretch sm:w-auto sm:gap-[24px] sm:items-center sm:justify-start">
                        <div className="flex flex-col items-start justify-start md:pr-8 md:box-border sm:items-center sm:justify-start sm:pl-4 sm:pr-4 sm:box-border">
                          <h1
                            className={` m-0 relative text-inherit leading-[120%] uppercase font-bold font-inherit md:text-base sm:text-xl mq350small:text-xl`}
                            id="Dashboard"
                          >
                            Reset password
                          </h1>
                          <h1
                            className={`
                            
                            m-0 relative text-xs leading-[100%] font-medium font-dm-sans md:text-base mq350small:text-3xs`}
                            id="Dashboard"
                          >
                            Forgot your password? Follow the steps below to
                            reset.
                          </h1>
                        </div>
                        <img
                          className={`
                          ${
                            ready
                              ? "bg-slate-200 text-transparent animate-pulse rounded-lg !h-1"
                              : ""
                          }
                          w-80 relative max-h-full sm:w-40`}
                          alt=""
                          src="/vector-253.svg"
                        />
                      </div>
                      <div className="self-stretch flex flex-col items-start justify-start py-0 px-4 gap-[16px] text-sm md:gap-[16px] sm:gap-[24px] sm:items-center sm:justify-start">
                        {/* <SocialMediaLoginContainer
                          ready={ready}
                          socialMediaButtonText="Continue With Social Media"
                          propOpacity="0.4"
                          propMixBlendMode="luminosity"
                        /> */}
                        {/* <div
                          className={`
                        ${
                          ready
                            ? "bg-slate-200 text-transparent animate-pulse rounded-lg !h-4"
                            : ""
                        }
                        relative text-xs leading-[100%] font-medium font-dm-sans mix-blend-normal mq350small:text-3xs`}
                        >
                          Alternatively, you can use a different method
                        </div> */}
                        <div className="flex flex-col items-start justify-start gap-[12px]">
                          <h1
                            className={`m-0 relative text-inherit leading-[120%] uppercase font-bold font-inherit md:text-sm mq350small:text-xs
                            
                            `}
                            id="Dashboard"
                          >
                            WE EMAILED YOU A FIVE digits code to
                          </h1>
                          <h1
                            className={`m-0 relative text-xs leading-[100%] font-medium font-dm-sans md:text-sm sm:text-center sm:self-stretch sm:w-auto mq350small:text-3xs
                            ${
                              ready
                                ? "bg-slate-200 text-transparent animate-pulse rounded-lg !h-4"
                                : ""
                            }`}
                            id="Dashboard"
                          >
                            {email && (
                              <>
                                {email.slice(0, 3)}
                                {email
                                  .slice(3, email.indexOf("@"))
                                  .replace(/./g, "*")}
                                {email.slice(email.indexOf("@"))}
                              </>
                            )}
                          </h1>
                        </div>
                        {/* <FormCard /> */}

                        {ready ? (
                          <div className="bg-slate-200 text-transparent animate-pulse rounded-lg !h-9 w-56" />
                        ) : (
                          <VerificationInput
                            placeholder=""
                            length={5}
                            onChange={(value) => {
                              setVerificationCode(value);
                            }}
                          />
                        )}

                        {errorMessage !== "" && (
                          <b className="relative leading-[120%] uppercase md:text-sm mq350small:text-xs text-red-700">
                            {errorMessage}
                          </b>
                        )}
                        <ContainerButtonForm
                          ready={ready}
                          onPrimaryButtonClick={onPrimaryButtonClick}
                          onPrimaryButtonContainerClick={
                            onPrimaryButtonContainerClick
                          }
                        />
                      </div>
                    </div>
                    <img
                      className={`w-[400px] relative rounded-xl h-[400px] object-cover md:flex-1 md:self-stretch md:h-auto sm:hidden
                      `}
                      alt=""
                      src="/banner1-1@2x.png"
                    />
                  </div>
                </div>
              </div>
            </main>
            <TermsCondition
              privacyPolicyFontWeight="bold"
              termsAndServicesFontWeight="bold"
              copyright2023FontWeight="bold"
            />
            <div className="w-[944px] bg-colors-white-100 h-12 hidden flex-row items-center justify-between py-2 px-6 box-border md:flex sm:flex sm:self-stretch sm:w-auto sm:gap-[8px] sm:pt-2 sm:pb-4 sm:box-border">
              <div className="flex flex-col items-center justify-start">
                <img
                  className="w-8 relative rounded h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/bxsdashboard13.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Dashboard
                </b>
              </div>
              <div className="flex flex-col items-center justify-start">
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/Inventory_icon.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Inventory
                </b>
              </div>
              <div className="hidden flex-col items-center justify-start">
                <img
                  className="w-7 relative h-7 overflow-hidden shrink-0 mix-blend-normal"
                  alt=""
                  src="/Inventory_icon.svg"
                />
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Logout
                </b>
              </div>
              <div className="flex flex-col items-center justify-start">
                <div className="w-8 relative rounded h-7 overflow-hidden shrink-0 mix-blend-normal">
                  <img
                    className="absolute top-[calc(50%_-_8px)] left-[calc(50%_-_8px)] w-4 h-4"
                    alt=""
                    src="/vector.svg"
                  />
                </div>
                <b className="relative tracking-[0.02em] leading-[100%]">
                  Profile
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDrawerMenuSlideOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top"
          onOutsideClick={closeDrawerMenuSlide}
        >
          <DrawerMenuSlide onClose={closeDrawerMenuSlide} />
        </PortalDrawer>
      )}
    </>
  );
}
