import { FunctionComponent } from "react";
import useNavigation from "../api/route";

const DashboardButtons: FunctionComponent = () => {
  const {
    onDashboardClick,
    onProfileClick,
    onInventoryClick,
    onMyNFTClick,
    onLogoutClick,
    onCatalogClick,
    onInventoryPlacedBookmarkClick,
    onInventoryMemoryFragmentClick,
    onInventoryCostumesClick,
    onInventoryAllItemClick,
  } = useNavigation(); // Use the custom hook
  return (
    <section className=" mobileNav">
      <button
        className="cursor-pointer [border:none] py-4 px-6 bg-[transparent] w-60 rounded-lg flex flex-row items-center justify-start box-border gap-[8px] mix-blend-normal"
        onClick={onDashboardClick}
      >
        <img
          className="w-8 relative rounded h-8 overflow-hidden shrink-0"
          alt=""
          src="/bxsdashboard14.svg"
        />
        <div className="relative text-base tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-dimgray-200 text-left">
          Dashboard
        </div>
      </button>
      <button
        onClick={onProfileClick}
        className="cursor-pointer [border:none] py-4 px-6 bg-[transparent] w-60 flex flex-row items-center justify-start box-border gap-[8px] mix-blend-normal mt-[-8px]"
      >
        <img
          className="w-8 relative rounded h-8 overflow-hidden shrink-0"
          alt=""
          src="/iconamoonprofilefill.svg"
        />
        <div className="relative text-base tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-dimgray-200 text-left">
          Profile
        </div>
      </button>
      <button className="cursor-pointer [border:none] py-4 px-6 bg-[transparent] w-60 flex flex-row items-center justify-start box-border gap-[8px] mix-blend-normal mt-[-8px]">
        <img
          className="w-8 relative h-8 overflow-hidden shrink-0"
          alt=""
          src="/mditreasurechest2.svg"
        />
        <div className="relative text-base tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-dimgray-200 text-left">
          Inventory
        </div>
      </button>
      <div className="flex flex-col items-start justify-start py-0 pr-0 pl-4 mt-[-8px]">
        {/* <button
          onClick={onInventoryPlacedBookmarkClick}
          className="cursor-pointer [border:none] py-2 px-6 bg-[transparent] w-60 flex flex-row items-center justify-start box-border gap-[8px] mix-blend-normal"
        >
          <img
            className="w-8 relative h-8 overflow-hidden shrink-0"
            alt=""
            src="/mditreasurechest3.svg"
          />
          <div className="relative text-base tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-dimgray-200 text-left">
            My Bookmarks
          </div>
        </button>
        <button
          onClick={onInventoryMemoryFragmentClick}
          className="cursor-pointer [border:none] py-2 px-6 bg-[transparent] w-60 flex flex-row items-center justify-start box-border gap-[8px] mix-blend-normal"
        >
          <img
            className="w-8 relative h-8 overflow-hidden shrink-0"
            alt=""
            src="/mditreasurechest4.svg"
          />
          <div className="relative text-base tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-dimgray-200 text-left">
            Memory Fragments
          </div>
        </button> */}
        <button
          onClick={onInventoryCostumesClick}
          className="cursor-pointer [border:none] py-2 px-6 bg-[transparent] w-60 flex flex-row items-center justify-start box-border gap-[8px] mix-blend-normal"
        >
          <img
            className="w-8 relative h-8 overflow-hidden shrink-0"
            alt=""
            src="/bxsdashboard6.svg"
          />
          <div className="relative text-base tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-dimgray-200 text-left">
            Attires
          </div>
        </button>
        <button
          onClick={onInventoryAllItemClick}
          className="cursor-pointer [border:none] py-2 px-6 bg-[transparent] w-60 flex flex-row items-center justify-start box-border gap-[8px] mix-blend-normal"
        >
          <img
            className="w-8 relative h-8 overflow-hidden shrink-0"
            alt=""
            src="/mditreasurechest5.svg"
          />
          <div className="relative text-base tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-dimgray-200 text-left">
            My Inventory
          </div>
        </button>
      </div>
      <button
        onClick={onMyNFTClick}
        className="cursor-pointer [border:none] py-4 px-6 bg-[transparent] w-60 flex flex-row items-center justify-start box-border gap-[8px] mix-blend-normal mt-[-8px]"
      >
        <img
          className="w-8 relative rounded h-8 overflow-hidden shrink-0"
          alt=""
          src="/iconamoonprofilefill.svg"
        />
        <div className="relative text-base tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-dimgray-200 text-left">
          My NFTs
        </div>
      </button>
      <button
        onClick={onCatalogClick}
        className="cursor-pointer [border:none] py-4 px-6 bg-[transparent] w-60 flex flex-row items-center justify-start box-border gap-[8px] mix-blend-normal mt-[-8px]"
      >
        <img
          className="w-8 relative rounded h-8 overflow-hidden shrink-0"
          alt=""
          src="/Catalog.svg"
        />
        <div className="relative text-base tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-dimgray-200 text-left">
          Catalog
        </div>
      </button>
      <button
        onClick={onLogoutClick}
        className="cursor-pointer [border:none] py-4 px-6 bg-[transparent] w-60 flex flex-row items-center justify-start box-border gap-[8px] mix-blend-normal mt-[-8px]"
      >
        <img
          className="w-8 relative h-8 overflow-hidden shrink-0"
          alt=""
          src="/mditreasurechest6.svg"
        />
        <div className="relative text-base tracking-[0.02em] leading-[100%] font-medium font-dm-sans text-dimgray-200 text-left">
          Logout
        </div>
      </button>
      <div className="w-60 hidden flex-row items-center justify-start py-4 px-6 box-border gap-[8px] mix-blend-normal">
        <img
          className="w-8 relative h-8 overflow-hidden shrink-0"
          alt=""
          src="/mditreasurechest7.svg"
        />
        <div className="relative tracking-[0.02em] leading-[100%] font-medium">
          Logout
        </div>
      </div>
    </section>
  );
};

export default DashboardButtons;
