import { FunctionComponent, useMemo, type CSSProperties } from "react";

import FacebookAuth from "./Authentication/facebookAuth";
import GoogleAuth from "./Authentication/googleAuth";
import FacebookAuthLinking from "./linking/facebookAuth";
import GoogleAuthLinking from "./linking/googleAuth";

type SocialMediaLoginContainerType = {
  toast?: any;
  setFacebookLink?: any;
  facebookLink?: any;
  socialMediaButtonText?: string;

  /** Style props */
  propOpacity?: CSSProperties["opacity"];
  propMixBlendMode?: CSSProperties["mixBlendMode"];
  ready?: boolean;
  clientApi?: any;
  profileList?: any;
};

const SocialMediaLoginContainer: FunctionComponent<
  SocialMediaLoginContainerType
> = ({
  toast,
  setFacebookLink,
  facebookLink,
  socialMediaButtonText,
  propOpacity,
  propMixBlendMode,
  ready,
  clientApi,
  profileList,
}) => {
  const optionStyle: CSSProperties = useMemo(() => {
    return {
      opacity: propOpacity,
      mixBlendMode: propMixBlendMode,
    };
  }, [propOpacity, propMixBlendMode]);

  return (
    <div
      // className="flex flex-col items-start justify-start gap-[12px] opacity-[0.2] mix-blend-luminosity text-left text-sm text-dimgray-200 font-dm-sans sm:items-center sm:justify-start"
            className="flex flex-col items-start justify-start gap-[12px]  text-left text-sm text-dimgray-200 font-dm-sans sm:items-center sm:justify-start"
      style={optionStyle}
    >
      <h1
        className={`
          ${
            ready
              ? "bg-slate-200 text-transparent animate-pulse rounded-lg !h-4"
              : ""
          }
          m-0 relative text-inherit leading-[120%] uppercase font-bold font-inherit md:text-base mq350small:text-xs`}
        id="Dashboard"
      >
        {socialMediaButtonText}
      </h1>
      <div className="flex flex-row items-start justify-start gap-[12px] sm:items-start sm:justify-center">
        {!profileList?.facebook_id && (
          <FacebookAuthLinking
            ready={ready}
            clientApi={clientApi}
            facebookLink={facebookLink}
            setFacebookLink={setFacebookLink}
            toast={toast}
          />
        )}

        {!profileList?.google_id && (
          <GoogleAuthLinking clientApi={clientApi} toast={toast} />
        )}
      </div>
    </div>
  );
};

export default SocialMediaLoginContainer;
